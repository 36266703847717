import { useState, useEffect } from 'react';

const useIntersectionObserver = (ref, options, watch = true) => {
    const [isIntersecting, setIsIntersecting] = useState(false);

    useEffect(() => {
        if (!watch) return; // Don't observe if the watch condition is false

        const observer = new IntersectionObserver(([entry]) => {
            setIsIntersecting(entry.isIntersecting);
        }, options);

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [ref, options, watch]);

    return isIntersecting;
};

export default useIntersectionObserver;
