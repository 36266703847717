import React, { useContext, useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { UserContext } from '../context/UserContext';
import { useHistory } from 'react-router-dom';
import { Box, Card, Container, Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { loginUser } from '../../fetchAPI';
import { LoadingButton } from '@mui/lab';
import ErrorAlert from '../components/Alerts/ErrorAlert';
import LoginUserRoleIDModal from '../components/LoginUserRoleIDModal';
import ForgetPasswordModal from '../components/ForgetPasswordModal';
import { fetchIpAddress } from '../helpers/helperFunctions';
import { makeStyles } from "@material-ui/core/styles";
import { validatePhoneNumber } from "../helpers/helperFunctions";
import { useAlert } from '../context/AlertContext';

const useStyles = makeStyles({
    input: {
        borderLeft: '1px solid #C4C4C4',
        paddingLeft: "14px",
        marginLeft: "7px",
        padding: "30px 0px",
    },
    outlinedInput: {
        '& .react-tel-input .form-control': {
            borderRadius: '4px',
            borderColor: '#C4C4C4',
            padding: '14px',
            fontSize: '16px',
            fontWeight: 400,
            color: 'rgba(0, 0, 0, 0.87)',
        },
        '& .react-tel-input .flag-dropdown': {
            border: 'none',
            backgroundColor: 'transparent',
        },
        '& .react-tel-input .form-control:focus': {
            borderColor: '#0275D8',
            boxShadow: '0 0 0 1px #0275D8',
        },
        '& .react-tel-input .selected-flag': {
            marginLeft: '8px',
            marginRight: '12px',
        },
        '& .react-tel-input .country-list': {
            zIndex: 1000,
        },
    }
});

export default function Loginv2() {

    const history = useHistory();
    const [loader, setLoader] = useState(false)
    const [loginInfo, setLoginInfo] = useState({ email: "", dialCode: "", phone: "", password: "" });
    const [errorMessage, setErrorMessage] = useState('')
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const [googleOAuthResponse, setGoogleOAuthResponse] = useState(null)
    const [openRoleIDModal, setOpenRoleIDModal] = useState(false)
    const [forgetPasswordModal, setForgetPasswordModal] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [isBD, setIsBD] = useState(false);
    const [country, setCountry] = useState("bd");
    const [dialCode, setDialCode] = useState("");
    const [isEmailOrMobileNumber, setIsEmailOrMobileNumber] = useState({ email: true, phone_number: false });
    const classes = useStyles();
    const alertContext = useAlert();

    // UseContext Declarations
    const { loginData, redirectionLink, isLoggedin } = useContext(UserContext);
    const urlParams = new URLSearchParams(window.location.search);
    const job_id = urlParams.get('job_id');

    /**
    * Create a function that handle the input event and
    * save the value of that event on an state every time the component change
    * 
    * Setting login information in loginInfo state
    * 
    * @param {string} name
    * @param {event} event 
    */

    const handleChange = (name) => (event) => {
        setLoginInfo({ ...loginInfo, [name]: event.target.value });
    };

    const handleRadioChange = (event) => {
        const { value } = event.target;
        setIsEmailOrMobileNumber({
            email: value === 'email',
            phone_number: value === 'phone_number',
        });
        setLoginInfo({ ...loginInfo, email: "" });
        setPhoneNumber("");
        setDialCode("");
    };

    const handlePhoneChange = (value, countryData) => {
        setDialCode(countryData.dialCode);

        if (country !== countryData.countryCode) {
            setPhoneNumber("")
            setCountry(countryData.countryCode);
        }
        if (countryData.countryCode === "bd") {
            setIsBD(true)
        } else {
            setIsBD(false)
        }

        // setLoginInfo({ ...loginInfo, email: "" })
        setPhoneNumber(value)
    };

    async function onLogin(e) {
        e.preventDefault();
        setLoader(true);

        const actualNumber = phoneNumber.replace(`${dialCode}`, isBD ? "0" : "")

        if (isBD && actualNumber != 0 && !validatePhoneNumber(actualNumber)) {
            alertContext.setOpenErrorAlert(true);
            alertContext.setResponseMessage("Please enter a valid phone number!");
            setLoader(false);
            return;
        }

        const ipAddress = await fetchIpAddress();
        const updatedLoginInfo = { ...loginInfo, user_role_id: 2, ip: ipAddress, email: loginInfo.email ? loginInfo.email : "", phone: actualNumber != 0 ? actualNumber : "", dialCode: actualNumber != 0 ? dialCode : "" };

        /**
         * Calling login API 
         * 
         * @param {object} loginInfo - Sending Login Information
         * @return {object} Success or false json with User Data
         */

        loginUser(updatedLoginInfo).then((response) => {
            if (response && response.success) {
                if (response.response.data?.user?.user_role_id !== 1) {
                    loginData(response)
                    if (job_id && response.response.data?.user?.user_role_id == 2) {
                        history.push(`/candidate-register/update?job_id=${job_id}`)
                    } else {
                        history.push(redirectionLink);
                    }
                    setLoader(false)
                }
                else {
                    setLoader(false)
                    setErrorMessage('Invalid User')
                    throw Error('Error: ' + 'Invalid User')
                }
            }
            else {
                setLoader(false)
                setErrorMessage(response?.errors[0].message[0])
                throw Error('Error: ' + response?.errors[0].message)
            }
        })
            .catch(err => {
                setLoader(false)
                setErrorMessage(err.message);
                setOpenErrorAlert(true);
            })
    }

    // Google Oauth 

    // const handleCallBackForRegistration = async (userRoleID) => {

    //     const ipAddress = await fetchIpAddress();

    //     axios.post(`${process.env.REACT_APP_API_SERVER_BASE_URL}/googleOauthLogin`, {
    //         credential: googleOAuthResponse,
    //         user_role_id: userRoleID,
    //         ip: ipAddress
    //     }).then((response) => {
    //         if (response && response.status === 200) {
    //             loginData(response.data)
    //             if (job_id && response.data.response.data.user.user_role_id == 2) {
    //                 //gtag code - don't remove
    //                 window.dataLayer.push({
    //                     'event': 'candidateNewRegistration',
    //                     'regMethod': 'google'
    //                 });
    //                 //gtag code ended
    //                 history.push(`/candidate-register/update?job_id=${job_id}&reg=success`)
    //             } else if (response.data.response.data.user.user_role_id == 2) {
    //                 //gtag code - don't remove
    //                 window.dataLayer.push({
    //                     'event': 'candidateNewRegistration',
    //                     'regMethod': 'google'
    //                 });
    //                 //gtag code ended
    //                 if (redirectionLink.includes('dashboard') && !redirectionLink.includes('?')) {
    //                     const urlSearchParamsString = `?reg=success`;
    //                     history.push(redirectionLink + urlSearchParamsString);
    //                 } else {
    //                     history.push(redirectionLink);
    //                 }
    //             } else {
    //                 history.push(redirectionLink);
    //             }

    //         }
    //         else {
    //             throw Error('Error: ' + response)
    //         }
    //     }).catch(function (error) {
    //         throw Error('Error: ' + error)
    //     });
    // }

    async function handleCallback(response) {

        setGoogleOAuthResponse(response.credential);

        const ipAddress = await fetchIpAddress();

        axios.post(`${process.env.REACT_APP_API_SERVER_BASE_URL}/oAuth/google/login/candidate`, {
            credential: response.credential,
            ip: ipAddress
        }).then((response) => {

            if (response && response.status === 200) {
                loginData(response.data)
                if (job_id && response?.data?.response?.data?.user?.user_role_id == 2) {
                    history.push(`/candidate-register/update?job_id=${job_id}`)
                } else {
                    history.push(redirectionLink);
                }
                setLoader(false)
            }
            else {
                setLoader(false)
                console.log("api err: ", response)
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                // setLoader(true);
                setErrorMessage(error.response.data.message);
                setOpenErrorAlert(true);
                // setTimeout(() => {
                //     setLoader(false);
                //     setOpenRoleIDModal(true)
                // }, 1000);
            }
        });
    }

    useEffect(() => {
        if(isLoggedin) {
            history.push(redirectionLink)
        }
        /*global google*/
        google.accounts.id.initialize({
            client_id: '723908483827-aquc3ubglvopidh8dant7rt81pv183te.apps.googleusercontent.com',
            callback: handleCallback,
        });

        google.accounts.id.renderButton(
            document.getElementById('gsign'),
            {
                theme: 'outline',
                size: 'large',
                width: `${window.innerWidth >= 900 ? '900px' : '100px'}`,
                text: 'signin_with',
            }
        )

    }, [loader])

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    height: '350px',
                    backgroundImage: 'url(/v2/images/candidateBackground.png)'
                }}
            />

            <Box
                sx={{
                    position: 'relative',
                    top: '-300px',
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '-250px'
                }}
            >
                <Card
                    sx={{
                        display: 'flex',
                        height: '100%',
                        width: '800px',
                        boxShadow: '0px 4px 100px 0px rgba(0, 0, 0, 0.25)',
                        borderRadius: '22px',
                        justifyContent: 'center',
                        margin: '10px'
                    }}
                >
                    <Container maxWidth='sm'>
                        <Grid item md={12} xs={12}>
                            <Stack spacing={1} sx={{ display: 'flex', alignItems: 'center', marginTop: 7, marginBottom: 3 }}>
                                <Typography align='center' sx={{ fontWeight: 600 }}>Welcome Back!</Typography>
                                <Typography variant='h6' sx={{ color: '#0275D8', textAlign: 'center', fontWeight: 700, fontSize: '18px' }}>If you already have an account, sign in using your
                                    email address or mobile number.</Typography>
                            </Stack>
                        </Grid>

                        <Grid item md={12} xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={isEmailOrMobileNumber.email ? 'email' : 'phone_number'}
                                    onChange={handleRadioChange}
                                >
                                    <Stack direction={'row'} spacing={2}>
                                        <FormControlLabel
                                            value="email"
                                            control={<Radio />}
                                            label="Email Address"
                                            sx={{
                                                '& .MuiFormControlLabel-label': {
                                                    fontSize: {
                                                        xs: '12px',
                                                        sm: '14px',
                                                        md: '16px',
                                                    },
                                                },
                                            }}
                                        />
                                        <FormControlLabel
                                            value="phone_number"
                                            control={<Radio />}
                                            label="Mobile Number"
                                            sx={{
                                                '& .MuiFormControlLabel-label': {
                                                    fontSize: {
                                                        xs: '12px',
                                                        sm: '14px',
                                                        md: '16px',
                                                    },
                                                },
                                            }}
                                        />
                                    </Stack>
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <Box m={3}>
                                <form
                                    className='ga_sigin_form' // do not remove ga_ class
                                    onSubmit={onLogin}
                                >
                                    <Stack spacing={2}>
                                        {
                                            isEmailOrMobileNumber.email === true ?
                                                <TextField
                                                    required={phoneNumber != dialCode ? false : true}
                                                    variant="outlined"
                                                    type='text'
                                                    label="Enter Your Email Address"
                                                    fullWidth
                                                    onChange={handleChange("email")}
                                                />
                                                :
                                                <PhoneInput
                                                    required={loginInfo.email ? false : true}
                                                    country={country}
                                                    value={phoneNumber || ''}
                                                    onChange={handlePhoneChange}
                                                    inputProps={{
                                                        name: 'mobile_number',
                                                        // required: true,
                                                        // autoFocus: true,
                                                    }}
                                                    countryCodeEditable={false}
                                                    enableSearch={true}
                                                    containerClass={classes.outlinedInput}
                                                    inputStyle={{
                                                        width: `calc(100% - 20px)`,
                                                        border: '1px solid #C4C4C4',
                                                        borderRadius: '4px',
                                                        fontSize: '16px',
                                                        fontWeight: 400,
                                                        color: 'rgba(0, 0, 0, 0.87)',
                                                        outline: 'none',
                                                        paddingTop: '27px',
                                                        paddingBottom: "27px",
                                                        marginLeft: "20px",
                                                    }}
                                                    searchStyle={{ width: "90%" }}
                                                    dropdownStyle={{ width: `${window.innerWidth > 480 ? "600%" : "400%"}` }}
                                                />
                                        }

                                        <Box sx={{ position: "relative" }}>
                                            <TextField
                                                required
                                                variant="outlined"
                                                type={showPassword ? "text" : "password"}
                                                label="Enter Your Password"
                                                fullWidth
                                                onChange={handleChange("password")}
                                            />
                                            {showPassword ?
                                                <VisibilityOutlinedIcon sx={{ position: "absolute", top: 17, right: 10, cursor: "pointer", color: "#666666" }} onClick={() => setShowPassword(!showPassword)} />
                                                :
                                                <VisibilityOffOutlinedIcon sx={{ position: "absolute", top: 17, right: 10, cursor: "pointer", color: "#666666" }} onClick={() => setShowPassword(!showPassword)} />
                                            }
                                        </Box>
                                        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                            <Typography align='right' sx={{ color: '#898989', fontSize: '12px', cursor: "pointer" }} onClick={() => setForgetPasswordModal(true)}> Forgot Password?</Typography>
                                        </Box>
                                        <LoadingButton
                                            loading={loader}
                                            variant='contained'
                                            type='submit'
                                        >
                                            Sign In
                                        </LoadingButton>
                                    </Stack>
                                </form>
                            </Box>

                            <Grid item md={12} xs={12}>
                                <Box m={3}>
                                    <Divider>OR</Divider>
                                </Box>
                            </Grid>

                            <Grid item md={12} xs={12}>
                                <div className='row justify-content-center'>
                                    <div className="App">
                                        <div id='gsign'></div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item md={12} xs={12}>
                                <Box m={5} mt={10}>
                                    <Typography variant='h6' align='center' sx={{ fontSize: '16px', fontWeight: 700 }}>Don't have an account yet? <Link to={'/candidate-register'}> <Typography variant='span' sx={{ color: '#0275D8', fontWeight: 700 }}> Register here</Typography> </Link></Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Card>

                {/* Forget Password Modal */}
                <ForgetPasswordModal
                    open={forgetPasswordModal}
                    onClose={() => setForgetPasswordModal(false)}
                />

                <ErrorAlert open={openErrorAlert} message={errorMessage} onHide={() => setOpenErrorAlert(false)} />
                {/* <LoginUserRoleIDModal open={openRoleIDModal} onClose={() => setOpenRoleIDModal(false)} registercallbackfunction={handleCallBackForRegistration} /> */}
            </Box>
        </>
    )
}