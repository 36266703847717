import { useQuery } from "react-query";
import { searchJob } from "../../fetchAPI";

export const useJobSearchHook = (jobSearchData, pageNumber) => {
    const getJobs = useQuery(['jobs', jobSearchData, pageNumber], searchJob, { staleTime: Infinity })
    return {
        jobs: getJobs.data,
        isLoading: getJobs.isLoading,
        status: getJobs.status
    };
};