import React from "react";
import { makeStyles } from "@material-ui/core";
import { Box, Grid, Paper, Typography, Divider, List, ListItem, Skeleton, Stack } from "@mui/material";

export default function TemplateSkeleton() {

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            padding: theme.spacing(4),
        },
        paper: {
            padding: theme.spacing(4),
            color: theme.palette.text.primary,
            backgroundColor: '#ffffff',
        },
        header: {
            marginBottom: theme.spacing(3),
        },
        section: {
            marginBottom: theme.spacing(3),
        },
        sectionTitle: {
            marginBottom: theme.spacing(1),
            fontWeight: 'bold',
        },
        listText: {
            color: theme.palette.text.secondary,
        },
        divider: {
            margin: theme.spacing(2, 0),
        },
    }));

    const classes = useStyles();

    return (
        <Box className={classes.root} sx={{ width: '100%', height: 'auto' }}>
            <Grid container justifyContent="center">
                <Grid item xs={12} md={10}>
                    <Paper className={classes.paper}>
                        <Box className={classes.header}>
                            <Typography variant="h4" gutterBottom>
                                <Skeleton variant="rounded" width={'50%'} height={40} animation="wave" />
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                <Skeleton variant="text" width={"90%"} height={30} animation="wave" />
                            </Typography>
                            <Stack direction={'row'} spacing={2}>
                                <Skeleton variant="text" width={"10%"} height={30} animation="wave" />
                                <Skeleton variant="text" width={"10%"} height={30} animation="wave" />
                                <Skeleton variant="text" width={"10%"} height={30} animation="wave" />
                                <Skeleton variant="text" width={"10%"} height={30} animation="wave" />
                            </Stack>
                        </Box>

                        <Divider className={classes.divider} />

                        <Box className={classes.section}>
                            <Typography variant="h6" className={classes.sectionTitle}>
                                <Skeleton variant="text" width={"70%"} height={30} animation="wave" />
                            </Typography>
                            <Typography variant="body1" paragraph>
                                <Skeleton variant="text" width={"70%"} height={30} animation="wave" />
                            </Typography>
                        </Box>

                        <Divider className={classes.divider} />

                        <Box className={classes.section}>
                            <Typography variant="h6" className={classes.sectionTitle}>
                                <Skeleton variant="text" width={"40%"} height={30} animation="wave" />
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <List dense>
                                        <ListItem>
                                            <Skeleton variant="text" width={"30%"} height={30} animation="wave" />
                                        </ListItem>
                                        <ListItem>
                                            <Skeleton variant="text" width={"30%"} height={30} animation="wave" />
                                        </ListItem>
                                        <ListItem>
                                            <Skeleton variant="text" width={"30%"} height={30} animation="wave" />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <List dense>
                                        <ListItem>
                                            <Skeleton variant="text" width={"30%"} height={30} animation="wave" />
                                        </ListItem>
                                        <ListItem>
                                            <Skeleton variant="text" width={"30%"} height={30} animation="wave" />
                                        </ListItem>
                                        <ListItem>
                                            <Skeleton variant="text" width={"30%"} height={30} animation="wave" />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </Box>

                        <Divider className={classes.divider} />

                        <Box className={classes.section}>
                            <Typography variant="h6" className={classes.sectionTitle}>
                                <Skeleton variant="text" width={"40%"} height={30} animation="wave" />
                            </Typography>

                            <Box>
                                <Typography variant="subtitle1">
                                    <Skeleton variant="text" width={"30%"} height={30} animation="wave" />
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    <Skeleton variant="text" width={"20%"} height={30} animation="wave" />
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    <Skeleton variant="text" width={"80%"} height={30} animation="wave" />
                                    <Skeleton variant="text" width={"70%"} height={30} animation="wave" />
                                    <Skeleton variant="text" width={"60%"} height={30} animation="wave" />
                                </Typography>
                            </Box>

                            <Divider className={classes.divider} />

                            <Box>
                                <Typography variant="subtitle1">
                                    <Skeleton variant="text" width={"30%"} height={30} animation="wave" />
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    <Skeleton variant="text" width={"20%"} height={30} animation="wave" />
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    <Skeleton variant="text" width={"80%"} height={30} animation="wave" />
                                    <Skeleton variant="text" width={"70%"} height={30} animation="wave" />
                                    <Skeleton variant="text" width={"60%"} height={30} animation="wave" />
                                </Typography>
                            </Box>

                            <Divider className={classes.divider} />

                            <Box>
                                <Typography variant="subtitle1">
                                    <Skeleton variant="text" width={"30%"} height={30} animation="wave" />
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    <Skeleton variant="text" width={"20%"} height={30} animation="wave" />
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    <Skeleton variant="text" width={"80%"} height={30} animation="wave" />
                                    <Skeleton variant="text" width={"70%"} height={30} animation="wave" />
                                    <Skeleton variant="text" width={"60%"} height={30} animation="wave" />
                                </Typography>
                            </Box>
                        </Box>

                        <Divider className={classes.divider} />

                        <Grid container spacing={4}>
                            <Grid item xs={12} md={6}>
                                <Box className={classes.section}>
                                    <Typography variant="h6" className={classes.sectionTitle}>
                                        <Skeleton variant="text" width={"30%"} height={30} animation="wave" />
                                    </Typography>
                                    <List dense>
                                        <ListItem>
                                            <Skeleton variant="text" width={"50%"} height={30} animation="wave" />
                                        </ListItem>
                                        <ListItem>
                                            <Skeleton variant="text" width={"40%"} height={30} animation="wave" />

                                        </ListItem>
                                        <ListItem>
                                            <Skeleton variant="text" width={"30%"} height={30} animation="wave" />
                                        </ListItem>
                                    </List>
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Box className={classes.section}>
                                    <Typography variant="h6" className={classes.sectionTitle}>
                                        <Skeleton variant="text" width={"30%"} height={30} animation="wave" />
                                    </Typography>
                                    <List dense>
                                        <ListItem>
                                            <Skeleton variant="text" width={"50%"} height={30} animation="wave" />
                                        </ListItem>
                                        <ListItem>
                                            <Skeleton variant="text" width={"40%"} height={30} animation="wave" />

                                        </ListItem>
                                        <ListItem>
                                            <Skeleton variant="text" width={"30%"} height={30} animation="wave" />
                                        </ListItem>
                                    </List>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    )
}
