import React, { useState, useEffect, useRef } from 'react'
import { deleteCandidateProjectByID, postCandidateProjectByID, updateCandidateProjectByID } from '../../../../../fetchAPI'
import { Alert, Box, Checkbox, CircularProgress, FormControlLabel, Grid, InputAdornment, InputLabel, TextField, Typography } from '@mui/material'
import UpdateDeleteConfirmationModal from './UpdateDeleteConfirmationModal'
import Select from 'react-select'
import JoditEditor from 'jodit-react';
import customStyles from './CandidateUpdateProfile.module.css'
import AddButton from './component/AddButton'
import CandidateUpdateModal from './component/CandidateUpdateModal'
import SingleProjectBlock from './component/SingleProjectBlock'
import EditSVG from './component/EditSVG'
import DeleteSVG from './component/DeleteSVG'
import { useAlert } from '../../../../context/AlertContext'
import RequiredAsterisk from './component/RequiredAsterisk'
import { useLocation } from 'react-router-dom'
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Custom breakpoint at 768px
const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        md: 768,  
      },
    },
});

function UpdateProject(props) {
    const [loader, setLoader] = useState(false)

    const [updateProjectData, setUpdateProjectData] = useState('')
    const [projectData, setProjectData] = useState({
        start_date: new Date().toISOString().split('T')[0],
        end_date: new Date().toISOString().split('T')[0],
        description: ""
    })

    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const addFormRef = useRef();
    const [deleteItemId, setDeleteItemId] = useState('')

    const alertContext = useAlert();
    const { search } = useLocation();

	useEffect(() => {
	  const scrollToHash = () => {
		const params = new URLSearchParams(search);
		const scrollTo = params.get('scrollTo');
		if (scrollTo) {
		  setTimeout(() => {
			const element = document.getElementById(scrollTo);
			if (element) {
				const offset = element.offsetTop - 220;
				window.scrollTo({ top: offset, behavior: 'smooth' });
			}
		  }, 100);
		}
	  };
  
	  scrollToHash();
	  window.addEventListener('popstate', scrollToHash);

	  return () => {
		window.removeEventListener('popstate', scrollToHash);
	  };
	}, [search]);

    const handleUpdateChange = (name) => (event) => {
        if(name === "start_date" || name === "end_date"){
            const date = new Date(event.$d)
            const formattedDate = dayjs(date).format("YYYY-MM-DD")
            setUpdateProjectData({...updateProjectData, [name]: formattedDate})
        } else {
            setUpdateProjectData({ ...updateProjectData, [name]: event.target.value })
        }
    }

    const enableUpdate = (item) => {
        setUpdateProjectData({
            ...updateProjectData,
            id: item.id,
            title: item.title,
            description: item.description,
            start_date: item.start_date,
            end_date: item.end_date,
            currently_working: item.currently_working,
            employment: item.employment
        })
    }

    const handleChange = (name) => (event) => {
        if(name === "start_date" || name === "end_date"){
            const date = new Date(event.$d)
            const formattedDate = dayjs(date).format("YYYY-MM-DD")
            setProjectData({...projectData, [name]: formattedDate})
        } else {
            setProjectData({ ...projectData, [name]: event.target.value })
        }
    }

    const handleCheckBox = (name) => (event) => {
        const checked = event.target.checked;
        setProjectData({ ...projectData, [name]: +checked,  end_date: checked ? '' : projectData.end_date })
    }

    const handleUpdateCheckBox = (name) => (event) => {
        setUpdateProjectData({ ...updateProjectData, [name]: +(event.target.checked) })
    }

    function defaultSelectValue(item) {
        if (item.employment != null) {
            return { label: item.employment.organization_name, value: item.employment.id }

        } else {
            return null
        }
    }


    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
    const handleOpen = (itemId) => {
        setOpenDeleteModal(true);
        setDeleteItemId(itemId);
    };
    const handleClose = () => {
        setOpenDeleteModal(false);
    };

    const deleteCandidateproject = (projectId) => {
        setOpenDeleteModal(false)
        setLoader(true)
        deleteCandidateProjectByID(projectId).then((response) => {
            if (response.success) {
                alertContext.setResponseMessage(response.response.message)
                alertContext.setOpenAlert(true)
                // props.projects.splice(index, 1);
                props.renderState()
                setLoader(false)
            }
            else {
                // console.log('errors: ', response);
                const message = response.errors?.map(msg => {
                    alertContext.setResponseMessage(msg.message)
                })
                alertContext.setOpenErrorAlert(true)
                setLoader(false)
            }
        })
    }

    let errorsObj = { title: '', start_date: '', end_date: '', description: '' };
    const [updateErrors, setUpdateErrors] = useState('')
    const [errors, setErrors] = useState({})

    const insertCandidateProject = (candId, body) => {
        let error = false;
        const errorObj = { ...errorsObj };

        if (!body.title) {
            errorObj.title = 'Project title is Required';
            error = true;
        }
        if (!body.start_date) {
            errorObj.start_date = 'Start Date is Required';
            error = true;
        }
        if (!body.end_date && !body.currently_working) {
            errorObj.end_date = 'End Date is Required';
            error = true;
        }
        if (!body.description) {
            errorObj.description = 'Description is Required';
            error = true;
        }
        setErrors(errorObj);
        if (error) {
            // Do not call the Post API
            setLoader(false);
        }
        else {
            setAddModalOpen(false);
            setProjectData({});

            postCandidateProjectByID(candId, body).then((response) => {
                if (response && response.success) {
                    alertContext.setResponseMessage(response.response.message)
                    alertContext.setOpenAlert(true)
                    props.renderState()
                    setLoader(false);
                }
                else {
                    // console.log('errors: ', response);
                    const message = response.errors?.map(msg => {
                        alertContext.setResponseMessage(msg.message)
                    })
                    alertContext.setOpenErrorAlert(true)
                    setLoader(false)
                }
            })
        }
    }

    const updateCandidateProject = (candId, body) => {
        let error = false;
        const errorObj = { ...errorsObj };

        if (!body.title) {
            errorObj.title = 'Project title is Required';
            error = true;
        }
        if (!body.start_date) {
            errorObj.start_date = 'Start Date is Required';
            error = true;
        }
        if (!body.end_date && !body.currently_working) {
            errorObj.end_date = 'End Date is Required';
            error = true;
        }
        if (!body.description) {
            errorObj.description = 'Description is Required';
            error = true;
        }
        setUpdateErrors(errorObj);
        if (error) {
            // Do not call the Post API
            setLoader(false);
        }
        else {
            setUpdateModalOpen(false);
            updateCandidateProjectByID(candId, body.id, body).then((response) => {
                if (response && response.success) {
                    alertContext.setResponseMessage(response.response.message)
                    alertContext.setOpenAlert(true)
                    props.renderState()
                    setLoader(false)
                }
                else {
                    // console.log('errors: ', response);
                    const message = response.errors?.map(msg => {
                        alertContext.setResponseMessage(msg.message)
                    })
                    alertContext.setOpenErrorAlert(true)
                    setLoader(false);
                }
            })
        }
    }


    return (
        <Box id='project_box'>
            <Box className='card shadow-sm'>
                <Box className={customStyles.cardHeader}>
                    <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <Typography sx={{fontSize: "18px", fontWeight: 700, color: "#424447"}}>Projects</Typography>
                        <Box
                            sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", color: "#0275D8", fontFamily: "Epilogue", fontWeight: "600 !important", fontSize: "16px !important", backgroundColor: "#E5F5FF", padding: "6px 16px !important", cursor: "pointer" }}
                            onClick={() => setAddModalOpen(true)}
                        >
                            <Typography sx={{color: "#0275D8", marginRight: "10px", fontFamily: "Epilogue", fontSize: "16px", fontWeight: 600}}>Add</Typography>
                            <Box sx={{display: "flex", alignItems: "center"}}>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="20" height="20" rx="10" fill="#CAEBFF"/>
                                <path d="M6.99362 9.80457C6.27657 9.80457 5.56145 9.80457 4.8444 9.80457C4.40448 9.80457 4.12153 10.0836 4.13316 10.4848C4.13603 10.6259 4.18635 10.7619 4.27601 10.8709C4.36567 10.9799 4.48944 11.0556 4.62735 11.0856C4.72962 11.1044 4.83344 11.1135 4.93742 11.1127H9.07696C9.38122 11.1127 9.34052 11.072 9.34246 11.386C9.34246 12.7852 9.34246 14.1844 9.34246 15.5836C9.34246 16.041 9.61378 16.3298 10.0188 16.3201C10.1598 16.3184 10.2961 16.2693 10.4058 16.1807C10.5155 16.0921 10.5921 15.9692 10.6235 15.8317C10.6438 15.7296 10.6535 15.6257 10.6525 15.5216C10.6525 14.1224 10.6525 12.7212 10.6525 11.322C10.6525 11.1495 10.6952 11.105 10.8677 11.1069C12.2669 11.1069 13.6661 11.1069 15.0673 11.1069C15.1647 11.1089 15.2621 11.1011 15.358 11.0836C15.5021 11.0525 15.6313 10.9729 15.7239 10.8582C15.8166 10.7434 15.8671 10.6003 15.8671 10.4528C15.8671 10.3053 15.8166 10.1623 15.7239 10.0475C15.6313 9.93272 15.5021 9.85314 15.358 9.82201C15.2558 9.80289 15.1518 9.79445 15.0479 9.79682H10.9277C10.6099 9.79682 10.6545 9.84139 10.6545 9.51388C10.6545 8.14178 10.6545 6.7684 10.6545 5.39372C10.6564 5.29629 10.6487 5.1989 10.6312 5.10302C10.6019 4.9568 10.5228 4.82525 10.4075 4.73073C10.2921 4.63621 10.1476 4.58456 9.99847 4.58456C9.84934 4.58456 9.70481 4.63621 9.58945 4.73073C9.4741 4.82525 9.39504 4.9568 9.36572 5.10302C9.34988 5.19908 9.34275 5.29638 9.3444 5.39372C9.3444 6.79294 9.3444 8.19411 9.3444 9.59333C9.3444 9.75612 9.30177 9.8007 9.13897 9.79876C8.4258 9.80457 7.70874 9.80457 6.99362 9.80457Z" fill="#0079C1"/>
                                </svg>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <div className='card-body'>
                    {
                        loader ?
                            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                                <CircularProgress />
                            </Box>
                            :
                            props.projects?.map((item, index) => {
                                return (
                                    <Box key={item.id}>
                                        {index > 0 ? <hr /> : ''}
                                        <div className={customStyles.singleProject} >
                                            <Typography sx={{fontSize: "18px", fontWeight: 700, color: "#0079c1"}}>{item.title}</Typography>
                                            <Box className={customStyles.actionContainer} style={{ marginLeft: '10px' }}>
                                                <span style={{ height: '32px' }} onClick={() => { enableUpdate(item); setUpdateModalOpen(true) }}>
                                                    <EditSVG />
                                                </span>
                                                <span style={{ height: '32px' }} onClick={() => handleOpen(item.id)}>
                                                    <DeleteSVG />
                                                </span>
                                            </Box>
                                        </div>
                                        <SingleProjectBlock item={item} />
                                    </Box>
                                )
                            })
                    }
                </div>
            </Box>
            <CandidateUpdateModal form="candidateProjectAddForm" open={addModalOpen} onClose={() => [setErrors({}), setAddModalOpen(false), setProjectData({})]} title='New Project'>
                <ThemeProvider theme={theme}>
                    <Box className='card-body'>
                        <form id='candidateProjectAddForm' ref={addFormRef} onSubmit={(e) => { e.preventDefault(); setLoader(true); insertCandidateProject(props.candId, projectData); }}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={6}>
                                    <InputLabel>Project Title <RequiredAsterisk/></InputLabel>
                                    <TextField className='form-control' fullWidth onChange={handleChange('title')} placeholder='Project title' sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}}} type='text' />
                                    {errors.title && <Alert severity="error">{errors.title}</Alert>}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputLabel>Project  Company / Client  Name</InputLabel>
                                    <Select
                                        placeholder='Select Company'
                                        options={props.experiences?.map(item => {
                                            return (
                                                { label: item.organization_name, value: item.id }
                                            )
                                        })
                                        }
                                        onChange={(event) => setProjectData({ ...projectData, candidate_experience_id: event.value })}
                                        className='skill-update-container'
                                        classNamePrefix='skill-update'
                                        styles={{
                                            control: (provided, state) => ({
                                                ...provided,
                                                borderRadius: '5px !important',
                                                height: '51px'
                                            }),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputLabel>Start Date <RequiredAsterisk/></InputLabel>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        className='form-control'
                                        closeOnSelect={true}
                                        disableFuture={true}
                                        showToolbar={false}
                                        views={['year', 'month']}
                                        maxDate={new Date().toISOString()}
                                        value={projectData.start_date}
                                        onChange={handleChange('start_date')}
                                        renderInput={(params) => <TextField {...params} InputProps={{ sx: {paddingRight: "0px"}, endAdornment: <InputAdornment sx={{position: "absolute", right: 12}}><CalendarMonthOutlinedIcon /></InputAdornment>}} sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}, '& .MuiOutlinedInput-input::placeholder': { fontSize: '13.5px'}}} /> }
                                    />
                                    </LocalizationProvider>
                                    {errors.start_date && <Alert severity="error">{errors.start_date}</Alert>}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputLabel>End Date {!projectData.currently_working ? <RequiredAsterisk/> : ''} </InputLabel>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        className='form-control'
                                        closeOnSelect={true}
                                        disableFuture={true}
                                        showToolbar={false}
                                        views={['year', 'month']}
                                        maxDate={new Date().toISOString()}
                                        value={projectData.end_date}
                                        disabled={projectData.currently_working == 1 ? true : false}
                                        onChange={handleChange('end_date')}
                                        renderInput={(params) => <TextField {...params} InputProps={{ sx: {paddingRight: "0px"}, endAdornment: <InputAdornment sx={{position: "absolute", right: 12}}><CalendarMonthOutlinedIcon /></InputAdornment>}} sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}, '& .MuiOutlinedInput-input::placeholder': { fontSize: '13.5px'}}} /> }
                                    />
                                    </LocalizationProvider>
                                    <FormControlLabel control={<Checkbox onChange={handleCheckBox('currently_working')} />} label="Ongoing"/>                                    
                                    {errors.end_date && <Alert severity="error">{errors.end_date}</Alert>}
                                    {errors.dateValid && <Alert severity="error">{errors.dateValid}</Alert>}
                                </Grid>
                                <Grid item xs={12}>
                                    <div className='form-group'>
                                        <InputLabel>Project Description <RequiredAsterisk/></InputLabel>
                                        <JoditEditor
                                            value={projectData.description || ''}
                                            buttons={[
                                                'bold', '|',
                                                'italic', '|',
                                                'ul', '|', 'underline', '|']}
                                            className='m-t10'
                                            onChange={(content) => setProjectData({ ...projectData, description: content })}
                                        />
                                        {errors.description && <Alert severity="error">{errors.description}</Alert>}
                                    </div>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </ThemeProvider>
            </CandidateUpdateModal>
            <CandidateUpdateModal form="candidateProjectUpdateForm" open={updateModalOpen} onClose={() => [setUpdateErrors({}), setUpdateModalOpen(false), setUpdateProjectData({})]} title='Update Project'>
                <ThemeProvider theme={theme}>
                    <Box className='card-body'>
                        <form id='candidateProjectUpdateForm' onSubmit={(e) => { e.preventDefault(); setLoader(true); updateCandidateProject(props.candId, updateProjectData); }}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={6}>
                                    <InputLabel>Project Title <RequiredAsterisk/></InputLabel>
                                    <TextField className='form-control' fullWidth onChange={handleUpdateChange('title')} value={updateProjectData.title} placeholder='Project title' sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}}} type='text' />
                                    {updateErrors.title && <Alert severity="error">{updateErrors.title}</Alert>}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputLabel>Project  Company / Client  Name</InputLabel>
                                    <Select
                                        placeholder='Select Employment'
                                        defaultValue={defaultSelectValue(updateProjectData)}
                                        options={props.experiences?.map(item => {
                                            return (
                                                { label: item.organization_name, value: item.id }
                                            )
                                        })
                                        }
                                        onChange={(event) => setUpdateProjectData({ ...updateProjectData, candidate_experience_id: event.value })}
                                        className='skill-update-container'
                                        classNamePrefix='skill-update'
                                        styles={{
                                            control: (provided, state) => ({
                                                ...provided,
                                                borderRadius: '5px !important',
                                                height: '51px'
                                            }),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputLabel>Start Date <RequiredAsterisk/></InputLabel>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        className='form-control'
                                        closeOnSelect={true}
                                        disableFuture={true}
                                        showToolbar={false}
                                        views={['year', 'month']}
                                        maxDate={new Date().toISOString()}
                                        value={updateProjectData.start_date}
                                        onChange={handleUpdateChange('start_date')}
                                        renderInput={(params) => <TextField {...params} InputProps={{ sx: {paddingRight: "0px"}, endAdornment: <InputAdornment sx={{position: "absolute", right: 12}}><CalendarMonthOutlinedIcon /></InputAdornment>}} sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}, '& .MuiOutlinedInput-input::placeholder': { fontSize: '13.5px'}}} /> }
                                    />
                                    </LocalizationProvider>
                                    {updateErrors.start_date && <Alert severity="error">{updateErrors.start_date}</Alert>}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputLabel>End Date {!updateProjectData.currently_working ? <RequiredAsterisk/> : ''} </InputLabel>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        className='form-control'
                                        closeOnSelect={true}
                                        disableFuture={true}
                                        showToolbar={false}
                                        views={['year', 'month']}
                                        maxDate={new Date().toISOString()}
                                        value={updateProjectData?.end_date}
                                        disabled={updateProjectData.currently_working == 1 ? true : false}
                                        onChange={handleUpdateChange('end_date')}
                                        renderInput={(params) => <TextField {...params} InputProps={{ sx: {paddingRight: "0px"}, endAdornment: <InputAdornment sx={{position: "absolute", right: 12}}><CalendarMonthOutlinedIcon /></InputAdornment>}} sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}, '& .MuiOutlinedInput-input::placeholder': { fontSize: '13.5px'}}} /> }
                                    />
                                    </LocalizationProvider>
                                    <FormControlLabel control={<Checkbox checked={updateProjectData?.currently_working} onChange={handleUpdateCheckBox('currently_working')} />} label="Ongoing"/>                                    
                                    {updateErrors.end_date && <Alert severity="error">{updateErrors.end_date}</Alert>}
                                    {updateErrors.dateValid && <Alert severity="error">{updateErrors.dateValid}</Alert>}
                                </Grid>
                                <Grid item xs={12}>
                                    <div className='form-group'>
                                        <InputLabel>Project Description <RequiredAsterisk/></InputLabel>
                                        <JoditEditor
                                            value={updateProjectData.description || ''}
                                            buttons={[
                                                'bold', '|',
                                                'italic', '|',
                                                'ul', '|', 'underline', '|']}
                                            className='m-t10'
                                            onChange={(content) => setUpdateProjectData({ ...updateProjectData, description: content })}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </ThemeProvider>
            </CandidateUpdateModal>

            <UpdateDeleteConfirmationModal open={openDeleteModal} onClose={handleClose} deleteItem={() => deleteCandidateproject(deleteItemId)} />
        </Box>
    )
}

export default UpdateProject