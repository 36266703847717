import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import { resetPassword } from '../../fetchAPI'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Box, Button, Card, Container, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import { validatePassword } from '../helpers/helperFunctions';
import { useAlert } from '../context/AlertContext';
import { LoadingButton } from '@mui/lab';

function CrossIcon() {
    return (
        <svg width="30" height="30" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" rx="8" fill="#FFDCDC" />
            <path d="M5.93246 9.69582C5.52683 10.1014 5.1223 10.506 4.71668 10.9116C4.46782 11.1605 4.46563 11.4784 4.69914 11.6987C4.78058 11.7769 4.88599 11.8254 4.99837 11.8364C5.11075 11.8473 5.22354 11.8201 5.31854 11.759C5.38703 11.7118 5.4509 11.6582 5.50929 11.599L7.85097 9.25731C8.02309 9.08519 7.97704 9.08519 8.15574 9.26169C8.94726 10.0532 9.73878 10.8447 10.5303 11.6363C10.789 11.895 11.1058 11.9048 11.3295 11.6702C11.4083 11.5895 11.4576 11.4846 11.4695 11.3725C11.4815 11.2603 11.4553 11.1474 11.3953 11.0519C11.349 10.9827 11.2958 10.9184 11.2363 10.8601C10.4448 10.0686 9.65217 9.27594 8.86065 8.48442C8.76308 8.38685 8.76198 8.33752 8.86065 8.24105C9.65217 7.44953 10.4437 6.658 11.2363 5.86539C11.2925 5.81139 11.3432 5.75189 11.3876 5.68779C11.4515 5.58862 11.4796 5.47054 11.4671 5.3532C11.4546 5.23587 11.4022 5.12636 11.3188 5.04292C11.2353 4.95948 11.1258 4.90715 11.0085 4.89463C10.8912 4.8821 10.7731 4.91015 10.6739 4.9741C10.6053 5.0211 10.5417 5.07511 10.4843 5.13526L8.15354 7.46597C7.97375 7.64576 8.02418 7.64576 7.83891 7.46049C7.06273 6.68432 6.28583 5.90741 5.50819 5.12978C5.45419 5.07354 5.3947 5.02286 5.3306 4.97849C5.23129 4.91236 5.11215 4.88267 4.99343 4.89445C4.87471 4.90624 4.76373 4.95878 4.67937 5.04314C4.59501 5.1275 4.54247 5.23848 4.53068 5.3572C4.5189 5.47592 4.54859 5.59506 4.61472 5.69436C4.6601 5.75766 4.7111 5.81673 4.7671 5.87087C5.55863 6.66239 6.35124 7.45501 7.14277 8.24653C7.23485 8.33861 7.23595 8.38795 7.14277 8.47894C6.74262 8.88566 6.33699 9.29129 5.93246 9.69582Z" fill="#DB1616" />
        </svg>
    );
}

function ErrorModalIcon() {
    return (
        <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="64" cy="64" r="64" fill="#FFE9E9" />
            <circle cx="64" cy="64" r="40" fill="#FF5353" />
            <circle cx="63.5" cy="64.5" r="12.5" fill="white" />
            <circle cx="1.25661" cy="1.25661" r="1.25661" transform="matrix(-1 0 0 1 64.4922 69.3359)" fill="#FF5353" />
            <rect width="1.39624" height="11.1699" rx="0.698119" transform="matrix(-1 0 0 1 63.9375 56.7656)" fill="#FF5353" />
        </svg>

    )
}

function PasswordResetIcon() {
    return (
        <svg width="128" height="129" viewBox="0 0 128 129" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="64" cy="64.3738" rx="64" ry="63.7722" fill="#E7F8EE" />
            <ellipse cx="64" cy="64.3733" rx="40" ry="39.8577" fill="#0FB957" />
            <path d="M52 64.3713C52 62.0078 52.703 59.6974 54.02 57.7318C55.3371 55.7661 57.2092 54.2335 59.3999 53.3275C61.5906 52.4215 64.0017 52.1827 66.3285 52.6413C68.6553 53.1 70.7936 54.2354 72.4732 55.9042C74.1528 57.5731 75.2983 59.7005 75.7652 62.0177C76.2321 64.335 75.9994 66.7381 75.0964 68.9236C74.1934 71.1091 72.6606 72.9789 70.6918 74.2969C68.7229 75.6149 66.4062 76.3219 64.0343 76.3287C60.8484 76.3292 57.7922 75.0708 55.5362 72.8292C53.2802 70.5876 52.0085 67.5459 52 64.3713ZM60.3829 70.3714C60.5511 70.3796 60.7189 70.3493 60.8735 70.2826C61.028 70.216 61.1652 70.1148 61.2743 69.987L64.4886 66.7842C66.6057 64.6745 68.7221 62.5649 70.8379 60.4553C70.9504 60.3442 71.0399 60.2119 71.1012 60.0663C71.1625 59.9207 71.1944 59.7644 71.1951 59.6065C71.1958 59.4486 71.1652 59.2921 71.1052 59.1459C71.0452 58.9997 70.9569 58.8668 70.8453 58.7546C70.7338 58.6424 70.6011 58.5533 70.455 58.4922C70.3088 58.4311 70.152 58.3994 69.9935 58.3987C69.835 58.398 69.6779 58.4284 69.5312 58.4882C69.3846 58.548 69.2511 58.636 69.1386 58.7471C67.5243 60.35 65.9114 61.9564 64.3 63.5663C63.0857 64.7777 61.8714 65.9877 60.6571 67.1962C60.5457 67.3094 60.4943 67.303 60.4064 67.1771C60.2264 66.9144 60.0293 66.6624 59.8386 66.4062C59.1593 65.4945 58.4843 64.5806 57.7986 63.6731C57.6498 63.4739 57.4445 63.3238 57.2092 63.2419C56.9739 63.1601 56.7194 63.1504 56.4786 63.214C56.2665 63.2551 56.0726 63.3612 55.9239 63.5173C55.7753 63.6735 55.6792 63.8721 55.6493 64.0852C55.6042 64.2932 55.6085 64.5088 55.6617 64.7149C55.7149 64.921 55.8155 65.1119 55.9557 65.2724C57.0986 66.7756 58.2343 68.2853 59.3629 69.8013C59.4662 69.9762 59.6143 70.1207 59.7919 70.2201C59.9696 70.3195 60.1705 70.3702 60.3743 70.3671L60.3829 70.3714Z" fill="white" />
        </svg>

    )
}

function SuccessModal({ open, onClose }) {
    return (
        <Dialog open={open} sx={{ '& .MuiPaper-root': { borderRadius: '16px' } }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton onClick={onClose}>
                    <CrossIcon />
                </IconButton>
            </Box>
            <DialogContent>
                <Stack spacing={2} alignItems={'center'} sx={{ margin: '50px' }}>
                    <PasswordResetIcon />
                    <Box>
                        <DialogTitle
                            sx={{
                                fontFamily: 'Epilogue',
                                fontSize: { xs: '20px', md: '24px' },
                                fontWeight: 700,
                                paddingLeft: 0,
                                paddingRight: 0,
                                textAlign: 'center'
                            }}
                        >

                            Password reset successfully
                        </DialogTitle>
                        <DialogContentText
                            sx={{
                                fontFamily: 'Poppins',
                                fontSize: '12px',
                                fontWeight: 400,
                                textAlign: 'center'
                            }}
                        >
                            You have successfully updated your password
                        </DialogContentText>
                    </Box>
                    <Link to={'/login'} style={{ width: '100%', marginTop: '40px' }}>
                        <Button
                            variant='contained'
                            fullWidth
                            sx={{
                                textTransform: 'capitalize',
                                padding: 1
                            }}
                        >
                            Sign In
                        </Button>
                    </Link>
                </Stack>
            </DialogContent>
        </Dialog >
    )
}

function ErrorModal({ open }) {
    return (
        <Dialog open={open} sx={{ '& .MuiPaper-root': { borderRadius: '16px' } }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton >
                    <CrossIcon />
                </IconButton>
            </Box>
            <DialogContent>
                <Stack spacing={2} alignItems={'center'} sx={{ margin: '50px' }}>
                    <ErrorModalIcon />
                    <Box>
                        <DialogTitle
                            sx={{
                                fontFamily: 'Epilogue',
                                fontSize: { xs: '20px', md: '24px' },
                                fontWeight: 700,
                                paddingLeft: 0,
                                paddingRight: 0,
                                textAlign: 'center'
                            }}
                        >

                            Oops!
                        </DialogTitle>
                        <DialogContentText
                            sx={{
                                fontFamily: 'Poppins',
                                fontSize: '12px',
                                fontWeight: 400,
                                textAlign: 'center'
                            }}
                        >
                            Something went wrong with your password reset. Please check the link or contact support at <span style={{ color: '#0079C1' }}>contact@atb-jobs.com</span>
                        </DialogContentText>
                    </Box>
                    <Link to={'/login'} style={{ width: '100%', marginTop: '40px' }}>
                        <Button
                            variant='contained'
                            fullWidth
                            sx={{
                                textTransform: 'capitalize',
                                padding: 1
                            }}
                        >
                            Sign In
                        </Button>
                    </Link>
                </Stack>
            </DialogContent>
        </Dialog >
    )
}

export default function PasswordReset() {

    const alertContext = useAlert();
    const { token, email } = useParams();

    const [showPassword, setShowPassword] = useState(false);
    const [responseSuccessModal, setResponseSuccessModal] = useState(false);
    const [responseErrorModal, setResponseErrorModal] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [validationMessages, setValidationMessages] = useState('');
    const [isPasswordFieldSelected, setIsPasswordFieldSelected] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [validationStatus, setValidationStatus] = useState({
        minLength: false,
        uppercase: false,
        lowercase: false,
        numbers: false,
        specialChar: false
    });

    const [passwordInput, setPasswordInput] = useState({
        password: '',
        password_confirmation: '',
        email: email,
        token: token
    });

    const resetPassData = async (e) => {
        e.preventDefault();
        const allValid = Object.values(validationStatus).every(Boolean);
        if (!allValid) {
            alertContext.setOpenErrorAlert(true);
            alertContext.setResponseMessage(validationMessages);
            return;
        }

        if (passwordInput.password === passwordInput.password_confirmation) {
            try {
                const response = await resetPassword(passwordInput);
                if (response.success) {
                    setResponseSuccessModal(true);
                    setResponseMessage(response.response.data)
                } else {
                    setResponseErrorModal(true);
                    setResponseMessage(response.errors[0].message)
                }
            } catch (error) {
                setResponseMessage(error.errors[0].message)
            }
        }

    };

    const handlePasswordChange = (event) => {
        const { name, value } = event.target;
        setPasswordInput({
            ...passwordInput,
            [name]: value.trim()
        });
    };

    useEffect(() => {
        if (passwordInput.password) {
            const { status, messages } = validatePassword(passwordInput.password);
            setValidationStatus(status);
            setValidationMessages(messages);
        }
    }, [passwordInput.password]);

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    height: '350px',
                    backgroundImage: 'url(/v2/images/ResetPasswordBgImage.png)'
                }}
            />

            <Box
                sx={{
                    position: 'relative',
                    top: '-300px',
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '-250px'
                }}
            >
                <Card
                    sx={{
                        display: 'flex',
                        height: '100%',
                        width: '800px',
                        boxShadow: '0px 4px 100px 0px rgba(0, 0, 0, 0.25)',
                        borderRadius: '22px',
                        justifyContent: 'center',
                        margin: '10px',
                        paddingBottom: '50px'
                    }}
                >
                    <Container maxWidth='sm'>
                        <Grid item md={12} xs={12}>
                            <Stack spacing={1} sx={{ display: 'flex', alignItems: 'flex-start', marginY: 7 }}>
                                <Typography
                                    variant='h6'
                                    sx={{
                                        fontWeight: 600,
                                    }}
                                >
                                    Reset Password
                                </Typography>
                                <Typography
                                    variant='body1'
                                    sx={{
                                        fontWeight: 400,
                                    }}
                                >
                                    Please set up a new password for your account so that you can log in and access all the features
                                </Typography>
                            </Stack>
                        </Grid>

                        <Grid item md={12} xs={12}>

                            <form onSubmit={resetPassData}>
                                <Stack spacing={2}>
                                    <Box sx={{ position: "relative" }}>
                                        <TextField
                                            name="password"
                                            required
                                            variant="outlined"
                                            value={passwordInput.password}
                                            type={showPassword ? "text" : "password"}
                                            label="New Password"
                                            fullWidth
                                            onChange={handlePasswordChange}
                                            onClick={() => setIsPasswordFieldSelected(true)}
                                        />
                                        {showPassword ?
                                            <VisibilityOutlinedIcon sx={{ position: "absolute", top: 17, right: 10, cursor: "pointer", color: "#666666" }} onClick={() => setShowPassword(!showPassword)} />
                                            :
                                            <VisibilityOffOutlinedIcon sx={{ position: "absolute", top: 17, right: 10, cursor: "pointer", color: "#666666" }} onClick={() => setShowPassword(!showPassword)} />
                                        }
                                    </Box>
                                    <Box sx={{ display: isPasswordFieldSelected ? 'block' : 'none' }}>
                                        <Typography variant='caption' sx={{ color: '#1D1E1B', marginTop: '30px !important' }}>
                                            <li style={{ color: validationStatus.uppercase ? '#0079C0' : '#1D1E1B' }}>
                                                Uppercase letters (A - Z)
                                            </li>
                                            <li style={{ color: validationStatus.lowercase ? '#0079C0' : '#1D1E1B' }}>
                                                Lowercase letters (a - z)
                                            </li>
                                            <li style={{ color: validationStatus.numbers ? '#0079C0' : '#1D1E1B' }}>
                                                Numbers (0 - 9)
                                            </li>
                                            <li style={{ color: validationStatus.specialChar ? '#0079C0' : '#1D1E1B' }}>
                                                Special character (@#$!%*?&)
                                            </li>
                                            <li style={{ color: validationStatus.minLength ? '#0079C0' : '#1D1E1B' }}>
                                                Minimum password length is 8 character
                                            </li>
                                        </Typography>
                                    </Box>
                                    <Box sx={{ position: "relative", marginTop: '30px !important' }}>
                                        <TextField
                                            name="password_confirmation"
                                            required
                                            variant="outlined"
                                            value={passwordInput.password_confirmation}
                                            type={showConfirmPassword ? "text" : "password"}
                                            label="Confirm Password"
                                            fullWidth
                                            onChange={handlePasswordChange}
                                        />
                                        {showConfirmPassword ?
                                            <VisibilityOutlinedIcon sx={{ position: "absolute", top: 17, right: 10, cursor: "pointer", color: "#666666" }} onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                                            :
                                            <VisibilityOffOutlinedIcon sx={{ position: "absolute", top: 17, right: 10, cursor: "pointer", color: "#666666" }} onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                                        }
                                    </Box>
                                    <p className="text-danger">{passwordInput.password_confirmation && (passwordInput.password !== passwordInput.password_confirmation) && "Password is not matched"}</p>

                                    <LoadingButton
                                        className='site-button forgot_pass_btn'
                                        variant='contained'
                                        type='submit'
                                        sx={{
                                            width: '30%'
                                        }}
                                    >
                                        Submit
                                    </LoadingButton>
                                </Stack>
                            </form>
                        </Grid>
                    </Container>
                </Card>

                <SuccessModal
                    open={responseSuccessModal}
                    onClose={() => setResponseSuccessModal(false)}
                />
                <ErrorModal
                    open={responseErrorModal}
                    onClose={() => setResponseErrorModal(false)}
                />
            </Box>
        </>
    )
}