import React from "react";
import CandidateDashboardSidebar from "../../components/CandidateComponents/CandidateDashboardSideBar/CandidateDashboardSidebar";
import AttachResume from "../../components/CandidateComponents/CandidateCVManager/AttachResume";
import { Box, Grid } from "@mui/material";
import ShortFooter from "../../components/ShortFooter";

function CVManager() {
  return (
    <div className='' >
      <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' }, position: 'fixed', height: '70px', backgroundColor: '#0275D8', width: '100%', zIndex: 10 }}></Box>

      <Grid container>
        <Grid item xs={0} sm={0} md={3} lg={2.5} xl={2} sx={{ zIndex: 11, display: { xs: 'none', sm: 'none', md: 'block' } }}>
          <CandidateDashboardSidebar />
        </Grid>
        <Grid item xs={12} sm={12} md={9} lg={9.5} xl={10} sx={{zIndex: 11, marginTop: {xs:"0px", sm:"0px", md: "20px"}, paddingBottom: {xs: "0px", md: "120px"}, position: "relative" }}>
          <Box sx={{padding: {md: "10px"}}}>
            <AttachResume />
          </Box>
          <Box sx={{display: {xs: "none", md: "block"}, position: "absolute", bottom: 0, width: "100%"}}>
            <ShortFooter />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default CVManager;
