import React from "react";
import { Link } from 'react-router-dom'
import { Box, Typography, Card } from '@mui/material';

export default function ProfileStrengthView({candidateData, profileStrength}) {

    return (
        <Card
            className="shadow-sm steps"
            sx={{
            padding: '10px',
            border: '1px solid #DADADA',
            marginTop: "-25px",
            }}
        > 
            <Box textAlign="center" sx={{mb: 2, py: 1}}>
                <Typography
                    sx={{
                    fontFamily: 'Epilogue',
                    fontSize: '14px',
                    fontWeight: 600,
                    color: '#424447',
                    mb: 1
                    }}
                >
                    Your Profile Strength 
                    <Typography component="span" sx={{ color: '#15A449', px: 0.5 }}>
                        ({profileStrength}%)
                    </Typography>
                    Only
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Epilogue',
                        fontSize: '13px',
                        fontWeight: 500,
                        color: '#72737C',
                        pt: 1,
                    }}
                >
                    Complete the following steps to finish setting up your profile
                </Typography>
            </Box>
            <hr />
            {/* profile strength details */}
            <Box sx={{px: 2}}>
                <Box sx={{display: "flex", alignItems: "center", columnGap: 1, py: "10px"}}> 
                    <img src={candidateData.first_name ? '/v2/icons/new-check.png' : '/v2/icons/new-unfinished.png'} alt="" /> 
                    <Link to="/candidate-update-profile?scrollTo=basic_info_block">
                        <Typography component="span" sx={{ color: candidateData?.first_name ? "#15A449" : "#0079C1", fontFamily: "Epilogue", fontSize: "14px", fontWeight: 600 }}>
                            Basic Details (+10%)
                        </Typography>
                    </Link>
                </Box>

                <Box sx={{display: "flex", alignItems: "center", columnGap: 1, py: "10px"}}> 
                    <img src={candidateData.intro ? '/v2/icons/new-check.png' : '/v2/icons/new-unfinished.png'} alt="" /> 
                    <Link to="/candidate-update-profile?scrollTo=about_box">
                        <Typography component="span" sx={{ color: candidateData?.intro ? "#15A449" : "#0079C1", fontFamily: "Epilogue", fontSize: "14px", fontWeight: 600 }}>
                            Profile Summary (+10%)
                        </Typography>
                    </Link>
                </Box>

                <Box sx={{display: "flex", alignItems: "center", columnGap: 1, py: "10px"}}> 
                    <img src={!!candidateData?.skills?.length ? '/v2/icons/new-check.png' : '/v2/icons/new-unfinished.png'} alt="" /> 
                    <Link to="/candidate-update-profile?scrollTo=skill_box">
                        <Typography component="span" sx={{ color: !!candidateData?.skills?.length ? "#15A449" : "#0079C1", fontFamily: "Epilogue", fontSize: "14px", fontWeight: 600 }}>
                            Key Skills (+10%)
                        </Typography>
                    </Link>
                </Box>

                <Box sx={{display: "flex", alignItems: "center", columnGap: 1, py: "10px"}}> 
                    <img src={!!candidateData?.candidate_experience?.length ? '/v2/icons/new-check.png' : '/v2/icons/new-unfinished.png'} alt="" /> 
                    <Link to="/candidate-update-profile?scrollTo=employment_box">
                        <Typography component="span" sx={{ color: !!candidateData?.candidate_experience?.length ? "#15A449" : "#0079C1", fontFamily: "Epilogue", fontSize: "14px", fontWeight: 600 }}>
                            Employment History (+10%)
                        </Typography>
                    </Link>
                </Box>

                <Box sx={{display: "flex", alignItems: "center", columnGap: 1, py: "10px"}}> 
                    <img src={!!candidateData?.candidate_education?.length ? '/v2/icons/new-check.png' : '/v2/icons/new-unfinished.png'} alt="" /> 
                    <Link to="/candidate-update-profile?scrollTo=education_box">
                        <Typography component="span" sx={{ color: !!candidateData?.candidate_education?.length ? "#15A449" : "#0079C1", fontFamily: "Epilogue", fontSize: "14px", fontWeight: 600 }}>
                            Education History (+10%)
                        </Typography>
                    </Link>
                </Box>

                <Box sx={{display: "flex", alignItems: "center", columnGap: 1, py: "10px"}}> 
                    <img src={!!candidateData?.candidate_resume?.length ? '/v2/icons/new-check.png' : '/v2/icons/new-unfinished.png'} alt="" /> 
                    <Link to="/candidate-update-profile?scrollTo=resume_box">
                        <Typography component="span" sx={{ color: !!candidateData?.candidate_resume?.length ? "#15A449" : "#0079C1", fontFamily: "Epilogue", fontSize: "14px", fontWeight: 600 }}>
                            Resume (+10%)
                        </Typography>
                    </Link>
                </Box>

                <Box sx={{display: "flex", alignItems: "center", columnGap: 1, py: "10px"}}> 
                    <img src={candidateData?.gender && candidateData?.dob ? '/v2/icons/new-check.png' : '/v2/icons/new-unfinished.png'} alt="" /> 
                    <Link to="/candidate-update-profile?scrollTo=personal_box">
                        <Typography component="span" sx={{ color: candidateData?.gender && candidateData?.dob ? "#15A449" : "#0079C1", fontFamily: "Epilogue", fontSize: "14px", fontWeight: 600 }}>
                            Personal Details (+5%)
                        </Typography>
                    </Link>
                </Box>

                <Box sx={{display: "flex", alignItems: "center", columnGap: 1, py: "10px"}}> 
                    <img src={(candidateData?.qualification && (!!candidateData?.preferred_districts?.length) && (!!candidateData?.preferred_jobcategories?.length) && (!!candidateData?.preferred_organization_types?.length)) ? '/v2/icons/new-check.png' : '/v2/icons/new-unfinished.png'} alt="" /> 
                    <Link to="/candidate-update-profile?scrollTo=update_candidate_career_box">
                        <Typography component="span" sx={{ color: candidateData?.qualification && (!!candidateData?.preferred_districts?.length) && (!!candidateData?.preferred_jobcategories?.length) && (!!candidateData?.preferred_organization_types?.length) ? "#15A449" : "#0079C1", fontFamily: "Epilogue", fontSize: "14px", fontWeight: 600 }}>
                            Career & Application Info (+5%)
                        </Typography>
                    </Link>
                </Box>

                <Box sx={{display: "flex", alignItems: "center", columnGap: 1, py: "10px"}}> 
                    <img src={!!candidateData.certification?.length ? '/v2/icons/new-check.png' : '/v2/icons/new-unfinished.png'} alt="" /> 
                    <Link to="/candidate-update-profile?scrollTo=certificate_box">
                        <Typography component="span" sx={{ color: !!candidateData.certification?.length ? "#15A449" : "#0079C1", fontFamily: "Epilogue", fontSize: "14px", fontWeight: 600 }}>
                            Training & Certifications (+7%)
                        </Typography>
                    </Link>
                </Box>

                <Box sx={{display: "flex", alignItems: "center", columnGap: 1, py: "10px"}}> 
                    <img src={!!candidateData.projects?.length ? '/v2/icons/new-check.png' : '/v2/icons/new-unfinished.png'} alt="" /> 
                    <Link to="/candidate-update-profile?scrollTo=project_box">
                        <Typography component="span" sx={{ color: !!candidateData.projects?.length ? "#15A449" : "#0079C1", fontFamily: "Epilogue", fontSize: "14px", fontWeight: 600 }}>
                            Projects (+5%)
                        </Typography>
                    </Link>
                </Box>

                <Box sx={{display: "flex", alignItems: "center", columnGap: 1, py: "10px"}}> 
                    <img src={!!candidateData.languages?.length ? '/v2/icons/new-check.png' : '/v2/icons/new-unfinished.png'} alt="" /> 
                    <Link to="/candidate-update-profile?scrollTo=candidate_update_language_box">
                        <Typography component="span" sx={{ color: !!candidateData.languages?.length ? "#15A449" : "#0079C1", fontFamily: "Epilogue", fontSize: "14px", fontWeight: 600 }}>
                            Languages (+5%)
                        </Typography>
                    </Link>
                </Box>

                <Box sx={{display: "flex", alignItems: "center", columnGap: 1, py: "10px"}}> 
                    <img src={!!candidateData.candidate_hobbies?.length ? '/v2/icons/new-check.png' : '/v2/icons/new-unfinished.png'} alt="" /> 
                    <Link to="/candidate-update-profile?scrollTo=candidate_update_hobby_box">
                        <Typography component="span" sx={{ color: !!candidateData.candidate_hobbies?.length ? "#15A449" : "#0079C1", fontFamily: "Epilogue", fontSize: "14px", fontWeight: 600 }}>
                            Hobbies and interests (+3%)
                        </Typography>
                    </Link>
                </Box>

                <Box sx={{display: "flex", alignItems: "center", columnGap: 1, py: "10px"}}> 
                    <img src={!!candidateData.references?.length ? '/v2/icons/new-check.png' : '/v2/icons/new-unfinished.png'} alt="" /> 
                    <Link to="/candidate-update-profile?scrollTo=candidate_update_reference_box">
                        <Typography component="span" sx={{ color: !!candidateData.references?.length ? "#15A449" : "#0079C1", fontFamily: "Epilogue", fontSize: "14px", fontWeight: 600 }}>
                            References (+7%)
                        </Typography>
                    </Link>
                </Box>

                <Box sx={{display: "flex", alignItems: "center", columnGap: 1, py: "10px"}}> 
                    <img src={!!candidateData.social_networks?.length ? '/v2/icons/new-check.png' : '/v2/icons/new-unfinished.png'} alt="" /> 
                    <Link to="/candidate-update-profile?scrollTo=social_box">
                        <Typography component="span" sx={{ color: !!candidateData.social_networks?.length ? "#15A449" : "#0079C1", fontFamily: "Epilogue", fontSize: "14px", fontWeight: 600 }}>
                            Social profiles (+3%)
                        </Typography>
                    </Link>
                </Box>

            </Box>
        </Card>
    )
}