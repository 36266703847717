import { Button, Stack, SvgIcon, Box, Breadcrumbs, Tooltip, Link, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import Card from "react-bootstrap/Card";
import Template2 from "../ResumeTemplates/Template2";
import ATSTemplate from "../ResumeTemplates/ATSTemplate";
import { resumeGenerate } from "../../../../fetchAPI";
import DefaultResume from "../ResumeTemplates/DefaultResume";
import { CandidateContext } from "../../../context/CandidateContext";
import { LoadingButton } from "@mui/lab";
import { useAlert } from "../../../context/AlertContext";

function DownloadIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 14.59l-4.25-4.24 1.41-1.41L11 13.17l6.34-6.34 1.41 1.41L11 16.59z" />
        </SvgIcon>
    );
}

export default function GenerateResumeV3() {

    const { candidateData } = useContext(CandidateContext)
    const alertContext = useAlert();

    const [selectedColor, setSelectedColor] = useState({
        start_color: '#163853',
        end_color: '#163853'
    })

    const [loading, setLoading] = useState(false);

    const handleColorClick = (startColor, endColor) => {
        setSelectedColor({
            start_color: startColor,
            end_color: endColor,
        });
    };

    const colors = [
        {
            start_color: '#163853',
            end_color: '#163853'
        },
        {
            start_color: '#0079C1',
            end_color: '#00395B'
        },
        {
            start_color: '#2193B0',
            end_color: '#6DD5ED'
        },
        {
            start_color: '#373B44',
            end_color: '#4286F4'
        },
        {
            start_color: '#283C86',
            end_color: '#45A247'
        },
        {
            start_color: '#DA4453',
            end_color: '#89216B'
        },
        {
            start_color: '#6713d2',
            end_color: '#cc208e'
        },
        {
            start_color: '#67B26F',
            end_color: '#4CA2CD'
        },
        {
            start_color: '#870000',
            end_color: '#190A05'
        },

    ]

    function getFileNameFromMediaLink(link) {
        let resumeReverse = link.split('').reverse().join('');
        let reverseArray = resumeReverse.split("/")
        let filename = reverseArray[0].split('').reverse().join('')
        return filename
    }

    const generateResume = (candidateId, templateId, name) => {
        setLoading(true);
        resumeGenerate(candidateId, templateId)
            .then((response) => {
                if (response.headers.get('Content-Type') === 'application/pdf') {
                    return response.blob();
                }

                return response.json().then((data) => {
                    if (data.error) {
                        alertContext.setResponseMessage(data.error || 'An error occurred while downloading the resume.');
                        setLoading(false);
                        alertContext.setOpenErrorAlert(true);
                        throw new Error('Validation error');
                    }
                    
                    throw new Error('Unexpected response format');
                });
            })
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${name}.pdf`);

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error:', error); // Log error
                setLoading(false);
            });
    };


    return (
        <>
            <Card className="shadow-sm mt-4 mt-md-0">
                <Card.Header className="bg-white py-4 px-3">
                    <Stack
                        direction={'row'}
                        spacing={2}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                    >

                        {/* Breadcrumbs */}
                        <Breadcrumbs separator=">" aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" href="/cv-manager">
                                CV Manager
                            </Link>
                            <Typography sx={{ color: 'text.primary' }}>Generate Resume</Typography>
                        </Breadcrumbs>

                        <LoadingButton
                            loading={loading}
                            variant='contained'
                            startIcon={<DownloadIcon />}
                            onClick={() => generateResume(candidateData.id, 2, candidateData.first_name)}
                            sx={{
                                backgroundColor: '#0079C1',
                                color: 'white',
                                textTransform: 'capitalize'
                            }}
                        >
                            Download PDF
                        </LoadingButton>
                    </Stack>


                </Card.Header>


                <Card.Body style={{ backgroundColor: '#f5f5f5' }}>
                    <Stack direction={'row'} sx={{ overflowY: 'auto', height: 'auto' }}>
                        <ATSTemplate baseColor={selectedColor} />
                        {/* <DefaultResume baseColor={selectedColor} /> */}
                    </Stack>

                    {/* Pagination */}
                    {/* <Box>
                        <Pagination
                            page={selectedNumber}
                            count={resumeList.length}
                            color="primary"
                            shape="rounded"
                            boundaryCount={1}
                            onChange={changeItem}
                            sx={{
                                '& .MuiPaginationItem-root': {
                                    fontSize: '0.75rem',
                                },
                                '& .MuiPaginationItem-page.Mui-selected': {
                                    fontSize: '0.75rem',
                                },
                                '@media (max-width: 600px)': {
                                    '& .MuiPaginationItem-root': {
                                        fontSize: '0.65rem',
                                    },
                                    '& .MuiPaginationItem-page.Mui-selected': {
                                        fontSize: '0.65rem',
                                    },
                                    '& .MuiPaginationItem-ellipsis': {
                                        display: 'none',
                                    },
                                },
                            }}
                        />
                    </Box> */}

                    {/* Color Palette */}
                    {/* <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: 3,
                    }}>
                        {colors.map((color, index) => (
                            <Tooltip key={index}>
                                <Box
                                    sx={{
                                        width: 30,
                                        height: 30,
                                        borderRadius: '50%',
                                        margin: 0.5,
                                        cursor: 'pointer',
                                        border: `2px solid grey`,
                                        '&:hover': {
                                            border: `2px solid black`,
                                        },
                                        background: color.start_color, // Fallback for old browsers
                                        background: `-webkit-linear-gradient(to bottom, ${color.start_color}, ${color.end_color})`, // Chrome 10-25, Safari 5.1-6
                                        background: `linear-gradient(to bottom, ${color.start_color}, ${color.end_color})`, // W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+       
                                    }}
                                    onClick={() => handleColorClick(color.start_color, color.end_color)}
                                />
                            </Tooltip>
                        ))}
                    </Box> */}

                </Card.Body>
            </Card>
        </>
    )
}