import Cookies from "js-cookie";

const getRoles = async () => {
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/role`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Role Error")
  }
};

const loginUser = async (body) => {
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/login`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );
    return await response.json();
  } catch (err) {
    throw Error(
      "500 Internal Server Error: " + err.message + ", unable to connect"
    );
    // console.log("Login Error")
  }
};

const adminLoginUser = async (body) => {
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/super-admin/login`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );
    return await response.json();
  } catch (err) {
    throw Error(
      "500 Internal Server Error: " + err.message + ", unable to connect"
    );
    // console.log("Login Error")
  }
};

const registerUser = async (body) => {
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/register`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );
    return await response.json();
  } catch (err) {
    throw Error(
      "500 Internal Server Error: " + err.message + ", unable to connect"
    );
    // console.log("Failed to call registration API from backend", err)
  }
};

const newRegisterUser = async (body) => {
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/user/register`,
      {
        method: "POST",
        headers: {
          // Accept: "application/json",
          // "Content-Type": "application/json",
        },
        body: body,
      }
    );
    return await response.json();
  } catch (err) {
    throw Error(
      "500 Internal Server Error: " + err.message + ", unable to connect"
    );
    // console.log("Failed to call registration API from backend", err)
  }
};

const forgetPassword = async (body) => {
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/password/email`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: body,
        }),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Failed to send Email", err)
    throw Error(
      "500 Internal Server Error: " + err.message + ", unable to connect"
    );
  }
};

const resetPassword = async (body) => {
  // console.log('p', JSON.stringify(body))
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/password/reset`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Failed to reset your password", err)
  }
};

const getCandidateDataByID = async () => {
  try {
    const user = JSON.parse(Cookies.get("user"));
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/by/user/${user.id}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Candidate By ID Error")
  }
};

const searchJob = async (jobSearchData) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_SEARCH_SERVER_BASE_URL}/search/job?page=${jobSearchData.queryKey[2]}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          // "Authorization": "Bearer "+bearerToken
        },
        body: JSON.stringify({
          ...jobSearchData.queryKey[1],
          token: bearerToken
        }),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Job Search Error")
  }
};

const searchCompany = async (companySearchData) => {
  // console.log('cs->',companySearchData);
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/search/employers?page=${companySearchData.queryKey[2]}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...companySearchData.queryKey[1],
        }),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Company Search Error")
  }
};

const searchCandidates = async (candidateSearchData) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_SEARCH_SERVER_BASE_URL}/search/candidates?page=${candidateSearchData.queryKey[2]}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify({
          ...candidateSearchData.queryKey[1],
        }),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Job Search Error")
  }
};

const getAllCandidates = async () => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidates`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get All Candidates Error")
  }
};

const getCounters = async () => {
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/counters`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Counters Error")
  }
};

const getBenefitSliderData = async () => {
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/benefit-categories?limit=2`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Counters Error")
  }
};

// Get Candidate Project By ID
const getCandidateProjectByID = async (id) => {
  try {
    const user = JSON.parse(Cookies.get("user"));
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/project`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Candidate Project By ID Error")
  }
};

// Post Candidate Project By ID
const postCandidateProjectByID = async (candId, projectData) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/project/post`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify(projectData),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Post Candidate Project By ID Error", err)
  }
};

// Delete Candidate Project By ID
const deleteCandidateProjectByID = async (projectId) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/project/delete/${projectId}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Delete Candidate Skill By ID Error", err)
  }
};

// Update Candidate Project Data
const updateCandidateProjectByID = async (candId, projectId, updateData) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/project/update/${projectId}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify(updateData),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Update Candidate Project By ID Error", err)
  }
};

// Get Employer Data
const getEmployerByID = async (userId) => {
  try {
    // const user = JSON.parse(Cookies.get('user'))
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/employer/by/user/${userId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Candidate By ID Error")
  }
};

// Get Employer Data By Slug
const getEmployerBySlug = async (slug) => {
  try {
    // const user = JSON.parse(Cookies.get('user'))
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/employer/profile/${slug}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Candidate By ID Error")
  }
};

// Update Employer Data
const updateEmployerByID = async (empId, body) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/employer/update`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify(body),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Update Employer By ID Error", err)
  }
};

// Get All JObs
const getAllJobs = async (pageNumber) => {
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/jobpost?page=${pageNumber}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get All Jobs Error")
  }
};

//Post Job
const postJob = async (body) => {
  try {
    const bearerToken = Cookies.get("token");
    const user = JSON.parse(Cookies.get("user"));
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/employer/job/post`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify(body),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Post Job Error", err)
  }
};

// update Job By ID
const updateJob = async (body) => {
  try {
    const bearerToken = Cookies.get("token");
    const user = JSON.parse(Cookies.get("user"));
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/job/${body.post_id}/update`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify(body),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Update Job By ID Error", err)
  }
};

// delete Job By ID
const deleteJob = async (body) => {
  try {
    const bearerToken = Cookies.get("token");
    const user = JSON.parse(Cookies.get("user"));
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/job/delete`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify(body),
        // body: body
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Delete Job By ID Error", err)
  }
};

// Shifts
const getShifts = async () => {
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/job_shifts`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Shift Error")
  }
};

// Types
const getTypes = async () => {
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/types`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Types Error")
  }
};

// Currencies
const getCurrencies = async () => {
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/currencies`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Currencies Error")
  }
};

// Top Featured Companies
const getTopFeaturedCompanies = async () => {
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/top-featured-companies`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Top Featured Companies Error")
  }
};

// Qualification
const getQualifications = async () => {
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/qualifications`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Types Error")
  }
};

// Districts
const getDistricts = async () => {
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/districts`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Types Error")
  }
};

// Upazillas
const getUpazillas = async (district_id) => {
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/upazillas/${district_id}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Types Error")
  }
};

// Areas
const getAreas = async (upazilla_id) => {
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/areas/${upazilla_id}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Types Error")
  }
};

// Skills
const getSkills = async () => {
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/skills`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Skills Error")
  }
};

// Levels
const getLevels = async () => {
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/levels`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Skills Error")
  }
};

// Department
const getDepartment = async () => {
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/departments`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Department Error")
  }
};

// Industry
const getIndustry = async () => {
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/industries`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Department Error")
  }
};

// Position
const getPositions = async () => {
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/positions`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Department Error")
  }
};

// Company Specialization
const getSpecialization = async () => {
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/specializations`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Department Error")
  }
};

// Salary Types
const getSalaryTypes = async () => {
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/salarytypes`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Salary Types Error")
  }
};

// update CandidateBy ID
const updateCandidate = async (body) => {
  // console.log(body)
  try {
    const bearerToken = Cookies.get("token");
    const user = JSON.parse(Cookies.get("user"));
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/${user.id}/update`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify(body),
      }
    );
    return await response.json();
  } catch (err) {
    console.log("Update Candidate By ID Error", err);
  }
};

// Insert Candidate Type
const insertCandidateType = async (id, body) => {
  try {
    const bearerToken = Cookies.get("token");
    // const user = JSON.parse(Cookies.get('user'))
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/${id}/type/insert`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify(body),
      }
    );
    return await response.json();
  } catch (err) {
    console.log("Insert Candidate Type ID Error", err);
  }
};

// Calling Captcha
const showCaptcha = async () => {
  try {
    let response = await fetch(`${process.env.REACT_APP_CAPTCHA_PATH_URL}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // 'Access-Control-Allow-Origin': '*',
        // "Access-Control-Allow-Methods": "POST,GET,PUT,DELETE, OPTIONS",
        // "Access-Control-Allow-Headers": "Authorization, Lang"
      },
    });
    return await response.json();
  } catch (err) {
    // console.log("Get Captcha Error", err)
  }
};

// insert Candidate Skill By ID
const insertCandidateSkillByID = async (id, body) => {
  try {
    const bearerToken = Cookies.get("token");
    const user = JSON.parse(Cookies.get("user"));
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/skill/insert`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify(body),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("insert Candidate Skill By ID Error", err)
  }
};

//Update Candidate Skill By ID
const updateCandidateSkillByID = async (candId, body) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/skill/update`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify(body),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Update Candidate Skill By ID Error", err)
  }
};

// Delete Candidate Skill By ID
const deleteCandidateSkillByID = async (candId, skillId) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/skill/delete/${skillId}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Delete Candidate Skill By ID Error", err)
  }
};

//Post Candidate Experience By ID
const postCandidateExperienceByID = async (candId, body) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/experience/post`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify(body),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Post Candidate Experience By ID Error", err)
  }
};

//Update Candidate Experience By ID
const updateCandidateExperienceByID = async (
  candId,
  experienceId,
  updatedData
) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/experience/update/${experienceId}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify(updatedData),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Update Candidate Experience By ID Error", err)
  }
};

// Delete Candidate Experience By ID
const deleteCandidateExperienceByID = async (candId, experienceId) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/experience/delete/${experienceId}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Delete Candidate Skill By ID Error", err)
  }
};

// Post Candidate Education By ID
const postCandidateEducationByID = async (candId, body) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/education/post`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify(body),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Post Candidate Education By ID Error", err)
  }
};

//Update Candidate Education By ID
const updateCandidateEducationByID = async (candId, eduId, updateData) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/education/update/${eduId}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify(updateData),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Update Candidate Education By ID Error", err)
  }
};

// Delete Candidate Education By ID
const deleteCandidateEducationByID = async (candId, eduId) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/education/delete/${eduId}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Delete Candidate Education By ID Error", err)
  }
};

// Post Candidate Certificate By ID
const postCandidateCertificateByID = async (certiData) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/certificate/post`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: certiData,
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Post Candidate Certificate By ID Error", err)
  }
};

//Update Certificate Education By ID
const updateCertificateEducationByID = async (certiId, body) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/certificate/update/${certiId}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: body,
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Update Candidate Certificate By ID Error", err)
  }
};

// Delete Candidate Certificate By ID
const deleteCandidateCertificateByID = async (candId, certiId) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/certificate/delete/${certiId}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Delete Candidate Certificate By ID Error", err)
  }
};

// Get Employer Saving Candidate Info By ID
const getEmployerSaveCandidateInfo = async (id) => {
  try {
    const bearerToken = Cookies.get("token");
    const user = JSON.parse(Cookies.get("user"));
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/employer/watchList`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Employer Saving Candidate Info By ID", err)
  }
};

// Post Employer Saving Candidate Info By ID
const postEmployerSaveCandidateInfo = async (empId, candId) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/employer/save/profile/candidate`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify({ candidate_id: candId }),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Post Employer Saving Candidate Info By ID Error", err)
  }
};

// Post Employer Saving Candidate Info By ID
const deleteEmployerSaveCandidateInfo = async (empId, candId) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/employer/candidate/unsaved`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify({ candidate_id: candId }),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Delete Employer Saving Candidate Info By ID Error", err)
  }
};

//Follow Company List for Candidate Account
const followList = async (candidateId) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/followList`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Follow Company List for Candidate Error", err)
  }
};

//Follow Company from Candidate Account
const followCompany = async (candidateId, employeId) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/follow/company`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify({ employer_id: employeId }),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Follow Company from Candidate Error", err)
  }
};

//UnFollow Company from Candidate Account
const unFollowCompany = async (candidateId, employeId) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/follow/company/unfollow`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify({ employer_id: employeId }),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("UnFollow Company from Candidate Error", err)
  }
};

//Follow Company from Candidate Account
const jobsByCategory = async (type, catId) => {
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/job/list/all/by/${type}/${catId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Job By Type Error", err)
  }
};

// Get Candidate Saving Job Info By ID
const getCandidateSaveJobInfo = async (candidateId) => {
  try {
    const user = JSON.parse(Cookies.get("user"));
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/savedJobs`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Candidate Saving Job Info By ID Error")
  }
};

// Get Recommended Job Info By ID
const getCandidateRecommendedJobInfo = async (candidateId) => {
  try {
    const user = JSON.parse(Cookies.get("user"));
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/recommended/job/infos`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Recommended Job Info By ID Error")
  }
};

// Post Candidate Saving Job Info By ID
const postCandidateSaveJobInfo = async (candidateId, jobId) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/save/job`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify(jobId),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Post Candidate Saving Job Info By ID Error", err)
  }
};

// Delete Candidate Saving Job Info By ID
const deleteCandidateSaveJobInfo = async (candidateId, jobId) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/saved/job/delete/${jobId}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Delete Candidate Saving Job Info By ID Error", err)
  }
};

//Generate Resume
const resumeGenerate = async (candidateId, templateId) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/generate/resume/${candidateId}/${templateId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return response;
  } catch (err) {
    // console.log("Generate Resume Error", err)
  }
};

//Download Resume
const downloadResume = async (resumeId) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/resume/${resumeId}/download`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return response;
  } catch (err) {
    // console.log("Resume Download Error", err)
  }
};

//Get Resume List
const getResumeListById = async (candidateId) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/resume`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Resume by Id Error", err)
  }
};

//Upload Resume
const uploadResume = async (candidateId, uploadfile) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/resume/${candidateId}/upload`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "content-type": uploadfile.file.type,
          "content-length": `${uploadfile.file?.size}`,
          Authorization: "Bearer " + bearerToken,
        },
        body: uploadfile,
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Resume Upload Error", err)
  }
};

//Delete Resume
const deleteResume = async (candidateId, resumeId) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/resume/delete/${resumeId}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Resume Delete Error", err)
  }
};

// Post Candidate Saving Job Info By ID
const applyToJob = async (jobId, body) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/job/${jobId}/apply`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          // "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: body,
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Post Candidate Saving Job Info By ID Error", err)
  }
};

// Password Change
const changePassword = async (body) => {
  try {
    const bearerToken = Cookies.get("token");
    const user = JSON.parse(Cookies.get("user"));
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/password/change`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify(body),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Password Change Error", err)
  }
};

//Upload Profile Picture
const uploadProfilePic = async (uploadfile) => {
  try {
    const bearerToken = Cookies.get("token");
    const user = JSON.parse(Cookies.get("user"));
    const user_id = user.id;
    const role_id = user.user_role_id;
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/user/${user_id}/profile/${role_id}/picture/upload`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + bearerToken,
        },
        body: uploadfile,
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Profile Pic Upload Error", err)
  }
};

//Get Profile Picture
const getProfilePic = async (userId, roleId) => {
  try {
    const bearerToken = Cookies.get("token");
    // const user = JSON.parse(Cookies.get('user'))
    // const user_id = user.id
    // const role_id = user.user_role_id
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/user/profile/picture`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Profile Pic Get Error", err)
  }
};

//Get Cover Picture
const getCoverPic = async (userId, roleId) => {
  try {
    const bearerToken = Cookies.get("token");
    // const user = JSON.parse(Cookies.get('user'))
    // const user_id = user.id
    // const role_id = user.user_role_id
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/user/${userId}/cover/${roleId}/picture`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Cover Pic Get Error", err)
  }
};

//Apply Status list
const applyStatusList = async () => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/applystatus`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Apply Status Link Error", err)
  }
};

// Status Change
const changeApplyStatus = async (jobId, body) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/job/${jobId}/applied/status/update`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify(body),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Post Candidate Saving Job Info By ID Error", err)
  }
};

//Post Contact Information
const postContactInformation = async (body) => {
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/contact/email`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Post Contact Information Error", err)
  }
};

//Get Candidate Profile Strength
const getProfilePercentage = async () => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/profile-strength-percentage`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Profile Percentage Error")
  }
};

//Update Employer Social Network
const updateEmployerSocialNetwork = async (empId, socialId, body) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/employer/${empId}/social/${socialId}/update`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify(body),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Update Employer Social Network Error", err)
  }
};

//Get Social Network
const getSocialNetwork = async (id) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/social/network/all`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Social Network Error")
  }
};

// Post Employer Social Network By ID
const postEmployerSocialNetworkByID = async (empId, body) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/employer/social/insert`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify(body),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Post Employer Social Network By ID Error", err)
  }
};

// Delete Employer Social Network By ID
const deleteEmployerSocialNetworkByID = async (id, body) => {
  try {
    const bearerToken = Cookies.get("token");
    const user = JSON.parse(Cookies.get("user"));
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/employer/social/delete/${body}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify(body),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Delete Employer Social Network By ID Error", err)
  }
};

// Post Candidate Social Network By ID
const postCandidateSocialNetworkByID = async (candId, body) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/social/insert`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify(body),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Post Candidate Social Network By ID Error", err)
  }
};

// Update Candidate Social Network By ID
const updateCandidateSocialNetworkByID = async (candId, socialId, body) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/social/update/${socialId}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify(body),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Update Candidate Social Network By ID Error", err)
  }
};

// Delete Candidate Social Network By ID
const deleteCandidateSocialNetworkByID = async (candId, socialId) => {
  try {
    const bearerToken = Cookies.get("token");
    const user = JSON.parse(Cookies.get("user"));
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/social/delete/${socialId}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Delete Candidate Social Network By ID Error", err)
  }
};

// Delete Custom Question by question ID
const deleteCustomQuestion = async (jobId, questionId) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/job/${jobId}/delete/question/${questionId}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Delete Candidate Social Network By ID Error", err)
  }
};

// Update Custom Question by question ID
const updateCustomQuestion = async (jobId, questions) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/job/${jobId}/update/question`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify({ custom_question: questions }),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Delete Candidate Social Network By ID Error", err)
  }
};

// Single Job Details by Job Id
const jobDetails = async (jobId) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/job-post/${jobId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Active Jobs Error")
  }
};

//Get Active Jobs
const getActiveJobs = async (queryInfo) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/job/list/by/employer/${queryInfo.queryKey[1]}/${queryInfo.queryKey[2]}?page=${queryInfo.queryKey[3]}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Active Jobs Error")
  }
};

// get candidate applied job list
const appliedJobList = async (statusId, pageno) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/appliedjobinfo/${statusId}?page=${pageno}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Delete Candidate Social Network By ID Error", err)
  }
};

// Applied Candidate Search
const appliedCandidateSearch = async (jobSearchData) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/search/job/${jobSearchData.queryKey[3]}/candidate/applied?page=${jobSearchData.queryKey[2]}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify({
          ...jobSearchData.queryKey[1],
        }),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Post Candidate Social Network By ID Error", err)
  }
};

// get single job details
const getJobDetails = async (jobId) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/job-post/${jobId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Delete Candidate Social Network By ID Error", err)
  }
};

// get al Publish job by Employer
const getPublishedJobByEmployer = async (empId) => {
  const bearerToken = Cookies.get("token");
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/employer/${empId}/pulished-job`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Delete Candidate Social Network By ID Error", err)
  }
};

// get Candidate By User Id
const getCandidateByUserId = async (userId) => {
  const bearerToken = Cookies.get("token");
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/by/user/${userId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("GET Candidate By ID Error", err)
    throw Error(
      "500 Internal Server Error: " + err.message + ", unable to connect"
    );
  }
};

// get Candidate By Slug
const getCandidateBySlug = async (slug) => {
  const bearerToken = Cookies.get("token");
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/profile/${slug}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("GET Candidate By ID Error", err)
    throw Error(
      "500 Internal Server Error: " + err.message + ", unable to connect"
    );
  }
};

// Update Candidate Basic info
const updateCandidateBasicInfo = async (candId, updateParameters) => {
  // console.log(candId, updateParameters)
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/basic-info/update`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify(updateParameters),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Post Candidate Social Network By ID Error", err)
  }
};

// Update Candidate Salary info
const updateCandidateSalaryInfo = async (candId, updateParameters) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/salary-info/update`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify(updateParameters),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Update Candidate Salary info By ID Error", err)
  }
};

// get Landing Page Job Type by Job Type
const getLandingPageJobType = async (type_id) => {
  const bearerToken = Cookies.get("token");
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/job/${type_id}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("get Landing Page Job Type by Job Type Error", err)
  }
};

// get Messages by Candidate
const getMessageThread = async (page) => {
  const bearerToken = Cookies.get("token");
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/message/read-unread-message?page=${page}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("get Landing Page Job Type by Job Type Error", err)
  }
};
// get Messages by Employer
const getMessagesByEmployer = async (page) => {
  const bearerToken = Cookies.get("token");
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/employer/message/read-unread-message?page=${page}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("get Landing Page Job Type by Job Type Error", err)
  }
};

// get Bar Chart Data By User Id
const getEmployerBarChart = async (userId) => {
  const bearerToken = Cookies.get("token");
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/employer/jobs/applied-status`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Bar Chart Data By User Id ID Error", err)
  }
};

// get Weekly Applicant Data By Id
const getweeklyapplicantsbyID = async (id) => {
  const bearerToken = Cookies.get("token");
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/employer/job/current-week/applicants
        `,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Weekly Applicant Data By ID Error", err)
  }
};

// get Single Applied Candidate Info
const getsingleAppliedCandidate = async (jobId, candId) => {
  const bearerToken = Cookies.get("token");
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/job/${jobId}/applicant/${candId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Weekly Applicant Data By ID Error", err)
  }
};

// get Suggested Candidate Info
const getSuggestedCandidate = async (employer_id) => {
  const bearerToken = Cookies.get("token");
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/employer/suggested-candidate`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    console.log("Get Suggested Candidate Error", err);
  }
};

// get Home Category Info
const getCategoryData = async () => {
  const bearerToken = Cookies.get("token");
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/job-categories`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    console.log("Get Category Error", err);
  }
};

// get Recommended Job Data Info
const recommendedJobData = async (page = 1) => {
  const bearerToken = Cookies.get("token");
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/recommended/jobs?page=${page}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    console.log("Get Recommended Job Data Error", err);
  }
};

// Post Recommended Jobs preference
const postRecommendedJobs = async (body) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/recommended/job/info/insert`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify(body),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log(" Post Recommended Jobs preference Error", err)
  }
};

// get All Categories Data Info
const getAllCategories = async (id) => {
  const bearerToken = Cookies.get("token");
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/all/categories`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    console.log("Get All Categories Data Error", err);
  }
};

// Get Packages for payment gateway
const getPackages = async () => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/packages`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get PackagesError")
  }
};

// Get Check Reference for Candidate
const getCheckReference = async () => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/reference/check`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Check Reference for Candidate Error")
  }
};

// Get Order History by User ID
const getOrderHistory = async (user_id) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/user/${user_id}/order/history`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Order History by User ID Error")
  }
};

// Post Order Data by User ID
const postOrderData = async (user_id, body) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/user/${user_id}/package/order`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify(body),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log(" Post Order Data by User ID Error", err)
  }
};

// Get payment success Data
const getPaymentSuccessData = async () => {
  try {
    let response = await fetch(`http://localhost:5000/payment_success_data`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return await response.json();
  } catch (err) {
    // console.log("Get Candidate By ID Error")
  }
};

// Post payment success data
const postPaymentResponseData = async (user_id, successData) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/user/${user_id}/transaction/history`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify(successData),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log(" Post Order Data by User ID Error", err)
  }
};

// Get payment failed Data
const getPaymentFailedData = async () => {
  try {
    let response = await fetch(`http://localhost:5000/payment_failed_data`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return await response.json();
  } catch (err) {
    // console.log("Get Candidate By ID Error")
  }
};

// get Similar jobs
const getSimilarJobs = async (job_id) => {
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/similar/jobs/${job_id}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get job Error", err)
    // throw err
  }
};

// Get notification by user id
const getNotifications = async (userId, currentPage) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/notification?page=${currentPage}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Candidate By ID Error")
    throw err;
  }
};

// Get Health Check
const getHealthCheck = async () => {
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/healthcheck`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Health Check Error")
    throw err;
  }
};

// Update notification by notification id
const updateNotification = async (userId, notificationUpdateData) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/notification/update`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify(notificationUpdateData),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Candidate By ID Error")
  }
};

// Delete notification by notification id
const deleteNotification = async (userId, notificationId) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/notification/delete`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify(notificationId),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Candidate By ID Error")
  }
};

// Single Benefit List Category
const getBenifits = async () => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/benefit/category`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get SIngle job details error", err)
  }
};

// Employer Benefit List Category
const getEmployerBenefitList = async () => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/employer/benefit/category-options`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Employer Benefit List Category", err)
  }
};

// Job Benefit List Category
const getJobBenefitList = async () => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/jobs/benefit-options`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Job Benefit List Category", err)
  }
};


// Child of a benifit category
const getBenifitChild = async (catId) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/benefit/category/${catId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get SIngle job details error", err)
  }
};

// get employer benefits list
const getEmployerBenefits = async () => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/employer/benefit/category`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get SIngle job details error", err)
  }
};

// get employer benefits list
const getEmployerBenefitOptions = async (catId) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/employer/benefit/category/${catId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get SIngle job details error", err)
  }
};

// Update Profile Strength Check for Candidate
const updateProfileStrength = async (id, profileStrengthCheck) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/profile-strength/update`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify(profileStrengthCheck),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Update Profile Strength Check for Candidate Error")
  }
};

//get hot cats
const getHotCategories = async () => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/top-job`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get SIngle job details error", err)
  }
};

// Get Top Jobs Weekly/Monthly
const getTopJobs = async (latestJobType) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/latest-job/${latestJobType}?`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Top Jobs Error")
  }
};

//get employer images cats

const getAllEmployerImage = async () => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/all-employer/logo`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Single job details error", err)
  }
};

//get job by gender

const getJobByGender = async (genderId) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/${genderId}/jobs`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Single job details error", err)
  }
};

// benefit slider

const getBenefitSlider = async (genderId) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/jobs/benefit`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Single job details error", err)
  }
};

// backend server check api

const getServerResponse = async () => {
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/maintenance/check`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Get Single job details error", err)
  }
};

//Get Candidate Own Information
const getCandidateSelf = async () => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/me`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Cover Pic Get Error", err)
  }
};

//Get Employer Own Information
const getEmployerSelf = async () => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/employer/me`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Cover Pic Get Error", err)
  }
};

//Get High Paid Jobs
const highPaidJobs = async () => {
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/jobs/high-paid`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Cover Pic Get Error", err)
  }
};

//Get Expire Soon Jobs
const expireSoonJobs = async () => {
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/jobs/expire/soon`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Cover Pic Get Error", err)
  }
};

//Get Most recent jobs
const mostRecentJobs = async () => {
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/jobs/recent`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Cover Pic Get Error", err)
  }
};

//Get Live Jobs jobs
const liveJobs = async () => {
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/jobs/live`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Cover Pic Get Error", err)
  }
};

//Post Candidate Register Flow
const candidateRegisterFlow = async (key, jobId = null, paramData) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/${key}/apply/job/${jobId}`,
      {
        method: "POST",
        headers:
          key === "resume"
            ? { Authorization: "Bearer " + bearerToken }
            : {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + bearerToken,
            },
        body: key === "resume" ? paramData : JSON.stringify(paramData),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Cover Pic Get Error", err)
  }
};

// special-page/configuration/save
const postPageConfiguration = async (paramData) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/special-page/configuration/save`,
      {
        method: "POST",
        headers: {
          // Accept: "application/json",
          // "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: paramData,
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Cover Pic Get Error", err)
  }
};

// Log Out API 
const postLogout = async (paramData) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/logout`,
      {
        method: "POST",
        headers: {
          // Accept: "application/json",
          // "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: paramData,
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Log Out API Error", err)
  }
};

// special-page/{route_name}
const getSpecialPageConfig = async (routeName) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/special-page/${routeName}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Cover Pic Get Error", err)
  }
};

// special-page/filtered-job/
const getSpecialPageFilteredJobs = async (paramData, routeName, pageNumber) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/special-page/${routeName}/filtered-job?page=${pageNumber}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: paramData,
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Cover Pic Get Error", err)
  }
};

// employer/leader/insert
const insertEmployerTeam = async (paramData) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/employer/leader/insert`,
      {
        method: "POST",
        headers: {
          // 'Content-Type': 'multipart/form-data',
          Authorization: "Bearer " + bearerToken,
        },
        body: paramData,
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Cover Pic Get Error", err)
  }
};

// Gallery insert for Employer profile
const insertEmployerGallery = async (paramData) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/employer/gallery/insert`,
      {
        method: "POST",
        headers: {
          // 'Content-Type': 'multipart/form-data',
          Authorization: "Bearer " + bearerToken,
        },
        body: paramData,
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Cover Pic Get Error", err)
  }
};

// employer/content/insert
const insertEmployerContent = async (paramData) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/employer/content/insert`,
      {
        method: "POST",
        headers: {
          // 'Content-Type': 'multipart/form-data',
          Authorization: "Bearer " + bearerToken,
        },
        body: paramData,
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Cover Pic Get Error", err)
  }
};

// Get Content title list
const getEmployerContentTitle = async () => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/employer/content/section/lists`,
      {
        method: "GET",
        headers: {
          // 'Content-Type': 'multipart/form-data',
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Cover Pic Get Error", err)
  }
};

//Get existing gallery images
const getEmployerGalleryImages = async (employer_id) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/employer/gallery/list/${employer_id}`,
      {
        method: "GET",
        headers: {
          // 'Content-Type': 'multipart/form-data',
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Cover Pic Get Error", err)
  }
};

//get employer team members
const getEmployerTeamMembers = async (employer_id) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/employer/leader/list/${employer_id}`,
      {
        method: "GET",
        headers: {
          // 'Content-Type': 'multipart/form-data',
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Cover Pic Get Error", err)
  }
};

//'employer/content/list/{employer_id
const getEmployerContents = async (employer_id) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/employer/content/list/${employer_id}`,
      {
        method: "GET",
        headers: {
          // 'Content-Type': 'multipart/form-data',
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Cover Pic Get Error", err)
  }
};

//
const updateTeamMembers = async (member_id, paramData) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/employer/leader/update/${member_id}`,
      {
        method: "POST",
        headers: {
          // 'Content-Type': 'multipart/form-data',employer/leader/update/{leader_id}
          Authorization: "Bearer " + bearerToken,
        },
        body: paramData,
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Cover Pic Get Error", err)
  }
};

//Delete team member
const deleteTeamMembers = async (member_id) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/employer/leader/delete/${member_id}`,
      {
        method: "DELETE",
        headers: {
          // 'Content-Type': 'multipart/form-data',employer/leader/update/{leader_id}
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Cover Pic Get Error", err)
  }
};

//employer/content/update/{content_id}
const updateEmployerContent = async (content_id, paramData) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/employer/content/update/${content_id}`,
      {
        method: "POST",
        headers: {
          // 'Content-Type': 'multipart/form-data',employer/leader/update/{leader_id}
          Authorization: "Bearer " + bearerToken,
        },
        body: paramData,
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Cover Pic Get Error", err)
  }
};

//Gallery delete Api
const deleteEmployerGallery = async (gallery_id) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/employer/gallery/delete/${gallery_id}`,
      {
        method: "DELETE",
        headers: {
          // 'Content-Type': 'multipart/form-data',employer/leader/update/{leader_id}
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Cover Pic Get Error", err)
  }
};

// Employer Organization Structure update
const updateEmployerOrgStructure = async (employer_id, paramData) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/employer/organizational/structure/image/update/${employer_id}`,
      {
        method: "POST",
        headers: {
          // 'Content-Type': 'multipart/form-data',employer/leader/update/{leader_id}
          Authorization: "Bearer " + bearerToken,
        },
        body: paramData,
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Cover Pic Get Error", err)
  }
};

const insertEmployerCCMail = async (paramData) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/employer/email/insert`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify(paramData),
      }
    );
    return await response.json();
  } catch (err) { }
};

const updateEmployerCCMail = async (id, paramData) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/employer/email/update/${id}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify(paramData),
      }
    );
    return await response.json();
  } catch (err) { }
};

const deleteEmployerCCMail = async (id) => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/employer/email/delete/${id}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) { }
};

const getEmployerCCMails = async () => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/employer/email/list`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
      }
    );
    return await response.json();
  } catch (err) { }
};

// post to request cxo eligibility
// const postCxoEligibility = async (paramData) => {
//   try {
//     const bearerToken = Cookies.get("token");
//     let response = await fetch(
//       `${process.env.REACT_APP_API_SERVER_BASE_URL}/search/job`,
//       {
//         method: "POST",
//         headers: {
//           Accept: "application/json",
//           "Content-Type": "application/json",
//           Authorization: "Bearer " + bearerToken,
//         },
//         body: JSON.stringify(paramData),
//       }
//     );
//     return await response.json();
//   } catch (err) {}
// };

//Check Cxo Eligibility
const getCxoEligibility = async () => {
  try {
    const bearerToken = Cookies.get('token');
    let response = await fetch(`${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/cxo-eligibility`, {
      method: 'GET',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + bearerToken
      },
    });
    return await response.json();
  }
  catch (err) {
  }
};

//Post Cxo Eligibility
const postCxoEligibility = async (paramData) => {
  try {
    const bearerToken = Cookies.get('token');
    let response = await fetch(`${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/cxo-eligibility-request`, {
      method: 'POST',
      headers: {
        Authorization: "Bearer " + bearerToken
      },
      body: paramData
    });
    return await response.json();
  }
  catch (err) {
  }
};

// Check job if it is CXO or not 
const getJobCxoOrNot = async (jobId) => {
  try {
    const bearerToken = Cookies.get('token');
    let response = await fetch(`${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/cxo-job-eligibility/${jobId}`, {
      method: 'GET',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + bearerToken
      },
    });
    return await response.json();
  }
  catch (err) {
  }
};

// hobbies and interest
// Post Hobby
const postHobby = async (body) => {

  try {
    const bearerToken = Cookies.get('token');
    let response = await fetch(`${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/hobbies/insert`, {
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + bearerToken
      },
      body: JSON.stringify(body)
    });
    return await response.json();
  }
  catch (err) {
    // console.log("Post Hobby Error", err)
  }
};

//Update hobby
const updateHobby = async (hobbyId, updateData) => {
  try {
    const bearerToken = Cookies.get('token');
    let response = await fetch(`${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/hobbies/update/${hobbyId}`, {
      method: 'PUT',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + bearerToken
      },
      body: JSON.stringify(updateData)
    });
    return await response.json();
  }
  catch (err) {
    // console.log("Update Hobby Error", err)
  }

};

// Delete Hobby
const deleteHobby = async (id) => {

  try {
    const bearerToken = Cookies.get('token');
    let response = await fetch(`${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/hobbies/delete/${id}`, {
      method: 'DELETE',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + bearerToken
      }
    });
    return await response.json();
  }
  catch (err) {
    // console.log("Delete Hobby Error", err)
  }
};

// language
const getAllLanguages = async () => {
  try {
    const bearerToken = Cookies.get('token');
    let response = await fetch(`${process.env.REACT_APP_API_SERVER_BASE_URL}/languages`, {
      method: 'GET',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + bearerToken
      },
    });
    return await response.json();
  }
  catch (err) {
  }
};

const postCandidateLanguage = async (body) => {

  try {
    const bearerToken = Cookies.get('token');
    let response = await fetch(`${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/language/insert`, {
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + bearerToken
      },
      body: JSON.stringify(body)
    });
    return await response.json();
  }
  catch (err) {
    // console.log("Post Hobby Error", err)
  }
};


const deleteCandidateLanguage = async (id) => {

  try {
    const bearerToken = Cookies.get('token');
    let response = await fetch(`${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/language/delete/${id}`, {
      method: 'DELETE',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + bearerToken
      }
    });
    return await response.json();
  }
  catch (err) {
    // console.log("Delete Hobby Error", err)
  }
};

//post candidate reference
const postCandidateReference = async (body) => {
  console.log(body);
  try {
    const bearerToken = Cookies.get('token');
    let response = await fetch(`${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/reference/insert`, {
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + bearerToken
      },
      body: JSON.stringify(body)
    });
    return await response.json();
  }
  catch (err) {
    // console.log("Post Hobby Error", err)
  }
};

const updateCandidateReference = async (id, updateData) => {
  try {
    const bearerToken = Cookies.get('token');
    let response = await fetch(`${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/reference/update/${id}`, {
      method: 'PUT',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + bearerToken
      },
      body: JSON.stringify(updateData)
    });
    return await response.json();
  }
  catch (err) {
    // console.log("Update Hobby Error", err)
  }

};

const deleteCandidateReference = async (id) => {

  try {
    const bearerToken = Cookies.get('token');
    let response = await fetch(`${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/reference/delete/${id}`, {
      method: 'DELETE',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + bearerToken
      }
    });
    return await response.json();
  }
  catch (err) {
    // console.log("Delete Hobby Error", err)
  }
};

const updateCandidateCareerInfo = async (updateData) => {
  try {
    const bearerToken = Cookies.get('token');
    let response = await fetch(`${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/job/preferenses/insert`, {
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + bearerToken
      },
      body: JSON.stringify(updateData)
    });
    return await response.json();
  }
  catch (err) {
    // console.log("Update Hobby Error", err)
  }

};

const deleteOrganizationStructureImage = async () => {
  try {
    const bearerToken = Cookies.get('token');
    let response = await fetch(`${process.env.REACT_APP_API_SERVER_BASE_URL}/employer/organizational/structure/image/delete`, {
      method: 'DELETE',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + bearerToken
      }
    });
    return await response.json();
  }
  catch (err) {
    // console.log("Update Hobby Error", err)
  }
}

const deleteJobCustomQuestionOption = async (optionId) => {
  try {
    const bearerToken = Cookies.get('token');
    let response = await fetch(`${process.env.REACT_APP_API_SERVER_BASE_URL}/question/option/${optionId}`, {
      method: 'DELETE',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + bearerToken
      }
    });
    return await response.json();
  }
  catch (err) {
    // console.log("Update Hobby Error", err)
  }
}

const postActivityLikes = async (params) => {
  try {
    const bearerToken = Cookies.get('token');
    let response = await fetch(`${process.env.REACT_APP_API_SERVER_BASE_URL}/activity-like`, {
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + bearerToken
      },
      body: JSON.stringify(params)
    });
    return await response.json();
  }
  catch (err) {
    // console.log("Activity log error", err)
  }

};

const postActivityLogs = async (params) => {
  try {
    const bearerToken = Cookies.get('token');
    let response = await fetch(`${process.env.REACT_APP_API_SERVER_BASE_URL}/activity-logs`, {
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + bearerToken
      },
      body: JSON.stringify(params)
    });
    return await response.json();
  }
  catch (err) {
    // console.log("Activity log error", err)
  }

};

const getSkillMatchPercentageInJob = async (job_id, candidate_id) => {
  try {
    const bearerToken = Cookies.get('token');
    let response = await fetch(`${process.env.REACT_APP_API_SERVER_BASE_URL}/job/${job_id}/skill/percentage/${candidate_id}`, {
      method: 'GET',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + bearerToken
      },
    });
    return await response.json();
  }
  catch (err) {
  }
};

const stepperStepInfo = async (step_no, ip, platform = "web") => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/stepper-logs`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify({ step_no, ip, platform }),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Post Candidate Saving Job Info By ID Error", err)
  }
};

const popUpLog = async (popup_id, ip, platform = "web") => {
  try {
    const bearerToken = Cookies.get("token");
    let response = await fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/popup-logs`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
        },
        body: JSON.stringify({ popup_id, ip, platform }),
      }
    );
    return await response.json();
  } catch (err) {
    // console.log("Post Candidate Saving Job Info By ID Error", err)
  }
};

export {
  getRoles,
  getCounters,
  getSkills,
  getSalaryTypes,
  getProfilePercentage,
  getDistricts,
  getUpazillas,
  getAreas,
  loginUser,
  getSimilarJobs,
  getCandidateRecommendedJobInfo,
  getAllCandidates,
  getCandidateDataByID,
  getCandidateProjectByID,
  postCandidateProjectByID,
  updateCandidateProjectByID,
  deleteCandidateProjectByID,
  getAllCategories,
  registerUser,
  newRegisterUser,
  updateCandidate,
  insertCandidateType,
  insertCandidateSkillByID,
  updateCandidateSkillByID,
  deleteCandidateSkillByID,
  getAllJobs,
  getShifts,
  getTypes,
  getEmployerByID,
  getEmployerBySlug,
  updateEmployerByID,
  getSpecialization,
  postCandidateExperienceByID,
  updateCandidateExperienceByID,
  deleteCandidateExperienceByID,
  getCheckReference,
  getLevels,
  getQualifications,
  getDepartment,
  getIndustry,
  getPositions,
  getMessageThread,
  postCandidateEducationByID,
  updateCandidateEducationByID,
  deleteCandidateEducationByID,
  postCandidateCertificateByID,
  updateCertificateEducationByID,
  deleteCandidateCertificateByID,
  getResumeListById,
  getEmployerSaveCandidateInfo,
  postEmployerSaveCandidateInfo,
  deleteEmployerSaveCandidateInfo,
  postJob,
  updateJob,
  deleteJob,
  forgetPassword,
  resetPassword,
  unFollowCompany,
  followList,
  getCandidateSaveJobInfo,
  postCandidateSaveJobInfo,
  deleteCandidateSaveJobInfo,
  postContactInformation,
  updateEmployerSocialNetwork,
  getSocialNetwork,
  postEmployerSocialNetworkByID,
  searchJob,
  deleteEmployerSocialNetworkByID,
  postCandidateSocialNetworkByID,
  updateCandidateSocialNetworkByID,
  deleteCandidateSocialNetworkByID,
  getMessagesByEmployer,
  getActiveJobs,
  getEmployerBarChart,
  getweeklyapplicantsbyID,
  getSuggestedCandidate,
  getCategoryData,
  recommendedJobData,
  postRecommendedJobs,
  getPackages,
  getOrderHistory,
  postOrderData,
  showCaptcha,
  followCompany,
  jobsByCategory,
  downloadResume,
  uploadResume,
  deleteResume,
  resumeGenerate,
  applyToJob,
  changePassword,
  uploadProfilePic,
  applyStatusList,
  changeApplyStatus,
  getProfilePic,
  getCoverPic,
  searchCandidates,
  deleteCustomQuestion,
  updateCustomQuestion,
  jobDetails,
  appliedJobList,
  appliedCandidateSearch,
  getJobDetails,
  getPublishedJobByEmployer,
  getCandidateByUserId,
  getCandidateBySlug,
  updateCandidateBasicInfo,
  getLandingPageJobType,
  getsingleAppliedCandidate,
  getPaymentSuccessData,
  postPaymentResponseData,
  getPaymentFailedData,
  updateCandidateSalaryInfo,
  updateProfileStrength,
  postLogout,

  getNotifications,
  updateNotification,
  deleteNotification,
  getBenefitSliderData,

  getBenifits,
  getBenifitChild,
  getEmployerBenefits,
  getEmployerBenefitOptions,
  getEmployerBenefitList,
  getJobBenefitList,
  getHotCategories,
  getTopJobs,
  getAllEmployerImage,
  getJobByGender,
  getBenefitSlider,
  getServerResponse,

  getCandidateSelf,
  getEmployerSelf,
  getHealthCheck,

  highPaidJobs,
  expireSoonJobs,
  mostRecentJobs,

  candidateRegisterFlow,

  postPageConfiguration,
  getSpecialPageConfig,
  getSpecialPageFilteredJobs,
  liveJobs,

  searchCompany,
  adminLoginUser,
  getCurrencies,
  getTopFeaturedCompanies,

  insertEmployerTeam,
  insertEmployerGallery,
  insertEmployerContent,
  getEmployerContentTitle,
  getEmployerGalleryImages,
  getEmployerTeamMembers,
  getEmployerContents,
  updateTeamMembers,
  deleteTeamMembers,
  updateEmployerContent,
  deleteEmployerGallery,
  updateEmployerOrgStructure,

  insertEmployerCCMail,
  getEmployerCCMails,
  updateEmployerCCMail,
  deleteEmployerCCMail,
  postHobby,
  updateHobby,
  deleteHobby,
  getAllLanguages,
  postCandidateLanguage,
  deleteCandidateLanguage,
  postCandidateReference,
  updateCandidateReference,
  deleteCandidateReference,
  updateCandidateCareerInfo,
  getCxoEligibility,
  postCxoEligibility,
  getJobCxoOrNot,
  deleteOrganizationStructureImage,
  postActivityLogs,
  postActivityLikes,
  deleteJobCustomQuestionOption,
  getSkillMatchPercentageInJob,
  stepperStepInfo,
  popUpLog
};
