import React, { useEffect, useState } from 'react'
import { updateCandidateBasicInfo } from '../../../../../fetchAPI'
import CandidateUpdateModal from './component/CandidateUpdateModal'
import customStyles from './CandidateUpdateProfile.module.css'
import EditSVG from './component/EditSVG'
import SinglePersonalInfoBlock from './component/SinglePersonalInfoBlock'
import { useAlert } from '../../../../context/AlertContext'
import { RELIGION_LIST } from '../../../../../constants'
import { useLocation } from 'react-router-dom'
import { Box, Grid, InputLabel, TextField, Typography } from '@mui/material'
import RequiredAsterisk from './component/RequiredAsterisk'

function UpdatePersonalInfo(props) {
    const alertContext = useAlert();
    const genderList = [{ label: 'Male', value: 'Male' }, { label: 'Female', value: 'Female' }, { label: 'Other', value: 'Other' }]
    const [updatePersonal, setUpdatePersonal] = useState('')

    const [modalOpen, setModalOpen] = useState(false);
    const { search } = useLocation();

	useEffect(() => {
	  const scrollToHash = () => {
		const params = new URLSearchParams(search);
		const scrollTo = params.get('scrollTo');
		if (scrollTo) {
		  setTimeout(() => {
			const element = document.getElementById(scrollTo);
			if (element) {
				const offset = element.offsetTop - 220;
				window.scrollTo({ top: offset, behavior: 'smooth' });
			}
		  }, 100);
		}
	  };
  
	  scrollToHash();
	  window.addEventListener('popstate', scrollToHash);

	  return () => {
		window.removeEventListener('popstate', scrollToHash);
	  };
	}, [search]);

    const handleChange = (name) => (event) => {
        setUpdatePersonal({ ...updatePersonal, [name]: event.target.value })
    }

    const updateCandidatePersonal = (candId, updateParameters) => {
        setModalOpen(false);
        updateCandidateBasicInfo(candId, updateParameters)
            .then(response => {
                if (response && response.success) {
                    alertContext.setResponseMessage(response.response.message)
                    alertContext.setOpenAlert(true)
                    props.renderState()
                } else {
                    const message = response.errors?.map(msg => {
                        alertContext.setResponseMessage(msg.message)
                    })
                    alertContext.setOpenErrorAlert(true)
                }
            })
    }

    const todayDate = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();
        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
        const formattedToday = yyyy + '-' + mm + '-' + dd;

        return formattedToday
    }

    return (
        <Box id='personal_box'>
            <Box className='card shadow-sm'>
                <Box className={customStyles.cardHeader}>
                    <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <Typography sx={{fontSize: "18px", fontWeight: 700, color: "#424447"}}>Personal Info</Typography>
                        <button className={`btn ${customStyles.editButton}`} onClick={() => setModalOpen(true)}>
                            <div style={{ marginRight: '10px' }}>Edit</div>
                            <EditSVG />
                        </button>
                    </Box>
                </Box>
                <Box className='card-body'>
                    <SinglePersonalInfoBlock title="Father's Name" value={props.candidateInfo.father_name} />
                    <SinglePersonalInfoBlock title="Mother's Name" value={props.candidateInfo.mother_name} />
                    <SinglePersonalInfoBlock title="Gender" value={props.candidateInfo.gender} />
                    <SinglePersonalInfoBlock title="Date of Birth" value={props.candidateInfo.dob} />
                    <SinglePersonalInfoBlock title="Home Town" value={props.candidateInfo.birth_city} />
                    <SinglePersonalInfoBlock title="Availablity" value={props.candidateInfo.available_date} />
                    <SinglePersonalInfoBlock title="Martial Status" value={props.candidateInfo.martial_status} />
                    <SinglePersonalInfoBlock title="Looking for Job" value={props.candidateInfo.searching_for_job_status} />
                    <SinglePersonalInfoBlock title="Nationality" value={props.candidateInfo.nationality} />
                    <SinglePersonalInfoBlock title="Religion" value={props.candidateInfo.religion} />
                    <SinglePersonalInfoBlock title="Current Location  " value={props.candidateInfo.current_location} />
                </Box>
            </Box>

            <CandidateUpdateModal open={modalOpen} onClose={() => setModalOpen(false)} title='Personal Information' onFormSubmit={() => updateCandidatePersonal(props.candidateInfo.id, updatePersonal)}>
                <Grid container spacing={3} rowSpacing={1} sx={{padding: {md: 3}}}>
                    <Grid item xs={12} md={6}>
                        <InputLabel>Father's Name</InputLabel>
                        <TextField className='form-control' fullWidth defaultValue={props.candidateInfo.father_name} onChange={handleChange('father_name')} placeholder='' sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}}} type='text' />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel>Mother's Name</InputLabel>
                        <TextField className='form-control' fullWidth defaultValue={props.candidateInfo.mother_name} onChange={handleChange('mother_name')} placeholder='' sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}}} type='text' />
                    </Grid>
                    <Grid item xs={12} md={6} className='form-group'>
                        <InputLabel>Gender</InputLabel>
                        <select onChange={handleChange('gender')} className='form-control' style={{borderRadius: '5px'}}>
                            <option value=''>Select Gender</option>
                            {
                                genderList.map((item) => {
                                    return (<option key={item.value} selected={item.value == props.candidateInfo.gender ? 'selected' : ''} value={item.value}>{item.value}</option>)
                                })
                            }
                        </select>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel>Date of Birth <RequiredAsterisk /></InputLabel>
                        <TextField className='form-control' fullWidth defaultValue={props.candidateInfo.dob} onChange={handleChange('dob')} placeholder='' sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}}} type='date' />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel>Home Town</InputLabel>
                        <TextField className='form-control' fullWidth defaultValue={props.candidateInfo.birth_city} onChange={handleChange('birth_city')} placeholder='' sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}}} type='text' />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel>Availability</InputLabel>
                        <TextField className='form-control' fullWidth min={todayDate()} defaultValue={props.candidateInfo.available_date} onChange={handleChange('available_date')} placeholder='' sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}}} type='date' />
                    </Grid>
                    <Grid item xs={12} md={6} className='form-group'>
                        <InputLabel>Marital Status</InputLabel>
                        <select onChange={handleChange('martial_status')} className='form-control' style={{borderRadius: '5px'}}>
                            <option value=''>Select Status</option>
                            <option selected={props.candidateInfo.martial_status == 'Married' ? 'selected' : ''} value='Married'>Married</option>
                            <option selected={props.candidateInfo.martial_status == 'Single' ? 'selected' : ''} value='Single'>Single</option>
                        </select>
                    </Grid>
                    <Grid item xs={12} md={6} className='form-group'>
                        <InputLabel>Looking for Job</InputLabel>
                        <select onChange={handleChange('searching_for_job_status')} className='form-control' style={{borderRadius: '5px'}}>
                            <option value=''>Select</option>
                            <option selected={props.candidateInfo.searching_for_job_status == 'Actively' ? 'selected' : ''} value='Actively'>Actively</option>
                            <option selected={props.candidateInfo.searching_for_job_status == 'Yes' ? 'selected' : ''} value='Yes'>Yes</option>
                            <option selected={props.candidateInfo.searching_for_job_status == 'No' ? 'selected' : ''} value='No'>No</option>
                        </select>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel>Nationality</InputLabel>
                        <TextField className='form-control' fullWidth defaultValue={props.candidateInfo.nationality} onChange={handleChange('nationality')} placeholder='' sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}}} type='text' />
                    </Grid>
                    <Grid item xs={12} md={6} className='form-group'>
                        <InputLabel>Religion</InputLabel>
                        <select onChange={handleChange('religion')} className='form-control' style={{borderRadius: '5px'}}>
                            <option value=''>Select Religion</option>
                            {
                                RELIGION_LIST.map((item) => {
                                    return (<option key={item.id} selected={item.value == props.candidateInfo.religion ? 'selected' : ''} value={item.value}>{item.label}</option>)
                                })
                            }
                        </select>
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel>Current Location</InputLabel>
                        <TextField className='form-control' fullWidth defaultValue={props.candidateInfo.current_location} onChange={handleChange('current_location')} placeholder='' sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}}} type='text' />
                    </Grid>
                </Grid>
            </CandidateUpdateModal>
        </Box >
    )
}

export default UpdatePersonalInfo