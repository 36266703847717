import React, { useContext, useEffect, useState } from 'react'
import { Box, Divider, Grid, IconButton, Stack, Typography } from '@mui/material'
import { CandidateContext } from '../../../context/CandidateContext'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { deleteCandidateSaveJobInfo, postCandidateSaveJobInfo } from '../../../../fetchAPI';
import { useHistory } from "react-router-dom";
import { UserContext } from '../../../context/UserContext';
import { LoadingButton } from '@mui/lab';
import { Link } from 'react-router-dom'

function SaveJobIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M14.6875 1.71289C12.3186 1.71289 10.7965 3.26301 10 4.65574C9.20348 3.26301 7.68145 1.71289 5.3125 1.71289C2.30613 1.71289 0 4.01543 0 7.0693C0 10.3807 2.88922 12.6357 7.20363 16.05C9.31621 17.7204 9.45195 17.8473 10 18.2879C10.4473 17.9284 10.6948 17.7117 12.7964 16.05C17.1108 12.6357 20 10.3807 20 7.0693C20 4.01547 17.6939 1.71289 14.6875 1.71289Z" fill="#DB1616" />
        </svg>
    )
}

function SalaryIcon(){
    return (
        <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_6585_32550)">
        <path d="M42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42C32.598 42 42 32.598 42 21Z" fill="#E5F5FF"/>
        <path d="M20.7498 17.1627C18.5096 17.1627 16.5701 18.7185 16.0842 20.9053L14.4928 28.0666C14.4811 28.1191 14.4752 28.1728 14.4752 28.2267C14.4752 28.634 14.8054 28.9642 15.2128 28.9642H26.2867C26.3405 28.9642 26.3942 28.9583 26.4467 28.9467C26.8444 28.8583 27.095 28.4643 27.0067 28.0666L25.4153 20.9053C24.9294 18.7185 22.9899 17.1627 20.7498 17.1627ZM20.7498 15.6875C23.6813 15.6875 26.2194 17.7236 26.8553 20.5853L28.4468 27.7465C28.7119 28.9396 27.9598 30.1216 26.7667 30.3867C26.6091 30.4217 26.4482 30.4394 26.2867 30.4394H15.2128C13.9907 30.4394 13 29.4487 13 28.2267C13 28.0652 13.0177 27.9042 13.0527 27.7465L14.6441 20.5853C15.28 17.7236 17.8182 15.6875 20.7498 15.6875Z" fill="#0079C1"/>
        <path d="M19.962 16.1535C20.1133 16.5317 19.9293 16.961 19.5511 17.1123C19.1729 17.2635 18.7436 17.0796 18.5923 16.7014L17.1171 13.0134C16.8774 12.4141 17.4687 11.8177 18.07 12.0523C18.6223 12.2677 19.034 12.3707 19.2772 12.3707C19.3377 12.3707 19.3879 12.3577 19.4974 12.3112C19.5265 12.2988 19.5606 12.2837 19.6407 12.2483C20.0222 12.0811 20.3236 12.0019 20.7523 12.0019C21.1801 12.0019 21.4862 12.0813 21.869 12.2469C21.9661 12.2894 22.0049 12.3063 22.0392 12.3205C22.1338 12.3594 22.1792 12.3707 22.2276 12.3707C22.4564 12.3707 22.8718 12.2668 23.44 12.0502C24.0405 11.8213 24.6263 12.4166 24.3875 13.0134L22.9124 16.7014C22.761 17.0796 22.3319 17.2635 21.9535 17.1123C21.5753 16.961 21.3914 16.5317 21.5427 16.1535L22.4709 13.8332C22.386 13.8416 22.3051 13.8458 22.2276 13.8458C21.9584 13.8458 21.7352 13.7906 21.4775 13.6845C21.4266 13.6636 21.3709 13.6393 21.2834 13.6008C21.0698 13.5084 20.9488 13.477 20.7523 13.477C20.5578 13.477 20.4436 13.5071 20.2329 13.5994C20.1605 13.6315 20.1147 13.6517 20.0744 13.6689C19.7981 13.7862 19.567 13.8458 19.2772 13.8458C19.1993 13.8458 19.1183 13.8418 19.0341 13.8337L19.962 16.1535Z" fill="#0079C1"/>
        <path d="M24.0241 22.5195V23.5338H20.9999V25.6358C20.9999 25.808 21.0245 25.9371 21.0736 26.0231C21.1228 26.103 21.2334 26.143 21.4055 26.143C21.5469 26.143 21.6852 26.1214 21.8205 26.0784C21.9556 26.0292 22.0786 25.9677 22.1893 25.894C22.2999 25.8142 22.389 25.722 22.4566 25.6174C22.5243 25.513 22.5581 25.4054 22.5581 25.2948C22.5581 25.2395 22.5334 25.1871 22.4843 25.138C22.435 25.0889 22.3399 25.0642 22.1984 25.0642C22.0878 25.0642 21.9895 25.0735 21.9034 25.0919L21.7559 24.1514C21.8665 24.1207 21.9895 24.0992 22.1247 24.0869C22.2661 24.0746 22.4106 24.0685 22.5581 24.0685C22.9207 24.0685 23.2127 24.1514 23.434 24.3174C23.6553 24.4833 23.7659 24.7415 23.7659 25.0919C23.7659 25.4239 23.689 25.7189 23.5353 25.977C23.3816 26.2352 23.1788 26.4502 22.9268 26.6224C22.6749 26.7945 22.3982 26.9267 22.0971 27.0189C21.8021 27.1049 21.5131 27.148 21.2303 27.148C20.9722 27.148 20.754 27.1172 20.5758 27.0558C20.4036 26.9943 20.2654 26.9143 20.1609 26.8159C20.0933 26.7484 20.0287 26.6715 19.9672 26.5855C19.9119 26.4995 19.8658 26.3796 19.8289 26.2259C19.7982 26.0723 19.7828 25.8664 19.7828 25.6082V23.5338H19.0084V22.5195H24.0241ZM19.6906 20.2422C19.8935 20.2422 20.0717 20.2668 20.2254 20.3159C20.379 20.3651 20.5081 20.442 20.6127 20.5464C20.7355 20.6632 20.8308 20.8261 20.8984 21.0351C20.9661 21.2441 20.9999 21.5391 20.9999 21.9203V22.5563H19.7828V21.9663C19.7828 21.8127 19.7706 21.6836 19.746 21.5791C19.7214 21.4746 19.6722 21.3977 19.5984 21.3486C19.5247 21.2933 19.414 21.2656 19.2665 21.2656C19.1866 21.2656 19.1006 21.2748 19.0084 21.2933C18.9223 21.3117 18.8332 21.3394 18.741 21.3762L18.3906 20.4911C18.6303 20.4051 18.8455 20.3436 19.036 20.3067C19.2266 20.2637 19.4448 20.2422 19.6906 20.2422Z" fill="#0079C1"/>
        </g>
        <defs>
        <clipPath id="clip0_6585_32550">
        <rect width="42" height="42" fill="white"/>
        </clipPath>
        </defs>
        </svg>
    )
}

export default function SimilarJobsComponent(props) {

    const { savedJobIds, candidateData, settingCandidateData } = useContext(CandidateContext)
    const { userData } = useContext(UserContext)
    const [loadingStates, setLoadingStates] = useState({});

    const history = useHistory()

    const unSaveCandidateJob = async (candId, jobId, index) => {
        setLoadingStates((prevLoadingStates) => ({ ...prevLoadingStates, [index]: true }));

        await deleteCandidateSaveJobInfo(candId, jobId)
            .then(response => {
                if (response && response.success) {
                    settingCandidateData();
                    setLoadingStates((prevLoadingStates) => ({ ...prevLoadingStates, [index]: false }));
                } else {
                    // Handle failure, show error message or take appropriate action
                    console.log(response)
                }
            })
            .catch(error => {
                // Handle errors, show error message or take appropriate action
                console.log(error)
            })
            .finally(() => {
                setLoadingStates((prevLoadingStates) => ({ ...prevLoadingStates, [index]: false }));
            });
    };

    const saveCandidateJob = async (candId, jobId, index) => {
        setLoadingStates((prevLoadingStates) => ({ ...prevLoadingStates, [index]: true }));

        await postCandidateSaveJobInfo(candId, jobId)
            .then(response => {
                if (response && response.success) {
                    settingCandidateData();
                    setLoadingStates((prevLoadingStates) => ({ ...prevLoadingStates, [index]: false }));
                } else {
                    // Handle failure, show error message or take appropriate action
                    if (!userData && response.errors[0].message === 'Unauthenticated.') {
                        history.push('/login')
                    }
                    else {
                        console.log(response)
                    }
                }
            })
            .catch(error => {
                // Handle errors, show error message or take appropriate action
                console.log(error)
            })
            .finally(() => {
                setLoadingStates((prevLoadingStates) => ({ ...prevLoadingStates, [index]: false }));
            });
    };

    return (
        <>
            <Stack spacing={2}
                sx={{
                    width: '100%',
                    marginTop: 2,
                }}
            >
                {
                    props.data?.map((data, index) => {
                        return (
                            <React.Fragment key={index}>
                                <Box>
                                    <Grid
                                        container
                                        spacing={2}
                                        alignItems={'center'}
                                        justifyContent={'space-between'}
                                        sx={{
                                            flexWrap: 'nowrap'
                                        }}
                                    >
                                        <Grid item>
                                            <Link to={`/jobs/${data?.post_id}`}>
                                                <Typography
                                                    variant='h6'
                                                    sx={{
                                                        fontSize: '15px',
                                                        fontWeight: 700,
                                                        color: '#0275D8'
                                                    }}
                                                >
                                                    {data.job_title}
                                                </Typography>
                                            </Link>
                                        </Grid>
                                        <Grid item >
                                            {candidateData.id !== 0 ?
                                                savedJobIds?.includes(data?.post_id) ?
                                                    <LoadingButton
                                                        loading={loadingStates[index]}
                                                        onClick={() => unSaveCandidateJob(candidateData.id, data?.post_id)}
                                                        aria-label="Unsave"
                                                        component={IconButton}
                                                        sx={{border: 1, 
                                                            borderColor: "#0079C1",                         flexWrap: 'nowrap',
                                                            boxShadow: 0,
                                                            lineHeight: '0px',
                                                            padding: "3px",
                                                            height: '30px',
                                                            minWidth: "50px",
                                                            ":focus": { outline: "none" }
                                                        }}
                                                        variant='outlined'
                                                        size='small'
                                                    >
                                                        <SaveJobIcon />
                                                    </LoadingButton>
                                                    :
                                                    <LoadingButton
                                                        loading={loadingStates[index]}
                                                        onClick={() => saveCandidateJob(candidateData.id, { job_post_id: data.post_id }, index)}
                                                        aria-label="Save"
                                                        component={IconButton}
                                                        sx={{border: 1, 
                                                            borderColor: "#0079C1",                         flexWrap: 'nowrap',
                                                            boxShadow: 0,
                                                            lineHeight: '0px',
                                                            padding: "3px",
                                                            height: '30px',
                                                            minWidth: "50px",
                                                            ":focus": { outline: "none" }
                                                        }}
                                                        variant='outlined'
                                                        size='small'
                                                    >
                                                        <FavoriteBorderIcon />
                                                    </LoadingButton>
                                                    : null
                                            }

                                        </Grid>
                                    </Grid>

                                    <Typography
                                        variant='h6'
                                        sx={{
                                            fontSize: '13px',
                                            fontWeight: 600,
                                            color: '#72737C'
                                        }}
                                    >
                                    { data.is_cxo ? (
                                        candidateData?.is_cxo_eligible || userData?.id === data.employer?.user_id ? (
                                            data.employer?.employer_name
                                        ) : (
                                            <img src="/images/cxo/blurred-text.png" style={{width : '100%'}} />
                                        )
                                        ) : (
                                            data.employer?.employer_name
                                    )}
                                    </Typography>
                                    <Typography
                                        variant='h6'
                                        sx={{
                                            fontSize: '12px',
                                            fontWeight: 700,
                                            marginTop: 1,
                                            display: "flex",
                                            // justifyContent: "center",
                                            alignItems: "center",
                                            gap: 1
                                        }}
                                    >
                                        <Box sx={{width: "42px"}}>
                                            <SalaryIcon />
                                        </Box>
                                        Salary: {
                                            data.minimum_salary && data.negotiable === 0 ?
                                                `${data.minimum_salary} - ${data.maximum_salary} ${data.salary_currency ? data.salary_currency : 'Taka'}/${data.salary_type ? data.salary_type?.type : 'Monthly'}`
                                                :
                                                'Negotiable'
                                        }
                                    </Typography>
                                </Box>

                                {props?.data.length -1 === index ?
                                "" : <Divider />
                                }
                            </React.Fragment>
                        )
                    })
                }

            </Stack>
        </>
    )
}