import React from "react";
import PolicyPage from "../components/PolicyPage";

function TermsAndConditionsPage() {

    return (
        <PolicyPage />
    )
}

export default TermsAndConditionsPage