import React from 'react'
import CandidateDashboardSidebar from '../../components/CandidateComponents/CandidateDashboardSideBar/CandidateDashboardSidebar'
import CandidateMessage from '../../components/CandidateComponents/CandidateMessage'
import { Box } from '@material-ui/core'
import { Grid } from '@mui/material'
import ShortFooter from '../../components/ShortFooter'

export default function CandidateMessagePage() {
    return (
        <div className='' >
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' }, position: 'fixed', height: '70px', backgroundColor: '#0275D8', width: '100%', zIndex: 10 }}></Box>

            <Grid container justifyContent="flex-end" sx={{position: "relative", minHeight: {xs: "100vh", md: "100%"}}}>
                <Grid item xs={0} sm={0} md={3} lg={2.5} xl={2} sx={{ zIndex: 11, display: { xs: 'none', sm: 'none', md: 'block' } }}>
                    <CandidateDashboardSidebar />
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={9.5} xl={10} sx={{ zIndex: 11, display: { md: 'block', marginTop: "30px" }, padding: { xs: '0px 0px 100px 0px' }}}>
                    <CandidateMessage />
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={9.5} xl={10} sx={{position: "absolute", bottom: 0, width: "100%"}}>
                    <ShortFooter />
                </Grid>
            </Grid>
        </div>
    )
}