import React, { useContext, useState } from "react";
import { Button, Card } from "react-bootstrap";
import "./EmployerCard.css";
import { unFollowCompany } from "../../../../fetchAPI";
import { CandidateContext } from "../../../context/CandidateContext";
import SuccessAlert from "../../Alerts/SuccessAlert";
import { useHistory } from "react-router-dom";
import { Box, Typography } from "@mui/material";

function EmployerCard(props) {
  const history = useHistory();
  const [openAlert, setOpenAlert] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const { candidateData, followEmployerIds, settingCandidateData } = useContext(
    CandidateContext
  );
  const unFollow = (candId, empId) => {
    unFollowCompany(candId, empId).then((response) => {
      if (response && response.success) {
        setResponseMessage(response.response.message);
        setOpenAlert(true);
        settingCandidateData();
      }
    });
  };

  return (
    <Card className="mb-3 shadow-sm bg-white" style={{}}>
      <Card.Body className="p-0">
        <div className="px-3 py-4 d-flex align-items-center">
          <div
            className="d-flex justify-content-center align-items-center p-1"
            style={{
              width: "120px",
              height: "120px",
              border: "1px solid #DADADA",
            }}
          >
            <img
              className="img-fluid"
              style={{
                minWidth: "100px",
              }}
              src={
                props.employerInfo?.profile_pic_base64
                  ? props.employerInfo?.profile_pic_base64
                  : `/images/profile.png`
              }
              alt=""
            />
          </div>
          <div className="ml-3 d-flex flex-column justify-content-between">
            <h4
              className=""
              style={{ fontWeight: 700, fontFamily: "Epilogue", color: '#424447' }}
            >
              {props.employerInfo?.employer_name
                ? props.employerInfo?.employer_name
                : ""}
            </h4>
            {props.employerInfo?.map_location ? (
              <div className="d-flex">
                <img className="" src="/v2/icons/icLocation.svg" alt="" />
                <p
                  className="ml-2 mb-0"
                  style={{
                    fontSize: "14px",
                    fontWeight: 600,
                    fontFamily: "Poppins",
                    color: '#0079C1'
                  }}
                >
                  {props.employerInfo?.map_location
                    ? props.employerInfo?.map_location
                    : ""}
                </p>
              </div>
            ) : null}
          </div>
        </div>
        <Box className=" px-3 py-4 justify-content-between align-items-center border-top" sx={{ display: { xs: 'block', sm: 'block', 'md': 'flex' } }}>
          <Typography sx={{ marginBottom: { xs: '10px', sm: '10px', md: '0px' }, fontSize: "14px", fontWeight: 600, fontFamily: "Poppins", color: '#0079C1' }}>
            Job Opening-[
            {props.employerInfo?.total_published_job
              ? props.employerInfo?.total_published_job
              : "0"}
            ]
          </Typography>
          <div className="d-flex justify-content-between justify-content-lg-center align-items-center">
            <Button
              className="mr-2 w-50 view_profile_btn_click"
              variant="primary"
              onClick={() =>
                history.push(`/employer/profile/${props.employerInfo?.slug}`)
              }
            >
              View Profile
            </Button>
            {followEmployerIds?.includes(props.employerInfo?.id) ? (
              <Button
                className="w-50"
                variant="outline-primary"
                onClick={() =>
                  unFollow(candidateData.id, props.employerInfo?.id)
                }
              >
                Unfollow
              </Button>
            ) : (
              <Button className="w-50" variant="outline-primary">
                Follow
              </Button>
            )}
          </div>
        </Box>
      </Card.Body>
      <SuccessAlert
        open={openAlert}
        message={responseMessage}
        onHide={() => setOpenAlert(false)}
      />
    </Card>
  );
}

export default EmployerCard;
