import React from 'react';
import { Box, Link } from "@mui/material";
import { useTranslation } from "react-i18next";

function ShortFooter() {
    // const { isLoggedin } = useContext(UserContext)
    const currentYear = new Date().getFullYear()
    const { t } = useTranslation("footer")

    return (
        <>
            <Box sx={{display: {xs: "none", md: "flex"}, flexDirection: "row", justifyContent: "space-between", alignItems: "center", zIndex: 1000, position: "relative", backgroundColor: "#EEEEEE", padding: {md: "30px 20px", lg: "30px 60px"} }}>
                <Box sx={{ color: '#424447', fontFamily: "Poppins", fontWeight: 500, fontSize: "13px" }}>
                    © {currentYear} {t("copy-right-section.text-1")} : <a className="fw-bold" href="/" style={{ color: '#0275D8' }}>atb-jobs.com</a>, {t("copy-right-section.text-2")}
                </Box>
                <Box sx={{color: "#0079C1", fontFamily: "Poppins", fontWeight: 500, fontSize: "13px"}}>
                    <Link href="/terms-conditions" underline='none' sx={{":hover": {color: "#0079C1"}}}>Terms and Conditions </Link> <span style={{color: '#424447'}} >&</span> <Link href="/privacy-policy" underline='none' sx={{":hover": {color: "#0079C1"}}}>Privacy Policy</Link>
                </Box>
            </Box>

            {/* small screen */}
            <Box sx={{display: {xs: "flex", md: "none"}, flexDirection: "column", justifyContent: "space-between", alignItems: "center", zIndex: 1000, position: "relative", backgroundColor: "#EEEEEE", padding: "30px" }}>
                <Box sx={{color: "#0079C1", fontFamily: "Poppins", fontWeight: 500, fontSize: "13px", marginBottom: 1}}>
                    <Link href="/terms-conditions" underline='none' sx={{":hover": {color: "#0079C1"}}}>Terms and Conditions </Link> <span style={{color: '#424447'}} >&</span> <Link href="/privacy-policy" underline='none' sx={{":hover": {color: "#0079C1"}}}>Privacy Policy</Link>
                </Box>
                <Box sx={{ color: '#424447', fontFamily: "Poppins", fontWeight: 500, fontSize: "13px", textAlign: "center", wordBreak: "break-word" }}>
                    © {currentYear} {t("copy-right-section.text-1")} : <a className="fw-bold" href="/" style={{ color: '#0275D8' }}>atb-jobs.com</a>, {t("copy-right-section.text-2")}
                </Box>
            </Box>
        
        </>
    )
}

export default ShortFooter