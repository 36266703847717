import React from "react";
import moment from 'moment'
import ReactHtmlParser from 'react-html-parser'
import styles from "../CandidateUpdateProfile.module.css";
import CommonMasking from "../../../../maskingcomponents/CommonMasking";

export default function SingleExpBlock({ item, masking }) {
    let yearDuration = item.work_experience ? (item.work_experience / 12).toFixed(1) : 0;

    return (
        <div style={{ width: '100%' }}>
            {
                masking ?
                    <CommonMasking>
                        <div className={styles.expOrg}>
                            <div>
                                Organization Name
                            </div>
                            <div>
                                &nbsp;&nbsp;|&nbsp;&nbsp;
                            </div>
                            <div>
                                Type
                            </div>
                        </div>
                    </CommonMasking>
                    :
                    <div className={styles.expOrg}>
                        <div>
                            {item.organization_name}
                        </div>

                        {item?.candidate_work_type && (
                            <>
                                <div>
                                    &nbsp;&nbsp;|&nbsp;&nbsp;
                                </div>
                                <div style={{display: "flex", alignItems: "center", columnGap: "5px", padding: "4px 16px", backgroundColor: "#E5F5FF", borderRadius: "20px"}}>
                                    <div style={{display: "flex", alignItems:"center" }}>
                                        <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_7113_6528)">
                                        <path d="M6.39892 7.57196C8.50541 7.57196 10.6127 7.57195 12.72 7.56643C12.9478 7.56643 13.0172 7.62004 13.0132 7.85575C12.9998 8.84041 13.0132 9.82664 13.0069 10.8097C13.0069 11.6958 12.407 12.2997 11.5272 12.3005C8.10046 12.3037 4.67401 12.3037 1.24782 12.3005C0.366435 12.3005 -0.232721 11.6958 -0.235874 10.8129C-0.235874 9.82822 -0.229574 8.84199 -0.242188 7.8597C-0.242188 7.63029 -0.186989 7.56486 0.0479413 7.56643C2.16468 7.57589 4.2814 7.57196 6.39892 7.57196Z" fill="#3BB2F4"/>
                                        <path d="M6.39291 6.15156C4.28406 6.15156 2.17521 6.15154 0.0663574 6.15627C-0.170149 6.15627 -0.26081 6.11843 -0.248985 5.85748C-0.225334 5.35609 -0.243459 4.85234 -0.241094 4.35016C-0.241094 3.43093 0.354908 2.84362 1.28123 2.84046C1.93162 2.84046 2.5828 2.8239 3.23241 2.84834C3.50833 2.85858 3.55878 2.7695 3.54775 2.51802C3.52804 2.08521 3.53828 1.65083 3.54301 1.21723C3.55169 0.507712 4.04834 0.00788356 4.75628 0.00394178C5.84054 -0.00131393 6.92452 -0.00131393 8.00825 0.00394178C8.71383 0.00788356 9.20892 0.510869 9.21681 1.22118C9.22232 1.68473 9.22627 2.14827 9.21681 2.61104C9.21208 2.78921 9.25937 2.84755 9.44542 2.84755C10.125 2.83651 10.8053 2.84202 11.4857 2.8436C12.4104 2.8436 13.0001 3.43488 13.0041 4.35726C13.0041 4.86023 12.9938 5.36319 13.0096 5.86537C13.0175 6.09163 12.9599 6.1618 12.7218 6.16022C10.6106 6.14682 8.50176 6.15156 6.39291 6.15156ZM6.38188 2.84046C6.77606 2.84046 7.17024 2.82862 7.56442 2.84518C7.76623 2.85306 7.81354 2.78054 7.80566 2.5937C7.79225 2.26968 7.79462 1.94487 7.80566 1.62007C7.81039 1.46792 7.76387 1.41669 7.60699 1.41827C6.79025 1.42562 5.97351 1.42562 5.15677 1.41827C4.9991 1.41827 4.95811 1.47502 4.96205 1.62323C4.97073 1.95749 4.9723 2.29254 4.96205 2.6268C4.95653 2.79236 5.00541 2.84833 5.17569 2.8436C5.5746 2.83099 5.97824 2.84046 6.38188 2.84046Z" fill="#3BB2F4"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_7113_6528">
                                        <rect width="13.9148" height="12.3023" fill="white"/>
                                        </clipPath>
                                        </defs>
                                        </svg>
                                    </div>
                                    <div style={{display: "flex", alignItems:"center", color: "#3BB2F4", fontStyle: "Poppins", fontWeight: "normal", fontSize: "13px"}}>
                                        {item.candidate_work_type || ''}
                                    </div>
                                </div>
                            
                            </>
                        )}
                    </div>
            }

            <div className={styles.expDateDuration}>
                {moment(item.start_date).format("MMM YYYY")} - {item.currently_working == 1 ? 'Present' : moment(item.end_date).format("MMM YYYY")}
                &nbsp;{yearDuration > 0 ? ` ( ${yearDuration} Year) ` : ''} | {masking ? <CommonMasking style={{display:'inline'}}>Address, Other Part</CommonMasking> : <>{item.company_location}</> }
            </div>
            {
                masking ?
                    <CommonMasking>
                        <div>
                            <hr />
                            <div className={styles.expTitle}>Job Responsibilities :</div>
                            <div className={styles.expDesc}>
                                1. Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br />
                                2. Nullam nulla turpis, scelerisque sit amet scelerisque vitae, <br />
                                3. fermentum non odio. Nullam sit amet felis sit amet diam aliquam hendrerit <br />
                            </div>
                        </div>
                    </CommonMasking>
                    :
                    <>
                        {
                            item.organization_desc != '' && item.organization_desc != null ?
                                <div>
                                    <hr />
                                    <div className={styles.expTitle}>Job Responsibilities :</div>
                                    <div className={styles.expDesc}>
                                        {ReactHtmlParser(item.organization_desc)}
                                    </div>
                                </div>
                                :
                                ''
                        }
                    </>
            }
            {
                masking ?
                    <CommonMasking>
                        {
                            item.technology != '' && item.technology != null ?
                                <div>
                                    <hr />
                                    <div className={styles.expTitle}>Area of Expertise :</div>
                                    <div className="row" style={{ margin: 'unset' }}>
                                        {
                                            item.technology.map((tech) => {
                                                return (
                                                    <div key={tech.id} className={styles.singleSkill} style={{ margin: '5px' }}>
                                                        Skill - N years
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                :
                                ''
                        }
                    </CommonMasking>
                    :
                    <>
                        {
                            item.technology != '' && item.technology != null ?
                                <div>
                                    <hr />
                                    <div className={styles.expTitle}>Area of Expertise :</div>
                                    <div className="row" style={{ margin: 'unset' }}>
                                        {
                                            item.technology.map((tech) => {
                                                return (
                                                    <div key={tech.id} className={styles.singleSkill} style={{ margin: '5px' }}>
                                                        {tech.skill_name} - {tech?.year_of_experience} years
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                :
                                ''
                        }
                    </>
            }


        </div>
    )
}