import React, { createContext, useState, useEffect } from "react"
import { getEmployerBenefitList, getJobBenefitList } from "../../fetchAPI";
import { useJobSearchHook } from "../CustomHooks/jobSearchHook";
import { useQuery } from "react-query";
import { fetchIpAddress } from "../helpers/helperFunctions";

export const JobSearchContext = createContext();

const JobSearchContextProvider = ({ children }) => {

    const benefitInitialState = {
        job_benefit: [],
        employer_benefit: []
    }

    const [benefits, setBenefits] = useState(benefitInitialState);
    const [jobResults, setJobResults] = useState([]);
    const [jobSearchData, setJobSearchData] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [jobIndexRef, setJobIndexRef] = useState();

    const handleReset = (isCxo, resetButtontrigger, location) => {
        
        if (jobSearchData !== null) {
            setBenefits(benefitInitialState)
            if (isCxo == true) {
                setJobSearchData({ is_cxo: true })
                if (resetButtontrigger || location?.state?.fromHeader) {
                    setJobResults([])
                    setPageNumber(1);
                }
            }
            else {
                setJobSearchData(null)
                setJobResults([])
                setPageNumber(1);
            }
        }
    }

    const handleSearch = (data) => {

        setJobResults([])
        setPageNumber(1);

        // Send final search data to API request
        setJobSearchData({
            ...jobSearchData,
            title: data.title,
            keyword: data.keyword,
            address: data.address,
            employer_name: data.employer_name,
            department: data.department,
            industry: data.industry,
            minimum_experience: data.minimum_experience,
            maximum_experience: data.maximum_experience,
            salary_range_from: data.salary_range_from,
            salary_range_to: data.salary_range_to,
            currency: data.currency,
            negotiable: data.negotiable,
            expire_soon: data.expire_soon,
            employer_name: data.employer_name,
            position: data.position,
            skill: data.skill,
            qualification: data.qualification,
            live_job: data.live_job,
            high_paid_job: data.high_paid_job,
            type: data.type,
            level: data.level,
            gender: data.gender,
            age_from: data.age_from,
            age_to: data.age_to,
            tracking_page: data.tracking_page,
        });
    };    

    // Custom hook to fetch jobs
    const { jobs, isLoading, status } = useJobSearchHook(jobSearchData, pageNumber);

    useEffect(() => {
        if (jobs?.response?.data) {
            setJobResults(prev => [
                ...(prev || []),
                ...jobs.response.data
            ]);
        }
    }, [jobs]);

    // Job Benefit list fetch
    const { data: jobBenefitListResults } = useQuery('Job Benefits List', getJobBenefitList, { staleTime: Infinity })
    const { data: employerBenefitListResults } = useQuery('Employer Benefits List', getEmployerBenefitList, { staleTime: Infinity })

    return (
        <JobSearchContext.Provider value={{
            jobBenefitList: jobBenefitListResults ? jobBenefitListResults.response.data : [],
            employerBenefitList: employerBenefitListResults ? employerBenefitListResults.response.data : [],
            jobSearchData,
            setJobSearchData,
            benefits,
            setBenefits,
            pageNumber,
            setPageNumber,
            handleSearch,
            handleReset,
            jobs,
            jobResults,
            isLoading,
            status,
            jobIndexRef,
            setJobIndexRef,
            setJobResults
        }}>
            {children}
        </JobSearchContext.Provider>
    );
};

export default JobSearchContextProvider;