import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Grid, Skeleton, Typography } from '@mui/material';
import { liveJobs } from '../../../fetchAPI';
import { Link } from 'react-router-dom';
import { GetLiveJobsSliderProps } from '../Slider/SliderFunctions';
import SliderComponent from '../Slider/Slider';
import { useTranslation } from 'react-i18next';

const JobCardSkeleton = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 2,
                border: '1px solid #e0e0e0',
                padding: 2,
                width: {
                    xs: '100%',
                    sm: '100%',
                    md: 330,
                    lg: 370,
                    xl: 460,
                }
            }}
        >
            <Skeleton variant="text" width={250} height={30} animation="wave" />
            <Box sx={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: "space-between", mt: 2 }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Skeleton variant="text" width={200} height={20} animation="wave" />
                    <Skeleton variant="text" width={150} height={20} animation="wave" />
                </Box>
                <Skeleton variant="rounded" width={60} height={60} animation="wave" />
            </Box>
            <Skeleton variant="text" width="100%" height={5} animation="wave" sx={{ mt: 5, mb: 1, borderRadius: 1 }} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Box sx={{ display: 'flex', columnGap: 2 }}>
                    <Skeleton variant="circular" width={40} height={40} animation="wave" />
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Skeleton variant="text" width={50} height={20} animation="wave" />
                        <Skeleton variant="text" width={70} height={20} animation="wave" />
                    </Box>
                </Box>
                <Skeleton variant="rounded" width={120} height={40} animation="wave" sx={{ borderRadius: 2 }} />
            </Box>
        </Box>
    );
};

export default function LiveJobs() {

    const [jobs, setJobs] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    //translations

    const { t } = useTranslation(["homepage", "button"])

    useEffect(() => {
        setIsLoading(true);
        gettingLiveJobs()

    }, []);

    const gettingLiveJobs = () => {

        liveJobs().then((response) => {
            if (response?.success) {
                setJobs(response?.response?.data)
                setIsLoading(false);

            }
            else {
                // console.log('errors: ', response);
            }
        })
    }

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            // partialVisibilityGutter: -38
        },
        tablet: {
            breakpoint: { max: 1024, min: 500 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 500, min: 0 },
            items: 1
        }
    };

    /**
     * Live Jobs Slider Component
     * 2024-01-03 Sajid
     */
    const [slides, setSlides] = useState();
    const [params, setParams] = useState({});
    useEffect(() => {
        if (isLoading) {
            return;
        }
        const { slides, params } = GetLiveJobsSliderProps({
            jobs: jobs?.live_job,
            t: t
        });
        setSlides(slides);
        setParams(params);
    }, [isLoading, jobs, t]);
    const slider = useMemo(() => {
        if (isLoading) {
            return <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                <JobCardSkeleton />
                <Box sx={{ display: { xs: "none", md: "block" } }}>
                    <JobCardSkeleton />
                </Box>
                <Box sx={{ display: { xs: "none", lg: "block" } }}>
                    <JobCardSkeleton />
                </Box>
            </Box>
        }
        return <SliderComponent slides={slides} params={params} />;
    }, [slides, params, isLoading]);

    return (
        <Box sx={{ marginTop: "50px", '@media (max-width: 599px)': { marginTop: "10px" } }}>
            <div className='container'>
                <Grid container spacing={2} sx={{ justifyContent: { xs: 'center', md: 'space-between' }, alignItems: "center" }} className='p-3'>
                    <Grid>
                        {
                            isLoading ?
                                <>
                                    <Grid container spacing={1} sx={{ display: { xs: 'none', md: 'flex' } }}>
                                        <Grid item>
                                            <Skeleton animation='wave' variant="rounded" width={200} height={40} />
                                        </Grid>
                                        <Grid item>
                                            <Skeleton animation='wave' variant="rounded" width={40} height={40} />
                                        </Grid>
                                    </Grid>
                                </>
                                :
                                <Typography variant='h3' className='d-none d-lg-block' sx={{ color: '#424447', fontWeight: 700, fontSize: { xs: '20px', sm: '20px', lg: '28px' }, paddingLeft: "15px" }}>{t('homepage:live-jobs.title')} <span className='' style={{ color: '#DB1616' }}> &nbsp;({Number(jobs?.count).toLocaleString()})</span></Typography>
                        }
                        {
                            isLoading ?
                                <>
                                    <Grid container spacing={1} sx={{ display: { xs: 'flex', md: 'none' }, m: 3 }}>
                                        <Grid item>
                                            <Skeleton animation='wave' variant="rounded" width={200} height={40} />
                                        </Grid>
                                        <Grid item>
                                            <Skeleton animation='wave' variant="rounded" width={40} height={40} />
                                        </Grid>
                                    </Grid>
                                </>
                                :
                                <>
                                    <Typography variant='h3' className='d-lg-none text-center responsive_header' sx={{ color: '#424447', fontWeight: 700, fontSize: { xs: '20px', sm: '20px', lg: '28px' }, paddingLeft: "15px" }}>{t('homepage:live-jobs.title')} <span className='' style={{ color: '#DB1616' }}> &nbsp;({Number(jobs?.count).toLocaleString()})</span></Typography> {/** Mobile View */}
                                </>
                        }
                    </Grid>
                    <Grid>
                        {
                            isLoading ?
                                <Skeleton animation='wave' variant="rounded" width={150} height={50} sx={{ display: { xs: 'none', md: 'flex' } }} />
                                :
                                // <Link to='/search/jobs' className='d-none d-lg-block' style={{ fontSize: '28px', color: '#2a9df4', padding: '10px 60px 10px 60px', border: '1px solid ', borderRadius: '10px' }}>EXPLORE ALL</Link>
                                <Link
                                    to={{
                                        pathname: '/search/jobs',
                                        search: '?live_job=true',
                                        state: { fromHome: true }
                                    }}
                                ><Button className='d-none d-lg-block' size='large' variant='outlined'>{t("button:explore-all.title")}</Button></Link>
                        }
                    </Grid>
                </Grid>
            </div>
            <Box className='container' sx={{ marginTop: "60px", '@media (max-width: 991px)': { marginTop: "50px" } }}>
                {/** Slider Component */}
                {slider}
            </Box>
            <div className='text-c'>
                {
                    isLoading ?
                        <Skeleton animation='wave' variant="rounded" width={300} height={50} sx={{ display: { xs: 'flex', md: 'none' }, marginTop: "30px" }} />
                        :
                        <Link
                            to={{
                                pathname: '/search/jobs',
                                search: '?live_job=true',
                                state: { fromHome: true }
                            }}
                        ><Button className='d-block d-lg-none' size='large' variant='outlined' sx={{ marginTop: '120px !important' }}>{t("button:explore-all.title")}</Button></Link>
                }
            </div>
        </Box >
    )
}

