import React, { useContext, useEffect, useState } from 'react'
import { CandidateContext } from '../../../../context/CandidateContext'
import UpdateAbout from './UpdateAbout'
import UpdateSkill from './UpdateSkill'
import UpdateEmployment from './UpdateEmployment'
import UpdateEducation from './UpdateEducation'
import UpdateProject from './UpdateProject'
import UpdateCertificate from './UpdateCertificate'
import UpdateSocial from './UpdateSocial'
import UpdatePersonalInfo from './UpdatePersonalInfo'
import './UpdateCandidateProfile.css'
import { useLocation } from 'react-router-dom'
import UpdateSalary from './UpdateSalary'
import ProfileStrengthSuccessAlert from '../../../Alerts/ProfileStrengthSuccessAlert'
import { UserContext } from '../../../../context/UserContext'
import ErrorAlert from '../../../Alerts/ErrorAlert'
import SuccessAlert from '../../../Alerts/SuccessAlert'
import { Box, Stack } from '@mui/material'
import CandidateUpdateProfileBasicInfo from './CandidateUpdateProfileBasicInfo'
import styles from './CandidateUpdateProfile.module.css'
import UpdateResume from './UpdateResume'
import UpdateHobby from './UpdateHobby'
import UpdateLanguage from './UpdateLanguage'
import UpdateReference from './UpdateReference'
import UpdateCareerInfo from './UpdateCareerInfo'
import ShortFooter from '../../../ShortFooter'

function CandidateUpdateDetails() {
	const { candidateData, settingCandidateData, candLoader, profileStrength } = useContext(CandidateContext)
	const { applyAttemptJobInfo, setApplyAlertNotificationOpen, uploadErrorAlert, setUploadErrorAlert, uploadErrorMessage, setSuccessTwister, successTwister, successMessage } = useContext(UserContext)

	const { pathname, hash, key } = useLocation();

	const [openModal, setOpenModal] = useState(true)

	const handleClose = () => {
		setOpenModal(false)
		if (profileStrength < 100) {
			{ setApplyAlertNotificationOpen(true) }
		}
	}

	useEffect(() => {
		// if not a hash link, scroll to top
		if (hash === '') {
			window.scrollTo(0, 0);
		}
		// else scroll to id
		else {
			setTimeout(() => {
				const id = hash.replace('#', '');
				const element = document.getElementById(id);
				if (element) {
					element.scrollIntoView();
				}
			}, 500);
		}
	}, [pathname, hash, key]);

	return (
		<>
			<Stack spacing={4} sx={{paddingTop: "20px"}} className={styles.updatePage}>
				{/* <CandidateUpdateProfileCover/> */}
				<CandidateUpdateProfileBasicInfo data={candidateData} candId={candidateData.id} candLoader={candLoader} renderState={settingCandidateData} />
				<UpdateAbout basicData={candidateData} candId={candidateData.id} loading={candLoader} renderState={settingCandidateData} />
				{/* <UpdateSalary candidateID={candidateData?.id} candidateTypes={candidateData?.candidate_types} renderState={settingCandidateData} /> */}
				<UpdateEducation education={candidateData.candidate_education} candId={candidateData.id} renderState={settingCandidateData} />
				<UpdateSkill skills={candidateData.skills} candId={candidateData.id} renderState={settingCandidateData} />
				<UpdateEmployment skills={candidateData.skills} experiences={candidateData.candidate_experience} candId={candidateData.id} renderState={settingCandidateData} />
				<UpdateProject experiences={candidateData.candidate_experience} projects={candidateData.projects} candId={candidateData.id} renderState={settingCandidateData} />
				<UpdateResume />
				<UpdateCertificate certificates={candidateData.certification} candId={candidateData.id} renderState={settingCandidateData} />
				
				<UpdateCareerInfo />
				<UpdatePersonalInfo candidateInfo={candidateData} candId={candidateData.id} renderState={settingCandidateData} />
				<UpdateLanguage candidateLanguages={candidateData.languages} renderState={settingCandidateData}/>
				<UpdateHobby hobbies={candidateData.candidate_hobbies} renderState={settingCandidateData} />
				<UpdateSocial social={candidateData.social_networks} candId={candidateData.id} renderState={settingCandidateData} />
				<UpdateReference candidateReference={candidateData.references} renderState={settingCandidateData}/>
				
				
				{
					profileStrength == process.env.REACT_APP_MINIMUM_STRENGTH_FOR_APPLY && applyAttemptJobInfo.job_id ?
						<>
							<ProfileStrengthSuccessAlert open={openModal} strength={profileStrength} message={process.env.REACT_APP_MINIMUM_STRENGTH_FOR_APPLY} onHandleClose={handleClose} />
							{window.scrollTo(0, 0)};

						</>
						:
						""
				}
				{
					profileStrength >= process.env.REACT_APP_MINIMUM_STRENGTH_FOR_APPLY && applyAttemptJobInfo.job_id ?
						profileStrength === 100 && candidateData?.profile_strength_check === 0 ?
							<>
								<ProfileStrengthSuccessAlert open={openModal} candidateData={candidateData} strength={profileStrength} message={"100%"} onHandleClose={handleClose} />
								{window.scrollTo(0, 0)};

							</>
							:
							""
						:
						profileStrength === 100 && candidateData?.profile_strength_check === 0 ?
							<>
								<ProfileStrengthSuccessAlert open={openModal} candidateData={candidateData} strength={profileStrength} message={"100%"} onHandleClose={handleClose} />
								{window.scrollTo(0, 0)};

							</>
							:
							""
				}
			</Stack>
			<Box sx={{display: {xs: "block", md: "none"}, marginTop: 5}}>
				<ShortFooter />
			</Box>
			<ErrorAlert open={uploadErrorAlert} message={uploadErrorMessage} onHide={() => setUploadErrorAlert(false)} />
			<SuccessAlert open={successTwister} message={successMessage} onHide={() => setSuccessTwister(false)} />
		</>
	)
}

export default React.memo(CandidateUpdateDetails)