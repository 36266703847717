import React from "react";
import PolicyPage from "../components/PolicyPage";

function RefundPolicyPage() {

    return (
        <PolicyPage />
    )
}

export default RefundPolicyPage