import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, Button, Grid, Skeleton, Typography } from '@mui/material';
import { expireSoonJobs, liveJobs } from '../../../fetchAPI';
import { Link } from 'react-router-dom';
import { GetExpireSoonJobsSliderProps } from '../Slider/SliderFunctions';
import SliderComponent from '../Slider/Slider';
import { JobSearchContext } from '../../context/JobSearchContext';
import { useTranslation } from 'react-i18next';

const JobCardSkeleton = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 2,
          border: '1px solid #e0e0e0',
          padding: 2,
            width: {
            xs: '100%',
            sm: '100%',
            md: 330,
            lg: 370,
            xl: 460,
          }
        }}
      >
        <Skeleton variant="text" width={250} height={30} animation="wave" />
        <Box sx={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: "space-between", mt: 2 }}>
            <Box sx={{display: "flex", flexDirection: "column"}}>
                <Skeleton variant="text" width={200} height={20} animation="wave" />
                <Skeleton variant="text" width={150} height={20} animation="wave" />
            </Box>
            <Skeleton variant="rounded" width={60} height={60} animation="wave" />
        </Box>
        <Skeleton variant="text" width="100%" height={5} animation="wave" sx={{ mt:5, mb:1, borderRadius: 1 }} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Box sx={{display: 'flex', columnGap: 2}}>
            <Skeleton variant="circular" width={40} height={40} animation="wave" />
            <Box sx={{display: "flex", flexDirection: "column"}}>
                <Skeleton variant="text" width={50} height={20} animation="wave" />
                <Skeleton variant="text" width={70} height={20} animation="wave" />
            </Box>
          </Box>
          <Skeleton variant="rounded" width={120} height={40} animation="wave" sx={{ borderRadius: 2 }} />
        </Box>
      </Box>
    );
  };

function ExpireSoonJobs() {

    const [jobs, setJobs] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const { setExpireSoon } = useContext(JobSearchContext)

    // translations

    const {t} = useTranslation(["homepage","button"])

    useEffect(() => {
        setIsLoading(true);
        gettingExpireSoonJobs()

    }, []);

    const gettingExpireSoonJobs = () => {

        expireSoonJobs().then((response) => {
            if (response?.success) {
                setJobs(response?.response?.data)
                setIsLoading(false);

            }
            else {
                // console.log('errors: ', response);
            }
        })
    }

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            // partialVisibilityGutter: -38
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    /**
  * Expire Soon Jobs Slider Component
  * 2024-01-08 Sajid
  */
    const [slides, setSlides] = useState();
    const [params, setParams] = useState({});
    useEffect(() => {
        if (isLoading) {
            return;
        }
        const { slides, params } = GetExpireSoonJobsSliderProps({
            jobs: jobs?.expire_soon_job,
            t : t
        });
        setSlides(slides);
        setParams(params);
    }, [isLoading, jobs, t]);
    const slider = useMemo(() => {
        if (isLoading) {
            return <Box sx={{display: 'flex', justifyContent: "space-between", alignItems: "center"}}>
            <JobCardSkeleton />
            <Box sx={{display: {xs: "none", md: "block"}}}>
            <JobCardSkeleton />
            </Box>
            <Box sx={{display: {xs: "none", lg: "block"}}}>
            <JobCardSkeleton />
            </Box>
        </Box>;
        }
        return <SliderComponent slides={slides} params={params} />;
    }, [slides, params, isLoading]);


    return (
        <Box sx={{marginTop: "130px", marginBottom: "50px", '@media (max-width: 991px)': {marginTop: "20px"}, '@media (min-width: 992px)': {marginBottom: "170px"}}}>
            <div className='container'>
                <Grid container className='p-3' spacing={2} sx={{ justifyContent: {xs:'center',md: 'space-between'}, justifyItems: "center" }}>
                    <Grid item>
                        {
                            isLoading ?
                                <>
                                    <Grid container spacing={1} sx={{ display: { xs: 'none', md: 'flex' } }}>
                                        <Grid item>
                                            <Skeleton animation='wave' variant="rounded" width={200} height={40} />
                                        </Grid>
                                        <Grid item>
                                            <Skeleton animation='wave' variant="rounded" width={40} height={40} />
                                        </Grid>
                                    </Grid>
                                </>
                                :
                                <Typography variant='h3' className='d-none d-lg-block' sx={{ color: '#424447', fontWeight: 700, fontSize: {xs: '20px', sm: '20px', lg: '28px'}, marginTop: "15px" }}>{t('homepage:expire-soon-jobs.title')}<span className='' style={{ color: '#DB1616' }}> &nbsp;({jobs?.count})</span></Typography>
                        }
                        {
                            isLoading ?
                                <>
                                    <Grid container spacing={1} sx={{ display: { xs: 'flex', md: 'none' }, m: 3 }}>
                                        <Grid item>
                                            <Skeleton animation='wave' variant="rounded" width={200} height={40} />
                                        </Grid>
                                        <Grid item>
                                            <Skeleton animation='wave' variant="rounded" width={40} height={40} />
                                        </Grid>
                                    </Grid>
                                </>
                                :
                                <>
                                    <Typography variant='h3' className='d-lg-none text-center responsive_header' sx={{ color: '#424447', fontWeight: 700, fontSize: {xs: '20px', sm: '20px', lg: '28px'} }}>{t('homepage:expire-soon-jobs.title')} <span className='' style={{ color: '#DB1616' }}> &nbsp;({Number(jobs?.count).toLocaleString()})</span></Typography> {/** Mobile View */}
                                </>
                        }
                    </Grid>
                    <Grid item>
                        {
                            isLoading ?
                                <Skeleton animation='wave' variant="rounded" width={150} height={50} sx={{ display: { xs: 'none', md: 'flex' } }} />
                                :
                                // <Link to='/search/jobs' className='d-none d-lg-block' style={{ fontSize: '28px', color: '#2a9df4', padding: '10px 60px 10px 60px', border: '1px solid ', borderRadius: '10px' }}>EXPLORE ALL</Link>
                                <Link
                                to={{
                                    pathname: '/search/jobs',
                                    search: '?expire_soon=true',
                                    state: { fromHome: true }
                                }} 
                                ><Button className='d-none d-lg-block' size='large' variant='outlined'>{t("button:explore-all.title")}</Button></Link>
                        }
                    </Grid>
                </Grid>
            </div>
            <div className='container'>
                {/** Slider Component */}
                <Box sx={{marginTop: "60px", '@media (max-width: 991px)': {marginTop: "60px"}}}>
                    {slider}
                </Box>
            </div>
            <div className='text-c'>
                {
                isLoading ?
                    <Skeleton animation='wave' variant="rounded" width={300} height={50} sx={{ display: { xs: 'flex', md: 'none' }, marginTop: "30px" }} />
                    :
                    <Link 
                    to={{
                        pathname: '/search/jobs',
                        search: '?expire_soon=true',
                        state: { fromHome: true }
                    }} 
                    ><Button className='d-block d-lg-none' size='large' variant='outlined' sx={{ marginTop: '120px !important'}}>{t("button:explore-all.title")}</Button></Link>
                }
            </div>
        </Box>
    )
}

export default ExpireSoonJobs;