import React, { useContext } from "react";
import { Box, createTheme, ThemeProvider, Typography } from "@mui/material";
import CommonMasking from "../../../../maskingcomponents/CommonMasking";
import { CandidateContext } from "../../../../../context/CandidateContext";

// Custom breakpoint at 768px
const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        tab: 768,
        md: 900,
        lap: 1024,  
        lg: 1200,
        xl: 1536,
        xxl: 1920,
      },
    },
});

export default function SingleCareerInfoBlock(props) {
    const {profileStrength} = useContext(CandidateContext)

    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    display: {xs: "none", sm: "none", md: "flex"},
                    fontFamily: 'Poppins',
                    fontSize: '16px',
                    fontWeight: 400,
                    color: '#424447',
                    mb: "10px",
                }}
            >
                <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <Typography sx={{fontFamily: 'Poppins', minWidth: {md: profileStrength < 100 ? "250px" : "300px", lg: "300px"}}}>
                        {props.title}
                    </Typography>
                    <Box component={"span"} sx={{fontWeight: 700, fontFamily: 'Poppins'}}>:</Box>
                </Box>
                <Box sx={{marginLeft: {md: profileStrength < 100 ? "10px" : "50px", lg: "50px"}}}>
                    {props.masking ? <CommonMasking>Personal Information</CommonMasking> : <>{props.value}</>}
                </Box>
            </Box>
            <Box
                sx={{
                    display: {xs: "block", sm: "block", md: "none"},
                    fontFamily: 'Poppins',
                    fontSize: '16px',
                    fontWeight: 500,
                    color: '#424447',
                    marginBottom: "16px",
                }}
            >
                <Box sx={{fontFamily: 'Poppins', fontWeight: 500}}>{props.title}</Box>
                <Box sx={{fontFamily: 'Poppins', fontWeight: 400}}>
                    {props.masking ? <CommonMasking>Personal Information</CommonMasking> : <>{props.value || '--'}</>}
                </Box>
            </Box>
        </ThemeProvider>
    )
}