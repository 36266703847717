import React from "react";

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import { useState } from "react";

const FAQsData = [
  {
    id: 1,
    ques: "Q: I can't create an account using the atB Jobs app. What should I do?",
    answer: "Please update the app if you're using an old version. You can also try clearing your cache and cookies or visit our website."
  },
  {
    id: 2,
    ques: "Q: My password is showing as incorrect when I try to sign in.?",
    answer: "Clear your cache and cookies, then try signing in again. If it still doesn’t work, you may need to reset your password."
  },
  {
    id: 3,
    ques: "Q: I can't update my profile. What can I do?",
    answer: "Try clearing your browser's cache and cookies. If that doesn’t help, sign out and then sign back in."
  },
  {
    id: 4,
    ques: "Q: How do I upload my CV?",
    answer: "Make sure your CV is under 10 MB. If it’s too large or not uploading, try changing the file format (like from Word to PDF) and upload it again."
  },
  {
    id: 5,
    ques: "Q: Why can't I open the downloaded CV?",
    answer: "Ensure you're using a PDF viewer since the CV is in PDF format. If you still have trouble, please share a video or picture of the issue by emailing us at contact@atb-jobs.com.Or Call Us +8801400492818"
  },
  {
    id: 6,
    ques: "Q: I can't update my profile picture; I'm getting an error.",
    answer: "Ensure your photo is in jpg, jpeg, or png format and does not exceed 1200 x 1200 pixels."
  },
  {
    id: 7,
    ques: "Q: How do I reset my password?",
    answer: `Click on “Sign In,” then “Forgot Password?” Enter your registered email and follow the instructions in the email you receive.`
  },
  {
    id: 8,
    ques: "Q: How can I make my profile 100% complete?",
    answer: "Fill out all required information in your profile section to achieve 100% completeness."
  },
  {
    id: 9,
    ques: "Q: I’m facing an error when applying for a job. What should I do?",
    answer: "Clear your cache and cookies, or try signing out and signing back in."
  },
  {
    id: 10,
    ques: "Q: Can you suggest companies I should apply to?",
    answer: `Unfortunately, we can’t apply on your behalf. However, you can create your CV using the "CV Manager" feature to help with your applications.`
  },
  {
    id: 11,
    ques: "Q: How can I know which job is better for me?",
    answer: "Update your profile to at least 70%. After that, we’ll show you relevant job listings based on your CV."
  },
  {
    id: 12,
    ques: "Q: How can I get a job using atB?",
    answer: `You can search our job listings in the "Find Jobs" section and apply directly on the site. Make sure to fill out your profile and upload your CV to increase your chances of being noticed by employers.`
  },
  {
    id: 13,
    ques: "Q: Can you apply for a job on my behalf?",
    answer: `Sorry, we cannot apply for jobs on your behalf. You can create your CV using the "CV Manager" and apply for jobs yourself.`
  },
  {
    id: 14,
    ques: "Q: How can I receive job alerts on atB Jobs?",
    answer: "Follow your favorite companies to receive notifications about new job postings."
  },
];

function FAQsPage() {
  const [expanded, setExpanded] = useState(1);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  const breadcrumbs = [
    <Link underline="hover" href="/" sx={{color: "#FFFFFF", fontFamily: "Poppins", fontWeight: 400, fontSize: "14px", lineHeight: "24px"}}>
      Home
    </Link>,
    <Typography sx={{color: "#3BB2F4", fontFamily: "Poppins", fontWeight: 400, fontSize: "14px", lineHeight: "24px"}}>
      For Candidate
    </Typography>,
  ];

  return (
    <>
        <Box sx={{backgroundColor: "#0079C1", height: "300px"}}>
        </Box>

        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 12
            }}
        >
            <Paper elevation={3} sx={{maxWidth: {xs: "95%", sm: "90%", md: "80%", lg: "80%", xl: "1200px"}, padding: {xs: 1, sm: 4}, marginTop: "-200px", border: "1px solid #C4C4C4", borderRadius: "10px"}}>
                <Breadcrumbs
                    sx={{marginTop: -10, marginLeft: {xs: 0, sm: -3}}}
                    separator={<NavigateNextIcon fontSize="small" sx={{color: "#FFFFFF"}} />}
                    aria-label="breadcrumb"
                >
                    {breadcrumbs}
                </Breadcrumbs>
                <Box sx={{marginTop: 10}}>
                    {/* Title Section */}
                    <Box sx={{display: "flex", columnGap: 3, marginBottom: 5}}>
                        <Typography sx={{backgroundColor: "#E5F5FF", color: "#0079C1", padding: "0px 16px", borderRadius: 1, fontFamily: "Poppins", fontWeight: 500, fontSize: "14px", display: "flex", alignItems: "center"}}>FAQs</Typography>
                        <Typography sx={{fontFamily: "Epilogue", fontWeight: 700, fontSize: {xs: "18px", sm: "24px"}, color: "#0079C1"}}>
                            For Candidates
                        </Typography>
                    </Box>

                    {/* FAQ Section */}
                    <Box>
                        {FAQsData.map((faq) => (
                        <Accordion
                            key={faq.id}
                            expanded={expanded === faq.id}
                            onChange={handleAccordionChange(faq.id)}
                            sx={{
                                boxShadow: "unset",
                                border: "1px solid #E6E9ED",
                                my: 3,
                                borderRadius: "6px",
                            }}
                        >
                            <AccordionSummary expandIcon={expanded === faq.id ? <KeyboardArrowDownIcon fontSize="large" /> : <ArrowForwardIosIcon />} sx={{backgroundColor: "#F8F9FA"}}>
                            <Typography sx={{color: "#424447", fontFamily: "Epilogue", fontWeight: 700, fontSize: "16px", lineHeight: "24px"}} >
                                {faq.ques}
                            </Typography>
                            </AccordionSummary>
                            {faq.answer && (
                            <AccordionDetails sx={{display: "flex", paddingLeft: "0px"}}>
                                <Typography sx={{marginRight: 1, color: "#424447", fontFamily: "Poppins", fontWeight: 700, fontSize: "14px", lineHeight: "24px"}}>A:</Typography>
                                <Typography sx={{color: "#424447", fontFamily: "Poppins", fontWeight: 400, fontSize: "14px", lineHeight: "24px"}}>{faq.answer}</Typography>
                            </AccordionDetails>
                            )}
                        </Accordion>
                        ))}
                    </Box>
                </Box>
            </Paper>
        </Box>

    </>
  );
}

export default FAQsPage;
