import React, { useState, useEffect, useContext, useRef } from 'react'
import { Box, Grid, Stack, Button, Typography, TextField, Accordion, AccordionSummary, AccordionDetails, IconButton, Divider, Chip, Pagination, Checkbox, FormGroup, FormControlLabel } from '@mui/material'
import Select from 'react-select'
import { DropdownContext } from '../../context/DropdownContext';
import SearchIcon from '@mui/icons-material/Search';
import TuneSharpIcon from '@mui/icons-material/TuneSharp';
import { useQuery } from 'react-query';
import { appliedCandidateSearch } from '../../../fetchAPI';
import { useParams } from 'react-router-dom'
import AppliedCandidateCardV3 from '../../components/CandidateCard/AppliedCandidateCardV3';
import NoResultsFound from '../../components/NoResultsFound';
import DrawerComponent from '../../components/DrawerComponent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment/moment';
import GlobalLoader from '../../components/GlobalLoader';

function ClearFilterIcon() {
    return (
        <Box
            sx={{
                width: ' 32px',
                height: ' 32px',
                marginLeft: ' 8px',
                backgroundColor: ' #B6DDFF',
                display: ' flex',
                alignItems: ' center',
                justifyContent: ' center',
                borderRadius: ' 50%',
            }}
        >
            <TuneSharpIcon className="text-primary" />
        </Box>
    )
}

function GridIcon({ grid }) {
    const strokeColor = grid ? "#0275D8" : "#808080";

    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="39" height="39" rx="5.5" stroke={strokeColor} />
            <g clipPath="url(#clip0_1185_485)">
                <path d="M17 12H13C12.4477 12 12 12.4477 12 13V17C12 17.5523 12.4477 18 13 18H17C17.5523 18 18 17.5523 18 17V13C18 12.4477 17.5523 12 17 12Z" stroke={strokeColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M27 12H23C22.4477 12 22 12.4477 22 13V17C22 17.5523 22.4477 18 23 18H27C27.5523 18 28 17.5523 28 17V13C28 12.4477 27.5523 12 27 12Z" stroke={strokeColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M17 22H13C12.4477 22 12 22.4477 12 23V27C12 27.5523 12.4477 28 13 28H17C17.5523 28 18 27.5523 18 27V23C18 22.4477 17.5523 22 17 22Z" stroke={strokeColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M27 22H23C22.4477 22 22 22.4477 22 23V27C22 27.5523 22.4477 28 23 28H27C27.5523 28 28 27.5523 28 27V23C28 22.4477 27.5523 22 27 22Z" stroke={strokeColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_1185_485">
                    <rect width="24" height="24" fill="white" transform="translate(8 8)" />
                </clipPath>
            </defs>
        </svg>
    );
}

function ListIcon({ grid }) {
    const strokeColor = grid ? "#808080" : "#0275D8";

    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.06" y="0.975586" width="40" height="39.0244" rx="4" fill="#F6F8FF" />
            <g clipPath="url(#clip0_1185_487)">
                <path d="M26 12.6826H14C12.8954 12.6826 12 13.5562 12 14.6338V16.5851C12 17.6627 12.8954 18.5363 14 18.5363H26C27.1046 18.5363 28 17.6627 28 16.5851V14.6338C28 13.5562 27.1046 12.6826 26 12.6826Z" fill="#F6F8FF" stroke={strokeColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M26 22.439H14C12.8954 22.439 12 23.3126 12 24.3902V26.3414C12 27.419 12.8954 28.2926 14 28.2926H26C27.1046 28.2926 28 27.419 28 26.3414V24.3902C28 23.3126 27.1046 22.439 26 22.439Z" fill="#F6F8FF" stroke={strokeColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <rect x="0.5" y="0.5" width="39" height="38.0244" rx="5.5" stroke={strokeColor} />
            <defs>
                <clipPath id="clip0_1185_487">
                    <rect width="24" height="23.4146" fill="white" transform="translate(8 8.78027)" />
                </clipPath>
            </defs>
        </svg>
    );
}

function PostDateIcon() {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M32 16C32 24.8365 24.8365 32 16 32C7.16344 32 0 24.8365 0 16C0 7.16344 7.16344 0 16 0C24.8365 0 32 7.16344 32 16Z" fill="#E5F5FF" />
            <path d="M16 7.66531C11.4041 7.66531 7.66531 11.4041 7.66531 16C7.66531 20.5959 11.4041 24.3347 16 24.3347C20.5959 24.3347 24.3347 20.5959 24.3347 16C24.3347 11.4041 20.5959 7.66531 16 7.66531ZM16 26C10.4857 26 6 21.5143 6 16C6 10.4857 10.4857 6 16 6C21.5143 6 26 10.4857 26 16C26 21.5143 21.5143 26 16 26Z" fill="#0079C1" />
            <path d="M17.9353 18.8327H15.0006C14.5394 18.8327 14.168 18.4613 14.168 18.0001V12.6041C14.168 12.1429 14.5394 11.7715 15.0006 11.7715C15.4618 11.7715 15.8333 12.1429 15.8333 12.6041V17.1674H17.9353C18.3965 17.1674 18.768 17.5388 18.768 18.0001C18.768 18.4613 18.3965 18.8327 17.9353 18.8327Z" fill="#0079C1" />
        </svg>

    )
}

function AdvanceSearchIcon() {
    return (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.8106 18.6222C10.6162 18.7963 10.3802 18.9174 10.1255 18.9739C9.87081 19.0303 9.60589 19.0202 9.35622 18.9445C9.09382 18.8701 8.86175 18.7143 8.69327 18.4998C8.52479 18.2853 8.42854 18.023 8.41835 17.7504C8.34812 16.9944 8.36872 16.2631 8.36046 15.5195C8.35219 14.3544 8.30675 13.1852 8.36873 12.0242C8.40591 11.3095 8.10438 11.2226 7.50944 11.2309C5.71636 11.2557 3.92189 11.2598 2.12606 11.2433C1.79593 11.2552 1.47135 11.1558 1.20459 10.9609C0.937819 10.7661 0.744388 10.4872 0.655265 10.1691C0.574878 9.89851 0.58056 9.60963 0.671605 9.34241C0.76265 9.0752 0.934495 8.84288 1.16338 8.67765C1.53287 8.42287 1.98124 8.30862 2.4276 8.35543C4.105 8.38022 5.82788 8.35123 7.53007 8.39255C8.13327 8.40907 8.39358 8.2397 8.37705 7.58692C8.33573 5.93431 8.37703 4.28169 8.35637 2.60842C8.35345 2.34986 8.37141 2.0915 8.41008 1.83583C8.45019 1.49717 8.61339 1.18507 8.86857 0.95884C9.12376 0.732605 9.45311 0.608068 9.79413 0.608831C10.1371 0.593581 10.4732 0.70792 10.7358 0.929135C10.9983 1.15035 11.1681 1.46225 11.2113 1.80285C11.3097 2.80099 11.3276 3.80544 11.2649 4.80647C11.2622 5.76223 11.2525 6.71658 11.236 7.66958C11.2277 8.1571 11.3806 8.35131 11.9342 8.36784C13.7067 8.33065 15.4792 8.35951 17.2475 8.35124C18.3464 8.34298 18.9868 8.86774 19.0074 9.8056C19.0281 10.7435 18.3464 11.2433 17.2433 11.2392C15.4502 11.2309 13.653 11.2516 11.8599 11.235C11.4096 11.2309 11.2401 11.3591 11.2608 11.8342C11.2773 13.6768 11.2525 15.5112 11.2732 17.3497C11.2753 17.8154 11.1113 18.2666 10.8106 18.6222Z" fill="#0079C1" />
        </svg>
    )
}

function SkillSearchIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10" r="10" fill="#CEE8FF" />
            <path d="M6.58228 9.26329C5.76762 9.26329 4.95515 9.26329 4.14049 9.26329C3.64068 9.26329 3.31921 9.58035 3.33242 10.0361C3.33568 10.1964 3.39286 10.351 3.49472 10.4748C3.59659 10.5987 3.73721 10.6846 3.89389 10.7187C4.01008 10.7401 4.12803 10.7504 4.24617 10.7495H8.94922C9.2949 10.7495 9.24866 10.7033 9.25087 11.06C9.25087 12.6497 9.25087 14.2394 9.25087 15.8291C9.25087 16.3487 9.55912 16.6768 10.0193 16.6657C10.1795 16.6638 10.3343 16.608 10.4589 16.5074C10.5836 16.4067 10.6707 16.2671 10.7063 16.1109C10.7293 15.9949 10.7404 15.8769 10.7393 15.7586C10.7393 14.1689 10.7393 12.577 10.7393 10.9873C10.7393 10.7913 10.7877 10.7407 10.9837 10.7429C12.5734 10.7429 14.1631 10.7429 15.755 10.7429C15.8657 10.7451 15.9763 10.7363 16.0853 10.7165C16.2491 10.6811 16.3958 10.5907 16.5011 10.4603C16.6063 10.3299 16.6637 10.1674 16.6637 9.9998C16.6637 9.83222 16.6063 9.66969 16.5011 9.53929C16.3958 9.40889 16.2491 9.31847 16.0853 9.28311C15.9691 9.26138 15.8511 9.25179 15.733 9.25448H11.0519C10.6908 9.25448 10.7415 9.30513 10.7415 8.93302C10.7415 7.37415 10.7415 5.8138 10.7415 4.25199C10.7437 4.1413 10.7349 4.03065 10.7151 3.92172C10.6817 3.7556 10.5919 3.60614 10.4609 3.49875C10.3298 3.39137 10.1656 3.33268 9.99618 3.33268C9.82674 3.33268 9.66254 3.39137 9.53148 3.49875C9.40042 3.60614 9.3106 3.7556 9.27729 3.92172C9.25929 4.03086 9.25119 4.1414 9.25307 4.25199C9.25307 5.84169 9.25307 7.4336 9.25307 9.0233C9.25307 9.20825 9.20463 9.25889 9.01968 9.25669C8.20942 9.26329 7.39475 9.26329 6.58228 9.26329Z" fill="#0079C1" />
        </svg>
    )
}

function AppliedCandidateSearchV3() {
    const [advancedSearchComponent, setAdvancedSearchComponent] = React.useState(false);
    const [gridView, setGridView] = useState(true)
    const {
        apiCallingConditionSet,
        setApiCallingConditionSet,
        skills,
        qualifications,
        genders,
        types,
        levels,
        experienceRange,
        salaryRange,
        currency,
        universities,
        ageRange } = useContext(DropdownContext)

    useEffect(() => {
        setApiCallingConditionSet({
            ...apiCallingConditionSet,
            qualification: true,
            type: true,
            skill: true,
            level: true,
            department: true,
            industry: true,
            position: true,
            salaryType: true,
            currency: true
        })
    }, [])

    const [filterParams, setFilterParams] = useState({
        status: ''
    })
    const { job_id } = useParams()

    const handleChangeAdvancedSearchComponent = (event) => {
        setAdvancedSearchComponent((prevState) => !prevState);
    };

    const handleExperienceRange = (e) => {
        if (e !== null) {
            const selectedValue = e?.value
            const [valueFrom, valueTo] = selectedValue.split(',')

            setFilterParams({ ...filterParams, experience_from: valueFrom, experience_to: valueTo })
        } else {
            setFilterParams({ ...filterParams, experience_from: '', experience_to: '' })
        }

    }

    const handleSalaryRange = (e) => {
        if (e !== null) {
            const selectedValue = e.value
            const [valueFrom, valueTo] = selectedValue.split(',')

            setFilterParams({ ...filterParams, salary_from: Number(valueFrom), salary_to: Number(valueTo) })
        } else {
            setFilterParams({ ...filterParams, salary_from: '', salary_to: '' })
        }

    }

    const handleAgeRange = (e) => {
        if (e !== null) {
            const selectedValue = e.value
            const [valueFrom, valueTo] = selectedValue.split(',')
            setFilterParams({ ...filterParams, age_from: valueFrom, age_to: valueTo })
        } else {
            setFilterParams({ ...filterParams, age_from: '', age_to: '' })
        }

    }

    const handleSelectField = (param_name) => (event) => {
        setFilterParams({ ...filterParams, [param_name]: event?.value ? event.value : '' })
    }

    const handletextField = (param_name) => (event) => {
        event.persist();
        const timeOut = setTimeout(() => {
            setFilterParams({ ...filterParams, [param_name]: event?.target.value })
        }, 1500)
        return (() => clearTimeout(timeOut))

    }
    const [currentPage, setCurrentPage] = useState(1)
    const [customQuestions, setCustionQuestions] = useState([])

    const { data: searchResult, isLoading: loading, status, refetch } = useQuery(['AppliedCandidateSearch', filterParams, currentPage, job_id], appliedCandidateSearch, { staleTime: Infinity })
    const [jobName, setJobName] = useState('')
    const [paginateInfo, setPaginationInfo] = useState('')
    const [statusListInfo, setStatusListInfo] = useState([])

    useEffect(() => {
        if (status === 'success') {
            setPaginationInfo(searchResult.response.pagination)
            setJobName(searchResult.response.message)
            setStatusListInfo(searchResult.response.data?.applicant_count_by_status)
            setCustionQuestions(searchResult.response?.data.job_info?.questions)
        }
    }, [searchResult])

    const [chipList, setChipList] = useState([])
    const [tempSkill, setTempSkill] = useState({
        skill_name: '',
        skill_id: '',
        skill_condition: '',
        year_of_experience: ''
    });
    const [tempYear, setTempYear] = useState([])
    const [emptyYearError, setEmptyYearError] = useState('')

    const addItemToTempSkill = (field) => (event) => {
        if (field === 'skill') {
            setTempSkill((prevState) => ({
                ...prevState,
                skill_name: event?.label,
                skill_id: event?.value,
            }));

            if (event) {
                yearRef.current.focus();
            } else {
                setTempYear('');
                setEmptyYearError('');
            }
        } else if (field === 'condition') {
            const skill_value = event?.label.slice(0, 2);
            setTempSkill((prevState) => ({
                ...prevState,
                skill_condition: skill_value,
            }));
        }
    };

    useEffect(() => {
        setEmptyYearError('');
    }, [tempYear])

    const handleAddOrUpdateSkill = () => {
        if (tempSkill.skill_id !== undefined && tempYear.length === 0) {
            setEmptyYearError('Please fill year field.');
            return;
        } else if (tempSkill.skill_id !== undefined && tempYear.length !== 0) {
            setChipList((prevSkills) => {
                const existingSkillIndex = prevSkills.findIndex((s) => s.skill_id === tempSkill.skill_id);

                if (existingSkillIndex !== -1) {
                    // Update the existing skill's experience and condition
                    const updatedSkills = [...prevSkills];
                    updatedSkills[existingSkillIndex].year_of_experience = tempYear;
                    updatedSkills[existingSkillIndex].skill_condition = tempSkill.skill_condition;
                    return updatedSkills;
                } else {
                    // Add a new skill object with condition
                    return [
                        ...prevSkills,
                        {
                            skill_name: tempSkill.skill_name,
                            skill_id: tempSkill.skill_id,
                            year_of_experience: tempYear,
                            skill_condition: tempSkill.skill_condition // Add skill condition here
                        }
                    ];
                }
            });
        }

        // Clear the input fields after adding/updating
        setTempSkill('');
        setTempYear('');
        setEmptyYearError(''); // Clear any existing error messages
    };

    const handleRemoveSkill = (skillToRemove) => {
        setChipList((prevSkills) => prevSkills.filter((s) => s.skill_id !== skillToRemove));
    };

    const selectedStatusClass = {
        backgroundColor: '#E5F5FF',
        borderRadius: '10px',
        padding: '8px',
        cursor: 'pointer',
        margin: '4px',
        whiteSpace: 'nowrap',
        marginLeft: 'unset'
    }

    const statusClass = {
        padding: '8px',
        cursor: 'pointer',
        backgroundColor: '#80808014',
        borderRadius: '10px',
        margin: '4px',
        whiteSpace: 'nowrap',

    }

    const skillConditions = [
        {
            id: 1,
            label: '< Less Than'
        },
        {
            id: 2,
            label: '> Greater Than'
        },
        {
            id: 3,
            label: '>= Greater Than, Equal to'
        },
        {
            id: 4,
            label: '<= Less Than Equal to'
        },

    ]

    useEffect(() => {
        setFilterParams({ ...filterParams, skill: chipList })
    }, [chipList])

    // const [pageNumber, setPageNumber] = useState(1)
    const changePage = (event, value) => {
        // setPageNumber(value);
        setCurrentPage(value)
    }

    useEffect(() => {
        setCurrentPage(1)
    }, [filterParams])

    const dateFromDateTime = (dateTime) => {
        if (dateTime) {
            var d = dateTime;
            d = d.split(' ')[0];
            return d;
        } else {
            return null
        }
    }

    const skillRef = useRef()
    const yearRef = useRef()
    const experienceRef = useRef()
    const salaryRef = useRef()
    const currencyRef = useRef()
    const qualificationRef = useRef()
    const genderRef = useRef()
    const instituteRef = useRef()
    const professionRef = useRef()
    const typeRef = useRef()
    const levelRef = useRef()
    const nameRef = useRef()
    const ageRef = useRef()

    const resettingFormValues = async () => {
        setTempSkill('')
        setTempYear('')

        experienceRef.current.clearValue()
        salaryRef.current.clearValue()
        currencyRef.current.clearValue()
        qualificationRef.current.clearValue()
        genderRef.current.clearValue()
        instituteRef.current.clearValue()
        typeRef.current.clearValue()
        levelRef.current.clearValue()
        ageRef.current.clearValue()

        yearRef.current.value = ''
        professionRef.current.value = ''
        nameRef.current.value = ''
    }

    function clearFilterFunction() {
        resettingFormValues()
        setEmptyYearError('');
        setFilterParams({
            status: ''
        })
    }

    const [custQuesParam, setCustQuesParam] = useState([])

    const handleCustomQuestion = (event, questionId) => {

        event.persist();
        let question = custQuesParam || [];

        let itemIndex = question.findIndex(item => item.question_id === questionId);

        if (event.target.checked) {
            if (itemIndex !== -1) {
                question[itemIndex].option_id_a.push(Number(event.target.value));
            } else {
                let newQ = { question_id: questionId, option_id_a: [] };
                newQ.option_id_a.push(Number(event.target.value));
                question.push(newQ);

            }
        } else {
            if (itemIndex !== -1) {
                // Remove the unchecked option
                question[itemIndex].option_id_a = question[itemIndex].option_id_a.filter(id => id !== Number(event.target.value));

                // If no options left, remove the entire question
                if (question[itemIndex].option_id_a.length === 0) {
                    question = question.filter(ques => ques.question_id !== questionId);
                }
            }
        }

        for (var q in question) {
            if (question[q].option_id_a.length > 0) {
                question[q].option_id = question[q].option_id_a.toString();
            } else {
                question[q].option_id = "";
            }
        }

        setCustQuesParam(question);
        setFilterParams((prev) => {
            return {
                ...prev, custQuesParam: question.map((item) => {
                    return {
                        question_id: item.question_id,
                        option_id: item.option_id
                    }
                })
            }
        })
    }


    useEffect(() => {
        refetch()
    }, [filterParams])

    return (
        <Box
            sx={{
                backgroundColor: '#f9f9f9'
            }}
        >
            <Box>
                <Grid container spacing={0}>
                    <Grid item md={3} sx={{
                        // maxHeight: 'calc(100vh - 100px)',
                        position: 'sticky',
                        top: 100,
                        zIndex: 1000,
                    }}>
                        <Box sx={{
                            display: { xs: 'none', md: 'block' },
                            // overflowY: 'auto',
                            boxShadow: '-1px 3px 5px 2px rgb(0 0 0 / 18%)'
                        }}
                        >
                            <Box
                                p={4}
                                sx={{
                                    backgroundColor: '#FFFF',
                                    width: '100%',
                                    '@media (max-width: 600px)': {
                                        width: '100%',
                                    },
                                }}
                            >
                                <form id="appliedCandidatesearchFormV3">
                                    <Stack spacing={2}>
                                        <Stack
                                            spacing={2}
                                            direction={'row'}
                                            alignItems={'center'}
                                            justifyContent={'space-between'}
                                            sx
                                        >
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    fontWeight: 700,
                                                    fontSize: '14px',
                                                    '@media (max-width: 600px)': {
                                                        fontSize: '12px',
                                                    },
                                                }}
                                            >
                                                {advancedSearchComponent ? 'Advanced Search' : 'Quick Search'}
                                            </Typography>
                                            <Button
                                                size="small"
                                                variant="contained"
                                                endIcon={<ClearFilterIcon />}
                                                disableElevation
                                                sx={{
                                                    backgroundColor: '#E3F2FF',
                                                    color: '#0275D8',
                                                    borderRadius: '30px',
                                                    '&:hover': {
                                                        backgroundColor: '#e6f3ff',
                                                    },
                                                    '@media (max-width: 600px)': {
                                                        fontSize: '12px',
                                                    },
                                                }}
                                                onClick={() => clearFilterFunction()}
                                            >
                                                Clear Filter
                                            </Button>
                                        </Stack>
                                        <Grid container spacing={2} alignItems={'center'} sx={{ marginLeft: '0px !important' }}>
                                            <Grid item md={4} sm={12} sx={{ paddingLeft: '0px !important' }}>
                                                <Select
                                                    isClearable
                                                    placeholder="Skills"
                                                    styles={{
                                                        control: base => ({ ...base, height: '40px', flexWrap: 'nowrap' }),
                                                        placeholder: base => ({ ...base, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }),
                                                        menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }),
                                                        clearIndicator: base => ({ ...base, padding: '0px', fontSize: '12px' }),
                                                        option: base => ({ ...base, fontSize: 14 }),
                                                    }}
                                                    className="react-select-container"
                                                    classNamePrefix="react-select"
                                                    value={
                                                        tempSkill.skill_id
                                                            ? { label: tempSkill.skill_name, value: tempSkill.skill_id }
                                                            : ''
                                                    }
                                                    options={skills?.map(item => ({ label: item.skill_name, value: item.id }))}
                                                    onChange={addItemToTempSkill('skill')}
                                                    ref={skillRef}
                                                />
                                            </Grid>
                                            <Grid item md={4} sm={12}>
                                                <Select
                                                    isClearable
                                                    placeholder='Select'
                                                    styles={{
                                                        control: base => ({ ...base, height: '40px', flexWrap: 'nowrap' }),
                                                        placeholder: base => ({ ...base, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }),
                                                        menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }),
                                                        clearIndicator: base => ({ ...base, padding: '0px', fontSize: '12px' }),
                                                        option: base => ({ ...base, fontSize: 14 }),
                                                    }}
                                                    className='react-select-container'
                                                    classNamePrefix='react-select'
                                                    value={tempSkill.skill_condition ? {
                                                        label: tempSkill.skill_condition, value: tempSkill.skill_condition
                                                    } : ''}
                                                    options={skillConditions?.map(item => {
                                                        return (
                                                            { label: item.label, value: item.id }
                                                        )
                                                    })}
                                                    onChange={addItemToTempSkill('condition')}
                                                />
                                            </Grid>
                                            <Grid item md={4} sm={12}>
                                                <TextField
                                                    label="Year"
                                                    type='number'
                                                    fullWidth
                                                    size="small"
                                                    name='year'
                                                    value={tempYear}
                                                    InputLabelProps={{
                                                        style: {
                                                            fontSize: '14px',
                                                        },
                                                    }}
                                                    sx={{
                                                        backgroundColor: !tempSkill.skill_id ? '#80808012' : 'white',
                                                    }}
                                                    onChange={(e) => setTempYear(e.target.value)}
                                                    inputRef={yearRef}
                                                    disabled={!tempSkill.skill_id}
                                                    required={!!tempSkill.skill_id}
                                                />
                                                {emptyYearError && <span style={{ color: 'red', fontSize: '12px' }}>{emptyYearError}</span>}
                                            </Grid>
                                        </Grid>
                                        {/* </Stack> */}
                                        <Grid container spacing={2} alignItems={'center'} sx={{ marginLeft: '0px !important', marginTop: '0px !important' }}>
                                            <Grid item md={12} sm={12} sx={{ paddingLeft: '0px !important', paddingTop: '10px !important' }}>
                                                <Button
                                                    aria-label="grid-view"
                                                    onClick={() => handleAddOrUpdateSkill()}
                                                    startIcon={<SkillSearchIcon />}
                                                    sx={{
                                                        borderRadius: '5px',
                                                        backgroundColor: '#E3F2FF',
                                                        textTransform: 'capitalize',
                                                        paddingLeft: '13px !important',
                                                        paddingRight: '13px !important'
                                                    }}
                                                >
                                                    Add Skills
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Select
                                            isClearable
                                            placeholder='Experience'
                                            name='experience'
                                            styles={{ control: base => ({ ...base, height: '40px' }), placeholder: base => ({ ...base, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }), menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }) }}
                                            className='react-select-container'
                                            classNamePrefix='react-select'
                                            options={experienceRange?.map(item => {
                                                return (
                                                    { label: item.label, value: item.value }
                                                )
                                            })}
                                            onChange={(e) => handleExperienceRange(e)}
                                            ref={experienceRef}
                                        />
                                        <Stack spacing={2}
                                            direction={'row'}
                                            alignItems={'center'}>
                                            <Grid container spacing={2} sx={{ gridTemplateColumns: 'repeat(auto-fill, minmax(0, 1fr))', marginLeft: '0px !important' }}>
                                                <Grid item md={6} sm={12} sx={{ paddingLeft: '0px !important' }}>
                                                    <Select
                                                        // fullWidth
                                                        isClearable
                                                        placeholder='Salary'
                                                        name='salary-range'
                                                        styles={{ control: base => ({ ...base, height: '40px' }), placeholder: base => ({ ...base, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }), menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }) }}
                                                        className='react-select-container'
                                                        classNamePrefix='react-select'
                                                        options={salaryRange?.map(item => {
                                                            return (
                                                                { label: item.label, value: item.value }
                                                            )
                                                        })}
                                                        onChange={(e) => handleSalaryRange(e)}
                                                        ref={salaryRef}
                                                    />
                                                </Grid>
                                                <Grid item md={6} sm={12}>
                                                    <Select
                                                        // fullWidth
                                                        isClearable
                                                        placeholder='Currency'
                                                        name='salary-currenct-range'
                                                        styles={{ control: base => ({ ...base, height: '40px' }), placeholder: base => ({ ...base, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }), menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }) }}
                                                        className='react-select-container'
                                                        classNamePrefix='react-select'
                                                        options={currency?.map(item => {
                                                            return (
                                                                { label: item.name, value: item.id }
                                                            )
                                                        })}
                                                        onChange={handleSelectField('currency')}
                                                        ref={currencyRef}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Stack>
                                        <Select
                                            isClearable
                                            placeholder='Qualification'
                                            styles={{ control: base => ({ ...base, height: '40px' }), placeholder: base => ({ ...base, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }), menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }) }}
                                            className='react-select-container'
                                            classNamePrefix='react-select'
                                            options={qualifications?.map(item => {
                                                return (
                                                    { label: item.name, value: item.id }
                                                )

                                            })}
                                            onChange={handleSelectField('qualification')}
                                            ref={qualificationRef}
                                        />
                                        <Select
                                            isClearable
                                            placeholder='Gender'
                                            styles={{ control: base => ({ ...base, height: '40px' }), placeholder: base => ({ ...base, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }), menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }) }}
                                            className='react-select-container'
                                            classNamePrefix='react-select'
                                            options={genders?.map(item => {
                                                return (
                                                    { label: item.name, value: item.name }
                                                )
                                            })}
                                            onChange={handleSelectField('gender')}
                                            ref={genderRef}
                                        />
                                        <Box
                                            sx={{
                                                display: advancedSearchComponent === true ? 'block' : 'none'
                                            }}
                                        >
                                            <Stack spacing={2}>
                                                <Select
                                                    isClearable
                                                    placeholder='Institute'
                                                    styles={{ control: base => ({ ...base, height: '40px' }), placeholder: base => ({ ...base, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }), menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }) }}
                                                    className='react-select-container'
                                                    classNamePrefix='react-select'
                                                    options={universities?.map(item => {
                                                        return (
                                                            { label: item, value: item }
                                                        )
                                                    })}
                                                    onChange={handleSelectField('institute')}
                                                    ref={instituteRef}
                                                />
                                                <TextField
                                                    label="Profession"
                                                    fullWidth
                                                    size="small"
                                                    name='profession'
                                                    InputLabelProps={{
                                                        style: {
                                                            fontSize: '14px',
                                                            zIndex: 'unset'
                                                        },
                                                    }}
                                                    sx={{
                                                        backgroundColor: 'white',
                                                    }}
                                                    onChange={handletextField('profession')}
                                                    inputRef={professionRef}

                                                />
                                                <Select
                                                    isClearable
                                                    placeholder='Candidate Type'
                                                    styles={{ control: base => ({ ...base, height: '40px' }), placeholder: base => ({ ...base, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }), menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }) }}
                                                    className='react-select-container'
                                                    classNamePrefix='react-select'
                                                    options={types?.map(item => {
                                                        return (
                                                            { label: item.name, value: item.id }
                                                        )
                                                    })}
                                                    onChange={handleSelectField('type')}
                                                    ref={typeRef}
                                                />
                                                <Select
                                                    isClearable
                                                    placeholder='Candidate Level'
                                                    styles={{ control: base => ({ ...base, height: '40px' }), placeholder: base => ({ ...base, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }), menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }) }}
                                                    className='react-select-container'
                                                    classNamePrefix='react-select'
                                                    options={levels?.map(item => {
                                                        return (
                                                            { label: item.candidate_level_name, value: item.id }
                                                        )
                                                    })}
                                                    onChange={handleSelectField('level')}
                                                    ref={levelRef}
                                                />
                                                <TextField
                                                    label="Name"
                                                    fullWidth
                                                    size="small"
                                                    name='name'
                                                    InputLabelProps={{
                                                        style: {
                                                            fontSize: '14px',
                                                            zIndex: 'unset'
                                                        },
                                                    }}
                                                    sx={{
                                                        backgroundColor: 'white',
                                                    }}
                                                    onChange={handletextField('name')}
                                                    inputRef={nameRef}
                                                />
                                                <Select
                                                    isClearable
                                                    placeholder='Age Range'
                                                    styles={{ control: base => ({ ...base, height: '40px' }), placeholder: base => ({ ...base, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }), menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }) }}
                                                    className='react-select-container'
                                                    classNamePrefix='react-select'
                                                    options={ageRange?.map(item => {
                                                        return (
                                                            { label: item.label, value: item.value }
                                                        )
                                                    })}
                                                    onChange={(e) => handleAgeRange(e)}
                                                    ref={ageRef}
                                                />
                                            </Stack>
                                        </Box>
                                        <Stack
                                            spacing={2}
                                            direction={{ xs: 'column', md: 'row' }}
                                            alignItems={'center'}
                                            justifyContent={'space-around'}
                                        >
                                            <Button
                                                variant="contained"
                                                fullWidth
                                                startIcon={<SearchIcon />}
                                                sx={{
                                                    backgroundColor: '#0079C1',
                                                    color: 'white',
                                                    fontSize: '12px',
                                                    textTransform: 'capitalize',
                                                    '&:hover': {
                                                        backgroundColor: '#075497',
                                                    }
                                                }}
                                            // onClick={() => handleAddOrUpdateSkill()}
                                            >
                                                Search
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                fullWidth
                                                startIcon={<AdvanceSearchIcon />}
                                                onClick={() => handleChangeAdvancedSearchComponent()}
                                                sx={{
                                                    fontSize: '12px',
                                                    color: '#0079C1',
                                                    textTransform: 'capitalize',
                                                    // '&:hover': {
                                                    //     backgroundColor: '#049b87',
                                                    // },
                                                }}
                                            >
                                                {advancedSearchComponent ? 'Quick Search' : 'Advanced Search'}
                                            </Button>
                                        </Stack>
                                    </Stack>
                                </form>
                            </Box>

                        </Box>

                        {
                            customQuestions.length !== 0 ?
                                <Box sx={{
                                    display: { xs: 'none', md: 'block' },

                                    overflowY: 'auto',
                                    boxShadow: '-1px 3px 5px 2px rgb(0 0 0 / 18%)',
                                    marginY: '30px'
                                }}
                                >
                                    <Box
                                        p={4}
                                        sx={{
                                            backgroundColor: '#FFFF',
                                            width: '100%',
                                            '@media (max-width: 600px)': {
                                                width: '100%',
                                            },
                                        }}
                                    >
                                        <Typography sx={{ fontSize: '20px', fontWeight: 700, color: '#424447' }} variant='h2'>Custom Question</Typography>
                                        <Divider />

                                        {
                                            customQuestions.map((item, index) => {
                                                return (
                                                    <Accordion elevation={0}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            sx={{ padding: '0px', margin: '0px' }}
                                                        >
                                                            <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>{`QS -${index + 1}: `}{item.question}</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails sx={{ padding: '0px', margin: '0px' }}>
                                                            <FormGroup sx={{ padding: 0, margin: 0 }}>
                                                                {
                                                                    item.options.length !== 0 ? item.options.map((opt, index) => {
                                                                        return (
                                                                            <FormControlLabel sx={{ padding: 0, margin: 0 }} key={index} style={{ fontSize: '14px', fontWeight: 500 }} control={<Checkbox value={opt.id} onChange={(event) => handleCustomQuestion(event, item.id, opt.id)} />} label={opt.option} />
                                                                        )
                                                                    }) : null
                                                                }
                                                            </FormGroup>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                )
                                            })
                                        }
                                    </Box>
                                </Box>
                                : null
                        }
                    </Grid>
                    <Grid item md={9} sx={{ width: '100%' }}>
                        <Box m={1} sx={{ width: { md: '100%' } }} >
                            <Typography
                                variant="h3"
                                sx={{
                                    fontWeight: 700,
                                    fontSize: '20px',
                                    '@media (max-width: 600px)': {
                                        fontSize: '18px',
                                    },
                                    textAlign: 'center',
                                    marginTop: '20px'
                                }}
                            >
                                Applied Candidate Dashboard List
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                margin: 4,
                                '@media (max-width: 600px)': {
                                    margin: 2,
                                },
                            }}
                        >

                            <Box sx={{ boxShadow: '-1px 3px 5px 2px rgb(0 0 0 / 18%)', backgroundColor: '#fff' }}>
                                <Grid container sx={{ justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
                                    <Grid item sm={6} xs={12}>
                                        <Box m={1} sx={{ width: { md: '100%' } }} >
                                            <Typography
                                                variant="h3"
                                                sx={{
                                                    fontWeight: 700,
                                                    fontSize: '20px',
                                                    '@media (max-width: 600px)': {
                                                        fontSize: '18px',
                                                    },
                                                }}
                                            >
                                                {paginateInfo ? <span>We found  <span style={{ color: '#1976d2' }}>({paginateInfo.total})</span> candidates <span style={{ color: '#1976d2' }}>"{jobName}"</span></span> : ``}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <Stack
                                            direction={'row'}
                                            spacing={2}
                                            justifyContent={'flex-end'}
                                            alignItems={'center'}
                                        >
                                            {/* <IconButton
                                                aria-label="list-view"
                                                sx={{ marginLeft: '0px !important', borderRadius: '10px' }}
                                            // onClick={() => setGridView(false)}
                                            > */}
                                            <PostDateIcon />
                                            {/* </IconButton> */}
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    fontWeight: 500,
                                                    fontSize: '14px',
                                                    '@media (max-width: 600px)': {
                                                        fontSize: '12px'
                                                    },
                                                }}
                                            >
                                                Application Period - {moment(searchResult?.response.data.job_info.post_date).format("MMM Do YY")} - {moment(searchResult?.response.data.job_info.deadline).format("MMM Do YY")}
                                            </Typography>

                                            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                                                <React.Fragment>
                                                    <IconButton
                                                        aria-label="grid-view"
                                                        onClick={() => setGridView(true)}
                                                        sx={{
                                                            borderRadius: '10px'
                                                        }}
                                                    >
                                                        <GridIcon grid={gridView} />
                                                    </IconButton>

                                                    <IconButton
                                                        aria-label="list-view"
                                                        sx={{ marginLeft: '0px !important', borderRadius: '10px' }}
                                                        onClick={() => setGridView(false)}
                                                    >
                                                        <ListIcon grid={gridView} />
                                                    </IconButton>
                                                </React.Fragment>
                                            </Box>

                                            <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-start' }}>
                                                <DrawerComponent filterComponent={
                                                    <form id="appliedCandidatesearchFormV3Mobile">
                                                        <Stack spacing={2} sx={{
                                                            padding: '10px',
                                                            width: '100%'
                                                        }}>
                                                            <Stack
                                                                spacing={2}
                                                                direction={'row'}
                                                                alignItems={'center'}
                                                                justifyContent={'space-between'}

                                                            >
                                                                <Typography
                                                                    variant="body1"
                                                                    sx={{
                                                                        fontWeight: 700,
                                                                        fontSize: '14px',
                                                                        '@media (max-width: 600px)': {
                                                                            fontSize: '12px',
                                                                        },
                                                                    }}
                                                                >
                                                                    {advancedSearchComponent ? 'Advanced Search' : 'Quick Search'}
                                                                </Typography>
                                                                <Button
                                                                    size="small"
                                                                    variant="contained"
                                                                    endIcon={<ClearFilterIcon />}
                                                                    disableElevation
                                                                    sx={{
                                                                        backgroundColor: '#E3F2FF',
                                                                        color: '#0275D8',
                                                                        borderRadius: '30px',
                                                                        '&:hover': {
                                                                            backgroundColor: '#e6f3ff',
                                                                        },
                                                                        '@media (max-width: 600px)': {
                                                                            fontSize: '12px',
                                                                        },
                                                                    }}
                                                                    onClick={() => clearFilterFunction()}
                                                                >
                                                                    Clear Filter
                                                                </Button>
                                                            </Stack>
                                                            <Stack spacing={2}
                                                                direction={'row'}
                                                                alignItems={'center'}
                                                                justifyContent={"space-between"}
                                                            >
                                                                <Box spacing={2} sx={{ width: '100%' }}>
                                                                    <Select
                                                                        fullWidth
                                                                        isClearable
                                                                        placeholder='Skills'
                                                                        styles={{ control: base => ({ ...base, height: '40px' }), placeholder: base => ({ ...base, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }), menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }) }}
                                                                        className='react-select-container'
                                                                        classNamePrefix='react-select'
                                                                        value={tempSkill.skill_id ? {
                                                                            label: tempSkill.skill_name, value: tempSkill.skill_id
                                                                        } : ''}
                                                                        options={skills?.map(item => {
                                                                            return (
                                                                                { label: item.skill_name, value: item.id }
                                                                            )
                                                                        })}
                                                                        onChange={addItemToTempSkill('skill')}
                                                                        ref={skillRef}
                                                                        style={{ width: '100%' }}
                                                                    />
                                                                </Box>
                                                            </Stack>
                                                            <Stack spacing={2}
                                                                direction={'row'}
                                                                alignItems={'center'}
                                                                justifyContent={"space-between"}
                                                            >
                                                                <Box spacing={2} sx={{ width: '100%' }}>
                                                                    <Select
                                                                        isClearable
                                                                        placeholder='Select'
                                                                        styles={{ control: base => ({ ...base, height: '40px' }), placeholder: base => ({ ...base, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }), menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }) }}
                                                                        className='react-select-container'
                                                                        classNamePrefix='react-select'
                                                                        value={tempSkill.skill_condition ? {
                                                                            label: tempSkill.skill_condition, value: tempSkill.skill_condition
                                                                        } : ''}
                                                                        options={skillConditions?.map(item => {
                                                                            return (
                                                                                { label: item.label, value: item.id }
                                                                            )
                                                                        })}
                                                                        onChange={addItemToTempSkill('condition')}
                                                                    />
                                                                </Box>
                                                                <Box spacing={2} sx={{ width: '100%' }}>
                                                                    <TextField
                                                                        label="Year"
                                                                        type='number'
                                                                        fullWidth
                                                                        size="small"
                                                                        name='year'
                                                                        value={tempYear}
                                                                        InputLabelProps={{
                                                                            style: {
                                                                                fontSize: '14px',
                                                                                zIndex: 0
                                                                            },
                                                                        }}
                                                                        sx={{
                                                                            backgroundColor: !tempSkill.skill_id ? '#80808012' : 'white',
                                                                        }}
                                                                        onChange={(e) => setTempYear(e.target.value)}
                                                                        inputRef={yearRef}
                                                                        disabled={!tempSkill.skill_id}
                                                                        required={!!tempSkill.skill_id}
                                                                    />
                                                                </Box>
                                                                <Box spacing sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                    <IconButton
                                                                        aria-label="grid-view"
                                                                        onClick={() => handleAddOrUpdateSkill()}
                                                                        sx={{
                                                                            borderRadius: '10px'
                                                                        }}
                                                                    >
                                                                        <SkillSearchIcon />
                                                                    </IconButton>
                                                                </Box>
                                                            </Stack>
                                                            {emptyYearError && <span style={{ color: 'red', fontSize: '12px', marginTop: '2px', marginLeft: '4px' }}>{emptyYearError}</span>}
                                                            <Select
                                                                isClearable
                                                                placeholder='Experience'
                                                                name='experience'
                                                                styles={{ control: base => ({ ...base, height: '40px' }), placeholder: base => ({ ...base, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }), menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }) }}
                                                                className='react-select-container'
                                                                classNamePrefix='react-select'
                                                                options={experienceRange?.map(item => {
                                                                    return (
                                                                        { label: item.label, value: item.value }
                                                                    )
                                                                })}
                                                                onChange={(e) => handleExperienceRange(e)}
                                                                ref={experienceRef}
                                                            />
                                                            <Stack spacing={2}
                                                                direction={'row'}
                                                                alignItems={'center'}
                                                                justifyContent={"space-between"}
                                                            >
                                                                <Box spacing={2} sx={{ width: '100%' }}>
                                                                    <Select
                                                                        fullWidth
                                                                        isClearable
                                                                        placeholder='Salary'
                                                                        name='salary-range'
                                                                        styles={{ control: base => ({ ...base, height: '40px' }), placeholder: base => ({ ...base, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }), menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }) }}
                                                                        className='react-select-container'
                                                                        classNamePrefix='react-select'
                                                                        options={salaryRange?.map(item => {
                                                                            return (
                                                                                { label: item.label, value: item.value }
                                                                            )
                                                                        })}
                                                                        onChange={(e) => handleSalaryRange(e)}
                                                                        ref={salaryRef}
                                                                        style={{ width: '100%' }}
                                                                    />
                                                                </Box>
                                                                <Box spacing={2} sx={{ width: '100%' }}>
                                                                    <Select
                                                                        fullWidth
                                                                        isClearable
                                                                        placeholder='Currency'
                                                                        name='salary-currenct-range'
                                                                        styles={{ control: base => ({ ...base, height: '40px' }), placeholder: base => ({ ...base, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }), menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }) }}
                                                                        className='react-select-container'
                                                                        classNamePrefix='react-select'
                                                                        options={currency?.map(item => {
                                                                            return (
                                                                                { label: item.name, value: item.id }
                                                                            )
                                                                        })}
                                                                        onChange={handleSelectField('currency')}
                                                                        ref={currencyRef}
                                                                        style={{ width: '100%' }}
                                                                    />
                                                                </Box>
                                                            </Stack>
                                                            <Select
                                                                isClearable
                                                                placeholder='Qualification'
                                                                styles={{ control: base => ({ ...base, height: '40px' }), placeholder: base => ({ ...base, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }), menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }) }}
                                                                className='react-select-container'
                                                                classNamePrefix='react-select'
                                                                options={qualifications?.map(item => {
                                                                    return (
                                                                        { label: item.name, value: item.id }
                                                                    )

                                                                })}
                                                                onChange={handleSelectField('qualification')}
                                                                ref={qualificationRef}
                                                            />
                                                            <Select
                                                                isClearable
                                                                placeholder='Gender'
                                                                styles={{ control: base => ({ ...base, height: '40px' }), placeholder: base => ({ ...base, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }), menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }) }}
                                                                className='react-select-container'
                                                                classNamePrefix='react-select'
                                                                options={genders?.map(item => {
                                                                    return (
                                                                        { label: item.name, value: item.id }
                                                                    )
                                                                })}
                                                                onChange={handleSelectField('gender')}
                                                                ref={genderRef}
                                                            />
                                                            <Box
                                                                sx={{
                                                                    display: advancedSearchComponent === true ? 'block' : 'none'
                                                                }}
                                                            >
                                                                <Stack spacing={2}>
                                                                    <Select
                                                                        isClearable
                                                                        placeholder='Institute'
                                                                        styles={{ control: base => ({ ...base, height: '40px' }), placeholder: base => ({ ...base, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }), menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }) }}
                                                                        className='react-select-container'
                                                                        classNamePrefix='react-select'
                                                                        options={universities?.map(item => {
                                                                            return (
                                                                                { label: item, value: item }
                                                                            )
                                                                        })}
                                                                        onChange={handleSelectField('institute')}
                                                                        ref={instituteRef}
                                                                    />
                                                                    <TextField
                                                                        label="Profession"
                                                                        fullWidth
                                                                        size="small"
                                                                        name='profession'
                                                                        InputLabelProps={{
                                                                            style: {
                                                                                fontSize: '14px',
                                                                                zIndex: 'unset'
                                                                            },
                                                                        }}
                                                                        sx={{
                                                                            backgroundColor: 'white',
                                                                        }}
                                                                        onChange={handletextField('profession')}
                                                                        inputRef={professionRef}
                                                                    />
                                                                    <Select
                                                                        isClearable
                                                                        placeholder='Candidate Type'
                                                                        styles={{ control: base => ({ ...base, height: '40px' }), placeholder: base => ({ ...base, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }), menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }) }}
                                                                        className='react-select-container'
                                                                        classNamePrefix='react-select'
                                                                        options={types?.map(item => {
                                                                            return (
                                                                                { label: item.name, value: item.id }
                                                                            )
                                                                        })}
                                                                        onChange={handleSelectField('type')}
                                                                        ref={typeRef}
                                                                    />
                                                                    <Select
                                                                        isClearable
                                                                        placeholder='Candidate Level'
                                                                        styles={{ control: base => ({ ...base, height: '40px' }), placeholder: base => ({ ...base, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }), menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }) }}
                                                                        className='react-select-container'
                                                                        classNamePrefix='react-select'
                                                                        options={levels?.map(item => {
                                                                            return (
                                                                                { label: item.candidate_level_name, value: item.id }
                                                                            )
                                                                        })}
                                                                        onChange={handleSelectField('level')}
                                                                        ref={levelRef}
                                                                    />
                                                                    <TextField
                                                                        label="Name"
                                                                        fullWidth
                                                                        size="small"
                                                                        name='name'
                                                                        InputLabelProps={{
                                                                            style: {
                                                                                fontSize: '14px',
                                                                                zIndex: 'unset'
                                                                            },
                                                                        }}
                                                                        sx={{
                                                                            backgroundColor: 'white',
                                                                        }}
                                                                        onChange={handletextField('name')}
                                                                        inputRef={nameRef}
                                                                    />
                                                                    <Select
                                                                        isClearable
                                                                        placeholder='Age Range'
                                                                        styles={{ control: base => ({ ...base, height: '40px' }), placeholder: base => ({ ...base, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }), menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }) }}
                                                                        className='react-select-container'
                                                                        classNamePrefix='react-select'
                                                                        options={ageRange?.map(item => {
                                                                            return (
                                                                                { label: item.label, value: item.value }
                                                                            )
                                                                        })}
                                                                        onChange={(e) => handleAgeRange(e)}
                                                                        ref={ageRef}
                                                                    />
                                                                </Stack>
                                                            </Box>
                                                            <Stack
                                                                spacing={2}
                                                                direction={{ xs: 'column', md: 'row' }}
                                                                alignItems={'center'}
                                                                justifyContent={'space-around'}
                                                            >
                                                                <Button
                                                                    variant="contained"
                                                                    fullWidth
                                                                    endIcon={<SearchIcon />}
                                                                    sx={{
                                                                        backgroundColor: '#0275D8',
                                                                        color: 'white',
                                                                        fontSize: '12px',
                                                                        textTransform: 'capitalize',
                                                                        '&:hover': {
                                                                            backgroundColor: '#075497',
                                                                        }
                                                                    }}
                                                                    onClick={() => handleAddOrUpdateSkill()}
                                                                >
                                                                    Search
                                                                </Button>
                                                                <Button
                                                                    variant="contained"
                                                                    fullWidth
                                                                    onClick={() => handleChangeAdvancedSearchComponent()}
                                                                    sx={{
                                                                        backgroundColor: '#00B19A',
                                                                        fontSize: '12px',
                                                                        color: 'white',
                                                                        textTransform: 'capitalize',
                                                                        '&:hover': {
                                                                            backgroundColor: '#049b87',
                                                                        },
                                                                    }}
                                                                >
                                                                    {advancedSearchComponent ? 'Quick Search' : 'Advanced Search'}
                                                                </Button>
                                                            </Stack>
                                                        </Stack>
                                                        {
                                                            customQuestions.length !== 0 ?
                                                                <Box sx={{
                                                                    overflowY: 'auto',
                                                                    boxShadow: '-1px 3px 5px 2px rgb(0 0 0 / 18%)',
                                                                    marginTop: '30px'
                                                                }}
                                                                >
                                                                    <Box
                                                                        p={4}
                                                                        sx={{
                                                                            backgroundColor: '#FFFF',
                                                                            width: '100%',
                                                                            '@media (max-width: 600px)': {
                                                                                width: '100%',
                                                                            },
                                                                        }}
                                                                    >
                                                                        <Typography sx={{ fontSize: '20px', fontWeight: 700, color: '#424447' }} variant='h2'>Custom Question</Typography>
                                                                        <Divider />

                                                                        {
                                                                            customQuestions.map((item, index) => {
                                                                                return (
                                                                                    <Accordion elevation={0}>
                                                                                        <AccordionSummary
                                                                                            expandIcon={<ExpandMoreIcon />}
                                                                                            aria-controls="panel1a-content"
                                                                                            id="panel1a-header"
                                                                                            sx={{ padding: '0px', margin: '0px' }}
                                                                                        >
                                                                                            <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>{`QS -${index + 1}: `}{item.question}</Typography>
                                                                                        </AccordionSummary>
                                                                                        <AccordionDetails sx={{ padding: '0px', margin: '0px' }}>
                                                                                            <FormGroup sx={{ padding: 0, margin: 0 }}>
                                                                                                {
                                                                                                    item.options.length !== 0 ? item.options.map((opt, index) => {
                                                                                                        return (
                                                                                                            <FormControlLabel sx={{ padding: 0, margin: 0 }} key={index} style={{ fontSize: '14px', fontWeight: 500 }} control={<Checkbox value={opt.id} onChange={(event) => handleCustomQuestion(event, item.id, opt.id)} />} label={opt.option} />
                                                                                                        )
                                                                                                    }) : null
                                                                                                }
                                                                                            </FormGroup>
                                                                                        </AccordionDetails>
                                                                                    </Accordion>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Box>
                                                                </Box>
                                                                : null
                                                        }
                                                    </form>
                                                } />
                                            </Box>

                                        </Stack>
                                    </Grid>
                                </Grid>
                                <Divider />
                                {
                                    chipList.length !== 0 ?
                                        <Box spacing={2} sx={{ padding: '10px' }}>
                                            {chipList.map((item) => {
                                                return (
                                                    <Chip color="primary" variant="outlined" sx={{ margin: '5px', textTransform: 'capitalize' }} label={item.skill_name + ' ' + item.skill_condition + ' ' + item.year_of_experience + ' year'} key={item.skill_id} onDelete={() => handleRemoveSkill(item.skill_id)} />
                                                )
                                            })}
                                        </Box>
                                        : null
                                }
                                <Divider />


                            </Box>
                            {
                                loading ?
                                    <GlobalLoader height="60vh" />
                                    :
                                    <div>
                                        {
                                            statusListInfo.length !== 0 ?
                                                <div style={{ padding: '20px 0px 10px 0px', margin: '0px' }} className="row" >
                                                    <div style={filterParams.status == '' ? selectedStatusClass : statusClass} onClick={() => setFilterParams({ ...filterParams, status: '' })} className='col'>
                                                        <Box sx={{ textAlign: 'center' }}>
                                                            <Typography variant='h5' sx={{
                                                                fontWeight: 600,
                                                                fontSize: '14px',
                                                                color: '#0079C1'
                                                            }} >All</Typography>
                                                            <Typography variant='h5' sx={{
                                                                fontWeight: 700,
                                                                fontSize: '24px',
                                                                color: '#0079C1'
                                                            }} >{searchResult?.response?.data?.total_applicant}</Typography>
                                                        </Box>
                                                    </div>
                                                    {statusListInfo.map((item, index) => {
                                                        return (
                                                            <div key={index} style={filterParams.status == item.id ? selectedStatusClass : statusClass} onClick={() => setFilterParams({ ...filterParams, status: item.id })} className='col'>
                                                                <Box sx={{ textAlign: 'center' }}>
                                                                    <Typography variant='h5' sx={{
                                                                        fontWeight: 600,
                                                                        fontSize: '14px',
                                                                        color: '#0079C1'
                                                                    }} >{item.name}</Typography>
                                                                    <Typography variant='h5' sx={{
                                                                        fontWeight: 700,
                                                                        fontSize: '24px',
                                                                        color: '#0079C1'
                                                                    }} >{item.applicantCount}</Typography>
                                                                </Box>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                : null
                                        }
                                        <div className='row' style={{ marginTop: '10px' }} >
                                            {
                                                searchResult?.response.data.applicants.length != 0 ? searchResult?.response.data.applicants.map((item, index) => {
                                                    return (
                                                        <div key={item.id} className={`col-md-${gridView ? '6' : '12'}  col-sm-12 m-b10`}>
                                                            <AppliedCandidateCardV3 index={index} jobId={job_id} candidateInfo={item} refetch={refetch} />
                                                        </div>
                                                    )
                                                })
                                                    :
                                                    < >
                                                        <div className='col'></div>
                                                        <div className='col' style={{ marginTop: '10px' }}>
                                                            <NoResultsFound />
                                                        </div>
                                                        <div className='col'></div>
                                                    </>
                                            }
                                        </div>
                                    </div>
                            }
                            <Box m={{ xs: 1, md: 3 }} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {
                                    !!paginateInfo.length ?
                                        <Pagination
                                            page={currentPage}
                                            count={paginateInfo?.last_page}
                                            color="primary"
                                            shape="rounded"
                                            boundaryCount={1}
                                            onChange={changePage}
                                            sx={{
                                                '& .MuiPaginationItem-root': {
                                                    fontSize: '0.75rem',
                                                },
                                                '& .MuiPaginationItem-page.Mui-selected': {
                                                    fontSize: '0.75rem',
                                                },
                                                '@media (max-width: 600px)': {
                                                    '& .MuiPaginationItem-root': {
                                                        fontSize: '0.65rem',
                                                    },
                                                    '& .MuiPaginationItem-page.Mui-selected': {
                                                        fontSize: '0.65rem',
                                                    },
                                                    '& .MuiPaginationItem-ellipsis': {
                                                        display: 'none',
                                                    },
                                                },
                                            }}
                                        />
                                        :
                                        ''
                                }

                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default AppliedCandidateSearchV3